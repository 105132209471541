import React from 'react'
import { Box, Grid, Typography, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

class ResetPwSuccess extends React.Component {
  render () {
    return (
      <Grid
        className='page reset-pw-success'
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justify='center'
      >
        <div className='success-circle'>
          <Typography variant='h6'>SUCCESS!</Typography>
        </div>
        <Box mb={5} />
        <Typography variant='h6' color='secondary'>
          Password reset successfully
        </Typography>
        <Box mb={4} />
        <Box width={350}>
          <Button
            variant='contained'
            color='secondary'
            component={Link}
            to='/login'
            fullWidth
          >
            OK
          </Button>
        </Box>
      </Grid>
    )
  }
}

export default ResetPwSuccess
