import React from 'react'
import { Box, Table, TableBody, Typography } from '@material-ui/core'

import FieldRows from './FieldRows'

const generalFields = [
  {
    name: '姓名(中)',
    property: 'chineseName'
  },
  {
    name: '姓名(英)',
    property: 'englishName'
  }
]

const phoneFields = [
  {
    name: '手提(1)',
    property: 'mobile1'
  },
  {
    name: '手提(2)',
    property: 'mobile2'
  },
  {
    name: '家居',
    property: 'home'
  },
  {
    name: '工作',
    property: 'work'
  }
]

const emailFields = [
  {
    name: '電郵(1)',
    property: 'email1'
  },
  {
    name: '電郵(2)',
    property: 'email2'
  }
]

const addressFields = [
  {
    name: '地址行1',
    property: 'line1'
  },
  {
    name: '地址行2',
    property: 'line2'
  },
  {
    name: '地址行3',
    property: 'line3'
  },
  {
    name: '地區',
    property: 'region'
  },
  {
    name: '國家',
    property: 'country'
  }
]

class ChangeOfPersonalInformationNotice extends React.Component {
  render () {
    const { data } = this.props

    return (
      <div>
        <Box pl={2}>
          <Typography variant='subtitle1'>更改個人資料通知書</Typography>
        </Box>

        <Table>
          <TableBody>
            <FieldRows data={data} fields={generalFields} />
            <FieldRows data={data.phone} fields={phoneFields} />
            <FieldRows data={data} fields={emailFields} />
            <FieldRows data={data.address} fields={addressFields} />
            <FieldRows
              data={data}
              fields={[
                {
                  name: '其他',
                  property: 'other'
                }
              ]}
            />
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default ChangeOfPersonalInformationNotice
