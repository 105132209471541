import * as R from 'ramda'
import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Box,
  Collapse,
  Grid,
  Button,
  Paper,
  Table,
  TableHead,
  TableBody,
  Typography,
  TableRow,
  TableCell,
  IconButton
} from '@material-ui/core'
import { Edit, ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

import Loading from '../components/layouts/Loading'
import CaseDetailToolbar from '../components/cases/CaseDetailToolbar'
import CaseDetailEditDialog from '../components/cases/CaseDetailEditDialog'
import PersonalInfoEditDialog from '../components/cases/PersonalInfoEditDialog'
import { types as formTypes, status as formStatus } from '../constants/form'
import { parseReminder, nl2br } from '../utils/reminder'

@inject('caseStore', 'contributionStore', 'reminderStore', 'formStore')
@observer
class CaseDetail extends React.Component {
  state = {
    showCaseDetailEditDialog: false,
    showPersonalInfoEditDialog: false,
    showOfficerHistory: false
  }

  componentDidMount () {
    const {
      caseStore,
      contributionStore,
      reminderStore,
      formStore,
      match
    } = this.props
    const { caseId } = match.params
    caseStore.fetchSingleCase(caseId)

    contributionStore.setTargetCaseId(caseId)
    contributionStore.fetchCaseContributions()

    reminderStore.setTargetCaseId(caseId)
    reminderStore.fetchCaseReminders()

    formStore.setTargetCaseId(caseId)
    formStore.fetchCaseForms()
  }

  toggleCaseDetailEditDialog = () => {
    this.setState(state => ({
      showCaseDetailEditDialog: !state.showCaseDetailEditDialog
    }))
  }

  togglePersonalInfoEditDialog = () => {
    this.setState(state => ({
      showPersonalInfoEditDialog: !state.showPersonalInfoEditDialog
    }))
  }

  toggleOfficerHistory = () => {
    this.setState(state => ({
      showOfficerHistory: !state.showOfficerHistory
    }))
  }

  render () {
    const {
      showCaseDetailEditDialog,
      showPersonalInfoEditDialog,
      showOfficerHistory
    } = this.state
    const {
      caseStore,
      contributionStore,
      reminderStore,
      formStore
    } = this.props
    const { singleCase } = caseStore
    const { totalContributions, monthlyContributions } = contributionStore
    const { caseReminders } = reminderStore
    const { caseForms } = formStore

    if (!singleCase) {
      return <Loading fullHeight />
    }

    return (
      <div>
        <Box mb={2}>
          <Button color='primary' component={Link} to='/case-management'>
            Case Management
          </Button>
          / <Typography display='inline'>{singleCase.customCaseId}</Typography>
        </Box>
        <Box mb={2}>
          <CaseDetailToolbar />
        </Box>
        <Paper>
          <Box pl={3} pr={1} py={2}>
            <Grid container justify='space-between' alignItems='center'>
              <Typography variant='h6' component='h2'>
                Case Detail
              </Typography>
              <IconButton onClick={this.toggleCaseDetailEditDialog}>
                <Edit />
              </IconButton>
            </Grid>
          </Box>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: '35%' }}>Case ID</TableCell>
                <TableCell>{singleCase.customCaseId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Last login</TableCell>
                <TableCell>
                  {singleCase.lastLogin
                    ? dayjs(singleCase.lastLogin).format('DD/MM/YYYY HH:mma')
                    : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>HCB No.</TableCell>
                <TableCell>{singleCase.hcbNo}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Number of times of Bankruptcy</TableCell>
                <TableCell>{singleCase.bankruptCount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>BO Date</TableCell>
                <TableCell>
                  {singleCase.boDate
                    ? dayjs(singleCase.boDate).format('DD-MM-YYYY')
                    : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>CM Date</TableCell>
                <TableCell>
                  {singleCase.cmDate
                    ? dayjs(singleCase.cmDate).format('DD-MM-YYYY')
                    : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Discharge Date</TableCell>
                <TableCell>
                  {singleCase.dischargeDate
                    ? dayjs(singleCase.dischargeDate).format('DD-MM-YYYY')
                    : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Annulled Date</TableCell>
                <TableCell>
                  {singleCase.annulledDate
                    ? dayjs(singleCase.annulledDate).format('DD-MM-YYYY')
                    : '-'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Box mb={2} />
        <Paper>
          <Box pl={3} pr={1} py={2}>
            <Grid container justify='space-between' alignItems='center'>
              <Typography variant='h6' component='h2'>
                Personal Information
              </Typography>
              <IconButton onClick={this.togglePersonalInfoEditDialog}>
                <Edit />
              </IconButton>
            </Grid>
          </Box>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: '35%' }}>Chinese Name</TableCell>
                <TableCell>{singleCase.chineseName || '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>English Name</TableCell>
                <TableCell>{singleCase.englishName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>HK ID No.</TableCell>
                <TableCell>{singleCase.hkIdNo}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Date of Birth</TableCell>
                <TableCell>
                  {singleCase.dob
                    ? dayjs(singleCase.dob).format('DD-MM-YYYY')
                    : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Phone</TableCell>
                <TableCell>
                  <Box py={1}>
                    Mobile(1) {R.path(['phone', 'mobile1'], singleCase) || '-'}{' '}
                    <br />
                    Mobile(2) {R.path(['phone', 'mobile2'], singleCase) ||
                      '-'}{' '}
                    <br />
                    Home {R.path(['phone', 'home'], singleCase) || '-'} <br />
                    Work {R.path(['phone', 'work'], singleCase) || '-'} <br />
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email(1)</TableCell>
                <TableCell>{singleCase.email1 || '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email(2)</TableCell>
                <TableCell>{singleCase.email2 || '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Address</TableCell>
                <TableCell>
                  <Box py={1}>
                    {['line1', 'line2', 'line3', 'region', 'country'].map(
                      name => {
                        const value = R.path(['address', name], singleCase)
                        return value ? <div key={name}>{value}</div> : null
                      }
                    )}
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Officer</TableCell>
                <TableCell>
                  <Box py={1}>
                    {singleCase.officer
                      ? singleCase.officer.name +
                        ` (${singleCase.officer.customOfficerId})`
                      : '-'}

                    <Box my={1}>
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={this.toggleOfficerHistory}
                      >
                        Officers History
                        {showOfficerHistory ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )}
                      </Button>
                    </Box>
                    <Collapse in={showOfficerHistory}>
                      {singleCase.handoverHistory.map((record, index) => (
                        <div key={index}>
                          {record.officerName +
                            ` (${record.customOfficerId}) on ` +
                            dayjs(record.createdAt).format('DD/MM/YYYY')}
                        </div>
                      ))}
                    </Collapse>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Box mb={2} />
        <Paper>
          <Box pl={3} pr={1} py={2}>
            <Grid container justify='space-between' alignItems='center'>
              <Typography variant='h6' component='h2'>
                Latest Forms
              </Typography>
              <Button
                color='primary'
                component={Link}
                to={`/forms-control?search=${singleCase.customCaseId}`}
              >
                All Forms
              </Button>
            </Grid>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Create Date</TableCell>
                <TableCell>Ref No.</TableCell>
                <TableCell>Form</TableCell>
                <TableCell>Last update</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {caseForms.slice(0, 3).map(form => (
                <TableRow key={form.id}>
                  <TableCell>
                    {dayjs(form.createdAt).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    <Button
                      color='primary'
                      component={Link}
                      to={`/form-detail/${form.id}`}
                    >
                      {form.refNo}
                    </Button>
                  </TableCell>
                  <TableCell>
                    {R.propOr(form.type, form.type, formTypes)}
                  </TableCell>
                  <TableCell>
                    {dayjs(form.updatedAt).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    {R.propOr(form.status, form.status, formStatus)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <Box mb={2} />
        <Paper>
          <Box pl={3} pr={1} py={2}>
            <Grid container justify='space-between' alignItems='center'>
              <Typography variant='h6' component='h2'>
                Recent Reminders
              </Typography>
              <Button
                color='primary'
                component={Link}
                to={`/case-detail/${singleCase.id}/reminders`}
              >
                All Reminders
              </Button>
            </Grid>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Reminder name</TableCell>
                <TableCell>Content(TC)</TableCell>
                <TableCell>Content(EN)</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {caseReminders.slice(0, 3).map(message => {
                const reminder = parseReminder(message.reminder, message.params)
                return (
                  <TableRow key={message.id}>
                    <TableCell>
                      {dayjs(message.createdAt).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      <Button
                        color='primary'
                        component={Link}
                        to={`/reminder-detail/${reminder.id}`}
                      >
                        {reminder['name']}
                      </Button>
                    </TableCell>
                    <TableCell>{nl2br(reminder['contentTc'])}</TableCell>
                    <TableCell>{nl2br(reminder['contentEn'])}</TableCell>
                    <TableCell>
                      {message.read
                        ? 'Read ' +
                          dayjs(message.readAt).format('DD/MM/YYYY h:mma')
                        : 'Unread'}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Paper>
        <Box mb={2} />
        <Paper>
          <Box pl={3} pr={1} py={2}>
            <Grid container justify='space-between' alignItems='center'>
              <Typography variant='h6' component='h2'>
                Contribution Summary
              </Typography>
              <Button
                color='primary'
                component={Link}
                to={`/case-detail/${singleCase.id}/contributions`}
              >
                Contribution History
              </Button>
            </Grid>
          </Box>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: '35%' }}>
                  Total Contribution
                </TableCell>
                <TableCell>${' ' + totalContributions}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>This month Contribution</TableCell>
                <TableCell>${' ' + monthlyContributions}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <CaseDetailEditDialog
          open={showCaseDetailEditDialog}
          onClose={this.toggleCaseDetailEditDialog}
        />
        <PersonalInfoEditDialog
          open={showPersonalInfoEditDialog}
          onClose={this.togglePersonalInfoEditDialog}
        />
      </div>
    )
  }
}

export default CaseDetail
