import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Box, Button, withStyles } from '@material-ui/core'

import ReminderDeleteConfirmDialog from './ReminderDeleteConfirmDialog'
import ReminderMassSendDialog from './ReminderMassSendDialog'
import SuccessDialog from '../SuccessDialog'
import history from '../../history'

const styles = theme => ({
  toolbarButton: {
    marginRight: theme.spacing(1)
  }
})

@inject('reminderStore')
@observer
class ReminderDetailToolbar extends React.Component {
  state = {
    showConfirmDeleteDialog: false,
    showSuccessDeleteDialog: false,
    showMassSendDialog: false
  }

  onConfirmDelete = async () => {
    const { reminderStore } = this.props
    const { singleReminder } = reminderStore
    await reminderStore.deleteReminder(singleReminder.id)

    this.toggleConfirmDeleteDialog()
    this.openSuccessDeleteDialog()
  }

  toggleConfirmDeleteDialog = () => {
    this.setState(state => ({
      showConfirmDeleteDialog: !state.showConfirmDeleteDialog
    }))
  }

  toggleMassSendDialog = () => {
    this.setState(state => ({
      showMassSendDialog: !state.showMassSendDialog
    }))
  }

  openSuccessDeleteDialog = () => {
    this.setState({
      showSuccessDeleteDialog: true
    })
  }

  handleDismissSuccessDeleteDialog = () => {
    history.push('/reminders')
  }

  render () {
    const {
      showConfirmDeleteDialog,
      showSuccessDeleteDialog,
      showMassSendDialog
    } = this.state
    const { reminderStore, classes } = this.props
    const { singleReminder } = reminderStore

    return (
      <Fragment>
        <Box>
          <Button
            className={classes.toolbarButton}
            variant='contained'
            color='primary'
            onClick={this.toggleMassSendDialog}
          >
            Send
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={this.toggleConfirmDeleteDialog}
          >
            Delete
          </Button>
        </Box>

        <ReminderDeleteConfirmDialog
          open={showConfirmDeleteDialog}
          onClose={this.toggleConfirmDeleteDialog}
          onConfirm={this.onConfirmDelete}
          reminder={singleReminder}
        />
        <SuccessDialog
          open={showSuccessDeleteDialog}
          content='The reminder has been removed successfully'
          onClose={this.handleDismissSuccessDeleteDialog}
        />
        <ReminderMassSendDialog
          open={showMassSendDialog}
          onClose={this.toggleMassSendDialog}
        />
      </Fragment>
    )
  }
}

export default withStyles(styles)(ReminderDetailToolbar)
