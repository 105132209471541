import * as R from 'ramda'
import React from 'react'
import PropTypes from 'prop-types'
import { TableRow, TableCell } from '@material-ui/core'

const FieldRows = ({ data, fields }) =>
  fields.map(field => (
    <TableRow key={field.name}>
      <TableCell style={{ width: '35%' }}>{field.name}</TableCell>
      <TableCell>
        {field.options
          ? R.propOr('-', R.prop(field.property, data), field.options)
          : R.compose(
              R.ifElse(
                R.is(Boolean),
                value => (value ? '是' : '否'),
                R.identity
              ),
              R.propOr('-', field.property)
            )(data)}
      </TableCell>
    </TableRow>
  ))

FieldRows.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      property: PropTypes.string.isRequired,
      options: PropTypes.objectOf(PropTypes.string)
    })
  ).isRequired
}

export default FieldRows
