import * as R from 'ramda'
import * as yup from 'yup'
import React from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Grid
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import TextFieldMaterial from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { formatDate } from '../../utils/formatDate'

const validationSchema = yup.object().shape({
  customCaseId: yup
    .string()
    .matches(/^\d+\/\d{4}$/, 'Invalid ID')
    .required('Case ID is a required field'),
  hcbNo: yup
    .string()
    .matches(/^\d{4,5}\/\d{4}$/, 'Invalid HCB No.')
    .required('HCB No. is a required field'),
  boDate: yup
    .string()
    .matches(/\d{1,2}-\d{1,2}-\d{4}/, 'Invalid date')
    .required('BO Date is a required field'),
  cmDate: yup
    .string()
    .matches(/\d{1,2}-\d{1,2}-\d{4}/, 'Invalid date')
    .required('CM Date is a required field'),
  bankruptCount: yup.number().required(),
  dischargeDate: yup
    .string()
    .matches(/\d{1,2}-\d{1,2}-\d{4}/, 'Invalid date')
    .nullable(),
  annulledDate: yup
    .string()
    .matches(/\d{1,2}-\d{1,2}-\d{4}/, 'Invalid date')
    .nullable()
})

@inject('caseStore')
@observer
class CaseDetailEditDialog extends React.Component {
  handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const { caseStore, onClose } = this.props
    const { singleCase } = caseStore
    let caseValues = { ...values }
    const customCaseId = 'JW/OT/' + caseValues.customCaseId
    caseValues.customCaseId = customCaseId
    caseValues = formatDate({ ...caseValues }, true)
    await caseStore.updateCase(singleCase.id, caseValues)

    setSubmitting(false)

    if (caseStore.error) {
      const { code, fields } = caseStore.error
      if (code === 'UNIQUE_CONSTRAINT_ERROR') {
        const errorObj = fields.reduce((acc, field) => {
          acc[field] = `The entered value is being used.`
          return acc
        }, {})
        setErrors(errorObj)
      }
    } else {
      // success
      caseStore.fetchSingleCase(singleCase.id)
      onClose()
    }
  }

  render () {
    const { open, onClose, caseStore } = this.props
    const { singleCase } = caseStore
    let initialValues = R.pick([
      'customCaseId',
      'hcbNo',
      'boDate',
      'cmDate',
      'bankruptCount',
      'dischargeDate',
      'annulledDate'
    ])(singleCase)
    initialValues.customCaseId = initialValues.customCaseId.replace(
      'JW/OT/',
      ''
    )
    initialValues = formatDate(initialValues, false)

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>Edit Case Detail</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.handleSubmit}
          enableReinitialize
        >
          {() => (
            <Form>
              <DialogContent>
                <Field
                  label='Case ID*'
                  name='customCaseId'
                  placeholder='10735/2018'
                  fullWidth
                  component={CustomInputComponent}
                />
                <Box mb={2} />
                <Field
                  label='HCB No.*'
                  name='hcbNo'
                  placeholder='4-5 Digital No./YYYY'
                  fullWidth
                  component={TextField}
                />
                <Box mb={2} />
                <Grid container spacing={24}>
                  <Grid item xs={12} md={6}>
                    <Field
                      label='BO Date*'
                      name='boDate'
                      placeholder='DD-MM-YYYY'
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      label='CM Date*'
                      name='cmDate'
                      placeholder='DD-MM-YYYY'
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={24}>
                  <Grid item xs={12} md={6}>
                    <Field
                      type='number'
                      label='Number of times of Bankruptcy*'
                      name='bankruptCount'
                      placeholder='1'
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      label='Discharge Date'
                      name='dischargeDate'
                      placeholder='DD-MM-YYYY'
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={24}>
                  <Grid item xs={12} md={6}>
                    <Field
                      label='Annulled Date'
                      name='annulledDate'
                      placeholder='DD-MM-YYYY'
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} color='primary'>
                  Close
                </Button>
                <Button type='submit' color='primary' variant='contained'>
                  Confirm
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    )
  }
}

CaseDetailEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

const CustomInputComponent = ({ field, form: { errors }, ...props }) => {
  return (
    <TextFieldMaterial
      type='text'
      {...field}
      {...props}
      error={!!errors.customCaseId}
      helperText={errors.customCaseId}
      InputProps={{
        startAdornment: <InputAdornment position='start'>JW/OT/</InputAdornment>
      }}
    />
  )
}

export default CaseDetailEditDialog
