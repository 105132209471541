import apiClient from './modules/client'
import * as paths from './modules/paths'

export const fetchFormList = (
  accessToken,
  { limit, offset, keyword, orderBy, order, status, type }
) =>
  apiClient.get(paths.formIndex, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: {
      limit,
      offset,
      search: keyword,
      orderBy,
      order,
      status,
      type
    }
  })

export const fetchSingleForm = (accessToken, id) =>
  apiClient.get(`${paths.formIndex}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const approveForm = (accessToken, id) =>
  apiClient.post(
    `${paths.formIndex}/${id}/approve`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )

export const rejectForm = (accessToken, id, comment) =>
  apiClient.post(
    `${paths.formIndex}/${id}/reject`,
    {
      comment
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )

export const fetchCaseForms = (accessToken, { caseId, limit, offset }) =>
  apiClient.get(`${paths.caseIndex}/${caseId}/forms`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: {
      limit,
      offset
    }
  })
