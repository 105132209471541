import * as R from 'ramda'
import React from 'react'
import {
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography
} from '@material-ui/core'

import FieldRows from './FieldRows'

const topFields = [
  {
    name: '日期',
    property: 'date'
  }
]

const reasonTranslations = {
  MEDICAL: '醫療',
  TAXES: '稅項',
  EDUCATION: '教育',
  RENT: '租金',
  MANAGEMENT_FREES: '管理費',
  CHANGE_IN_FAMILY_MEMBERS: '家庭成員變更'
}

class ApplicationFormForContributionAdjustment extends React.Component {
  render () {
    const { data } = this.props

    return (
      <div>
        <Box pl={2}>
          <Typography variant='subtitle1'>調整開支申請表</Typography>
        </Box>

        <Table>
          <TableBody>
            <FieldRows data={data} fields={topFields} />
            <TableRow>
              <TableCell>原因</TableCell>
              <TableCell>
                {data.reasons.map((item, i) => (
                  <div key={i}>{R.propOr(item, item, reasonTranslations)}</div>
                ))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default ApplicationFormForContributionAdjustment
