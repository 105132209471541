import React from 'react'
import { inject, observer } from 'mobx-react'
import { Switch, Route } from 'react-router-dom'

import { publicRoutes } from '../../routes'
import LoadingPage from '../../pages/LoadingPage'
import Layout from './Layout'

@inject('authStore')
@observer
class LoadingController extends React.Component {
  // render content until authStore is ready,
  // otherwise show loading
  render () {
    const { authStore } = this.props
    if (authStore.isReady) {
      return (
        <Switch>
          {publicRoutes.map(config => (
            <Route key={config.path} {...config} />
          ))}
          <Route path='/' component={Layout} />
        </Switch>
      )
    } else {
      return <LoadingPage />
    }
  }
}

export default LoadingController
