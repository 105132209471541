import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Box,
  Grid,
  Button,
  Paper,
  Table,
  TableBody,
  Typography,
  TableRow,
  TableCell,
  IconButton
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

import Loading from '../components/layouts/Loading'
import ReminderDetailToolbar from '../components/reminders/ReminderDetailToolbar'
import ReminderEditDialog from '../components/reminders/ReminderEditDialog'
import { nl2br } from '../utils/reminder'

@inject('reminderStore')
@observer
class ReminderDetail extends React.Component {
  state = {
    showEditDialog: false
  }

  componentDidMount () {
    const { reminderStore, match } = this.props
    const { reminderId } = match.params
    reminderStore.fetchSingleReminder(reminderId)
  }

  toggleEditDialog = () => {
    this.setState(state => ({
      showEditDialog: !state.showEditDialog
    }))
  }

  render () {
    const { reminderStore } = this.props
    const { singleReminder } = reminderStore
    const { showEditDialog } = this.state

    if (!singleReminder) {
      return <Loading fullHeight />
    }

    return (
      <div>
        <Box mb={2}>
          <Button color='primary' component={Link} to='/reminders'>
            Reminder
          </Button>
          / <Typography display='inline'>{singleReminder.name}</Typography>
        </Box>
        {!singleReminder.readOnly && (
          <Box mb={2}>
            <ReminderDetailToolbar />
          </Box>
        )}
        <Paper>
          <Box pl={3} pr={1} py={2}>
            <Grid container justify='space-between' alignItems='center'>
              <Typography variant='h6' component='h2'>
                Detail
              </Typography>
            </Grid>
          </Box>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Update date</TableCell>
                <TableCell>
                  {dayjs(singleReminder.updatedAt).format('DD/MM/YYYY')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Creation date</TableCell>
                <TableCell>
                  {dayjs(singleReminder.createdAt).format('DD/MM/YYYY')}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Box mb={2} />
        <Paper>
          <Box pl={3} pr={1} py={2}>
            <Grid container justify='space-between' alignItems='center'>
              <Typography variant='h6' component='h2'>
                Info
              </Typography>
              {!singleReminder.readOnly && (
                <IconButton onClick={this.toggleEditDialog}>
                  <Edit />
                </IconButton>
              )}
            </Grid>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>{singleReminder.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Content(TC)</TableCell>
                  <TableCell>{nl2br(singleReminder['contentTc'])}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Content(EN)</TableCell>
                  <TableCell>{nl2br(singleReminder['contentEn'])}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Paper>
        <ReminderEditDialog
          open={showEditDialog}
          onClose={this.toggleEditDialog}
        />
      </div>
    )
  }
}

export default ReminderDetail
