import apiClient from './modules/client'
import * as paths from './modules/paths'

export const fetchOfficerList = (
  accessToken,
  { limit, offset, orderBy, order }
) =>
  apiClient.get(paths.officerIndex, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: {
      limit,
      offset,
      orderBy,
      order
    }
  })

export const fetchOfficerOptions = accessToken =>
  apiClient.get(paths.officerOptions, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const fetchSingleOfficer = (accessToken, id) =>
  apiClient.get(`${paths.officerIndex}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const createOfficer = (accessToken, payload) =>
  apiClient.post(paths.officerIndex, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const updateOfficer = (accessToken, id, payload) =>
  apiClient.patch(`${paths.officerIndex}/${id}`, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const deleteOfficer = (accessToken, id) =>
  apiClient.delete(`${paths.officerIndex}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
