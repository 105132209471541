import React from 'react'

import MonthlyIncomeProofAndContributionNotice from './formData/MonthlyIncomeProofAndContributionNotice'
import ApplicationFormForContributionAdjustment from './formData/ApplicationFormForContributionAdjustment'
import NoticeOfExtraIncome from './formData/NoticeOfExtraIncome'
import ChangeOfPersonalInformationNotice from './formData/ChangeOfPersonalInformationNotice'
import NoticeOfDeparture from './formData/NoticeOfDeparture'

class FormData extends React.Component {
  render () {
    const { type, data } = this.props

    if (type === 'MONTHLY_INCOME_PROOF_AND_CONTRIBUTION_NOTICE') {
      return <MonthlyIncomeProofAndContributionNotice data={data} />
    }

    if (type === 'APPLICATION_FORM_FOR_CONTRIBUTION_ADJUSTMENT') {
      return <ApplicationFormForContributionAdjustment data={data} />
    }

    if (type === 'NOTICE_OF_EXTRA_INCOME') {
      return <NoticeOfExtraIncome data={data} />
    }

    if (type === 'CHANGE_OF_PERSONAL_INFORMATION_NOTICE') {
      return <ChangeOfPersonalInformationNotice data={data} />
    }

    if (type === 'NOTICE_OF_DEPARTURE') {
      return <NoticeOfDeparture data={data} />
    }

    return null
  }
}

export default FormData
