import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Box,
  Grid,
  Button,
  Paper,
  Table,
  TableHead,
  TableBody,
  Typography,
  TableRow,
  TableCell,
  withStyles
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { Link } from 'react-router-dom'

import ReminderDeleteConfirmDialog from '../components/reminders/ReminderDeleteConfirmDialog'
import ReminderCreateDialog from '../components/reminders/ReminderCreateDialog'
import SuccessDialog from '../components/SuccessDialog'

const styles = theme => ({
  deleteButton: {
    color: red[500]
  }
})

@inject('reminderStore')
@observer
class ReminderIndex extends React.Component {
  state = {
    targetReminder: null,
    showConfirmDeleteDialog: false,
    showSuccessDeleteDialog: false,
    showCreateDialog: false
  }

  componentDidMount () {
    const { reminderStore } = this.props
    reminderStore.fetchReminderList()
  }

  onDeleteBtnClicked = reminder => () => {
    this.setState({
      targetReminder: reminder,
      showConfirmDeleteDialog: true
    })
  }

  onConfirmDelete = async () => {
    const { targetReminder } = this.state
    const { reminderStore } = this.props
    await reminderStore.deleteReminder(targetReminder.id)
    reminderStore.fetchReminderList()

    this.toggleConfirmDeleteDialog()
    this.toggleSuccessDeleteDialog()
  }

  toggleConfirmDeleteDialog = () => {
    this.setState(state => ({
      showConfirmDeleteDialog: !state.showConfirmDeleteDialog
    }))
  }

  toggleSuccessDeleteDialog = () => {
    this.setState(state => ({
      showSuccessDeleteDialog: !state.showSuccessDeleteDialog
    }))
  }

  toggleCreateDialog = () => {
    this.setState(state => ({
      showCreateDialog: !state.showCreateDialog
    }))
  }

  render () {
    const {
      showConfirmDeleteDialog,
      showSuccessDeleteDialog,
      showCreateDialog,
      targetReminder
    } = this.state
    const { reminderStore, classes } = this.props
    const { reminderList } = reminderStore

    return (
      <Fragment>
        <Box mb={2}>
          <Paper>
            <Box p={3}>
              <Typography variant='h6' component='h2'>
                Default Reminders
              </Typography>
            </Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Reminder Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reminderList
                  .filter(row => row.readOnly)
                  .map(row => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Button
                          color='primary'
                          component={Link}
                          to={`/reminder-detail/${row.id}`}
                        >
                          {row.name}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Paper>
        </Box>
        <Paper>
          <Box p={3}>
            <Grid container justify='space-between'>
              <Typography variant='h6' component='h2'>
                Free Text Reminders
              </Typography>
              <Button
                color='primary'
                variant='contained'
                onClick={this.toggleCreateDialog}
              >
                Add
              </Button>
            </Grid>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Reminder Name</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {reminderList
                .filter(row => !row.readOnly)
                .map(row => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Button
                        color='primary'
                        component={Link}
                        to={`/reminder-detail/${row.id}`}
                      >
                        {row.name}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        className={classes.deleteButton}
                        onClick={this.onDeleteBtnClicked(row)}
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
        {targetReminder && (
          <ReminderDeleteConfirmDialog
            open={showConfirmDeleteDialog}
            onClose={this.toggleConfirmDeleteDialog}
            onConfirm={this.onConfirmDelete}
            reminder={targetReminder}
          />
        )}
        <SuccessDialog
          open={showSuccessDeleteDialog}
          content='The reminder has been removed successfully'
          onClose={this.toggleSuccessDeleteDialog}
        />
        <ReminderCreateDialog
          open={showCreateDialog}
          onClose={this.toggleCreateDialog}
        />
      </Fragment>
    )
  }
}

export default withStyles(styles)(ReminderIndex)
