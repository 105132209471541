import React from 'react'
import { Box, Grid, Typography, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

class ForgotPwSuccess extends React.Component {
  render () {
    return (
      <Grid
        className='page forgot-pw-success'
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justify='center'
      >
        <Typography component='h1' variant='h5' color='secondary'>
          Success!
        </Typography>
        <Box mb={5} />
        <Typography variant='body1' color='secondary'>
          The reset password link has now been sent to your email.
        </Typography>
        <Typography variant='body1' color='secondary'>
          Please click "OK" in order to return login page.
        </Typography>
        <Box mb={5} />
        <Box width={350}>
          <Button
            variant='contained'
            color='secondary'
            fullWidth
            component={Link}
            to='/login'
          >
            OK
          </Button>
        </Box>
      </Grid>
    )
  }
}

export default ForgotPwSuccess
