export const status = {
  PENDING: 'Processing',
  APPROVED: 'Approved',
  REJECTED: 'Rejected'
}

export const types = {
  MONTHLY_INCOME_PROOF_AND_CONTRIBUTION_NOTICE:
    'Monthly Income Proof & Contribution Notice',
  APPLICATION_FORM_FOR_CONTRIBUTION_ADJUSTMENT:
    'Application Form for Contribution Adjustment',
  CHANGE_OF_PERSONAL_INFORMATION_NOTICE:
    'Change of Personal Information Notice',
  NOTICE_OF_EXTRA_INCOME: 'Notice of Extra Income',
  NOTICE_OF_DEPARTURE: 'Notice of Departure'
}
