import { observable, action, runInAction, reaction } from 'mobx'

import * as api from '../services/api'
import { itemsPerPage } from '../constants/pagination'

class CaseStore {
  @observable caseList = []
  @observable caseOptions = []
  @observable singleCase = null
  @observable totalCount = 0
  @observable page = 1
  @observable filter = {
    keyword: '',
    orderBy: 'customCaseId',
    order: 'DESC'
  }
  @observable error = null

  constructor (rootStore) {
    this.rootStore = rootStore
  }

  @action
  setPage = page => {
    this.page = page
  }

  @action
  updateFilter = filter => {
    this.filter = {
      ...this.filter,
      ...filter
    }
    // reset page after updating filter
    this.page = 1
  }

  fetchCaseList = async () => {
    const { accessToken } = this.rootStore.authStore
    const { keyword, orderBy, order } = this.filter
    try {
      const result = await api.fetchCaseList(accessToken, {
        limit: itemsPerPage,
        offset: (this.page - 1) * itemsPerPage,
        keyword,
        orderBy,
        order
      })
      runInAction(() => {
        this.caseList = result.rows
        this.totalCount = result.count
      })
    } catch (error) {
      console.log(error)
    }
  }

  fetchSingleCase = async id => {
    const { accessToken } = this.rootStore.authStore
    try {
      const result = await api.fetchSingleCase(accessToken, id)
      runInAction(() => {
        this.singleCase = result
      })
    } catch (error) {
      console.log(error)
    }
  }

  fetchCaseOptions = async () => {
    const { accessToken } = this.rootStore.authStore
    try {
      const result = await api.fetchCaseOptions(accessToken)
      runInAction(() => {
        this.caseOptions = result
      })
    } catch (error) {
      console.log(error)
    }
  }

  @action
  listenAndFetchCaseList = () => {
    this.page = 1
    return reaction(
      () => ({
        page: this.page,
        filter: this.filter
      }),
      () => this.fetchCaseList()
    )
  }

  @action
  clearError = () => {
    this.error = null
  }

  createCase = async newCase => {
    this.clearError()
    const { accessToken } = this.rootStore.authStore
    try {
      await api.createCase(accessToken, newCase)
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.error = error
      })
    }
  }

  updateCase = async (id, payload) => {
    this.clearError()
    const { accessToken } = this.rootStore.authStore
    try {
      await api.updateCase(accessToken, id, payload)
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.error = error
      })
    }
  }

  disableCase = async id => {
    const { accessToken } = this.rootStore.authStore
    try {
      await api.disableCase(accessToken, id)
    } catch (error) {
      console.log(error)
    }
  }

  unlockNoObjectionLetter = async id => {
    const { accessToken } = this.rootStore.authStore
    try {
      await api.updateNoObjectionLetterStatus(accessToken, id, false)
    } catch (error) {
      console.log(error)
    }
  }

  lockNoObjectionLetter = async id => {
    const { accessToken } = this.rootStore.authStore
    try {
      await api.updateNoObjectionLetterStatus(accessToken, id, true)
    } catch (error) {
      console.log(error)
    }
  }

  searchCase = async query => {
    const { accessToken } = this.rootStore.authStore
    try {
      const result = await api.searchCase(accessToken, query)
      runInAction(() => {
        this.singleCase = result
      })
    } catch (error) {
      console.log(error)
    }
  }

  transferCases = async (officerId, caseIds) => {
    const { accessToken } = this.rootStore.authStore
    try {
      await api.transferCases(accessToken, officerId, caseIds)
    } catch (error) {
      console.log(error)
    }
  }

  importFromCsv = async file => {
    this.clearError()
    const { accessToken } = this.rootStore.authStore
    try {
      const result = await api.importCasesFromCsv(accessToken, file)
      return result
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.error = error
      })
    }
  }
}

export default CaseStore
