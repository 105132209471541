import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Box, Grid, Button, Chip, withStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import dayjs from 'dayjs'

import CaseDetailConfirmDialog from './CaseDetailConfirmDialog'

const styles = theme => ({
  disabled: {
    color: red[500]
  }
})

@inject('caseStore')
@observer
class CaseDetailToolbar extends React.Component {
  state = {
    showDisableDialog: false,
    showUnlockDialog: false
  }

  onConfirmDisable = async () => {
    const { caseStore } = this.props
    const { singleCase } = caseStore
    await caseStore.disableCase(singleCase.id)
    // fetch case again when done
    await caseStore.fetchSingleCase(singleCase.id)
    this.toggleDisableDialog()
  }

  onConfirmUnlock = async () => {
    const { caseStore } = this.props
    const { singleCase } = caseStore
    if (singleCase.noObjectionLetterUnlocked) {
      await caseStore.lockNoObjectionLetter(singleCase.id)
    } else {
      await caseStore.unlockNoObjectionLetter(singleCase.id)
    }
    // fetch case again when done
    await caseStore.fetchSingleCase(singleCase.id)
    this.toggleUnlockDialog()
  }

  toggleDisableDialog = () => {
    this.setState(state => ({
      showDisableDialog: !state.showDisableDialog
    }))
  }

  toggleUnlockDialog = () => {
    this.setState(state => ({
      showUnlockDialog: !state.showUnlockDialog
    }))
  }

  getNoObjectionLetterLabel = () => {
    const { caseStore } = this.props
    const { singleCase } = caseStore

    if (singleCase.noObjectionLetterAppliedAt) {
      return (
        <Box ml={2}>
          <Chip
            color='primary'
            label={`No Objection Letter was applied on ${dayjs(
              singleCase.noObjectionLetterAppliedAt
            ).format('DD/MM/YYYY')}`}
          />
        </Box>
      )
    }

    if (singleCase.noObjectionLetterUnlocked) {
      return (
        <Box ml={2}>
          <Chip
            label={`No Objection Letter was unlocked on ${dayjs(
              singleCase.noObjectionLetterUnlockedAt
            ).format('DD/MM/YYYY')}`}
          />
        </Box>
      )
    }

    return null
  }

  render () {
    const { showDisableDialog, showUnlockDialog } = this.state
    const { caseStore, classes } = this.props
    const { singleCase } = caseStore

    if (singleCase.disabled) {
      return (
        <Chip
          className={classes.disabled}
          label={`This user was disabled on ${dayjs(
            singleCase.disabledAt
          ).format('DD/MM/YYYY')}`}
        />
      )
    } else {
      return (
        <Fragment>
          <Grid container justify='space-between'>
            <Grid item>
              <Grid container alignItems='center'>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={this.toggleUnlockDialog}
                >
                  {singleCase.noObjectionLetterUnlocked ? 'Return' : 'Unlock'}{' '}
                  No Objection Letter
                </Button>
                {this.getNoObjectionLetterLabel()}
              </Grid>
            </Grid>

            <Button
              variant='contained'
              color='secondary'
              onClick={this.toggleDisableDialog}
            >
              Disable Case
            </Button>
          </Grid>
          <CaseDetailConfirmDialog
            open={showDisableDialog}
            variant='disable'
            caseObj={singleCase}
            onConfirm={this.onConfirmDisable}
            onClose={this.toggleDisableDialog}
          />
          <CaseDetailConfirmDialog
            open={showUnlockDialog}
            variant={singleCase.noObjectionLetterUnlocked ? 'lock' : 'unlock'}
            caseObj={singleCase}
            onConfirm={this.onConfirmUnlock}
            onClose={this.toggleUnlockDialog}
          />
        </Fragment>
      )
    }
  }
}

export default withStyles(styles)(CaseDetailToolbar)
