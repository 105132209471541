import * as yup from 'yup'
import React from 'react'
import { inject, observer } from 'mobx-react'
import { Box, Grid, Typography, Button, Link } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import { Link as RouterLink } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'

import logo from '../images/hkdm_logo.png'

const initialValues = {
  email: '',
  password: ''
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
  password: yup.string().required()
})

@inject('authStore')
@observer
class Login extends React.Component {
  handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const { authStore } = this.props
    const { email, password } = values

    await authStore.login(email, password)

    if (authStore.error) {
      setErrors({
        password: authStore.error.message
      })
    }

    setSubmitting(false)
  }

  render () {
    return (
      <Grid
        className='page login'
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justify='center'
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.handleSubmit}
        >
          {() => (
            <Form>
              <Box width={350}>
                <Box mb={5}>
                  <div className='logo-container'>
                    <img id='logo' src={logo} alt='logo' />
                  </div>
                  <Typography component='h1' variant='h5' color='secondary'>
                    HKDMS Management System
                  </Typography>
                </Box>
                <Field
                  name='email'
                  placeholder='Email'
                  fullWidth
                  component={TextField}
                />
                <Box mb={2} />
                <Field
                  type='password'
                  name='password'
                  placeholder='Password'
                  fullWidth
                  component={TextField}
                />
                <Box mb={3} />
                <Button
                  type='submit'
                  variant='contained'
                  color='secondary'
                  fullWidth
                >
                  Login
                </Button>
                <Box mb={1} />
                <Grid container justify='flex-end'>
                  <Typography color='secondary'>
                    <Link component={RouterLink} to='/forgot-pw'>
                      Forgot Password?
                    </Link>
                  </Typography>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
    )
  }
}

export default Login
