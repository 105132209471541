import * as R from 'ramda'
import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableHead,
  TableSortLabel,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Toolbar,
  Select,
  MenuItem,
  Typography
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import dayjs from 'dayjs'
import Pagination from 'material-ui-flat-pagination'
import { Link } from 'react-router-dom'
import qs from 'qs'

import { itemsPerPage } from '../constants/pagination'
import { types as formTypes, status as formStatus } from '../constants/form'

@inject('formStore')
@observer
class FormControl extends React.Component {
  componentDidMount () {
    const { formStore, location } = this.props

    const { search } = qs.parse(location.search, {
      ignoreQueryPrefix: true
    })
    if (search) {
      formStore.updateFilter({
        keyword: search
      })
    }

    formStore.fetchFormList()
    this.cancelListen = formStore.listenAndFetchFormList()
  }

  componentWillUnmount () {
    if (this.cancelListen) {
      this.cancelListen()
    }
  }

  onFilterChanged = e => {
    const { formStore } = this.props
    const { name, value } = e.target
    formStore.updateFilter({
      [name]: value
    })
  }

  onSortOrderChanged = property => () => {
    const { formStore } = this.props
    const { filter } = formStore
    if (filter.orderBy === property) {
      formStore.updateFilter({
        order: filter.order === 'ASC' ? 'DESC' : 'ASC'
      })
    } else {
      formStore.updateFilter({
        orderBy: property,
        order: 'DESC'
      })
    }
  }

  onChangePage = (e, offset) => {
    const { formStore } = this.props
    formStore.setPage(offset / itemsPerPage + 1)
  }

  render () {
    const { formStore } = this.props
    const { formList, page, totalCount, filter } = formStore

    return (
      <div>
        <Box mb={2}>
          <Paper>
            <Box px={3}>
              <Toolbar disableGutters>
                <Grid container spacing={8} alignItems='center'>
                  <Grid item>
                    <Search />
                  </Grid>
                  <Grid item>
                    <TextField
                      name='keyword'
                      placeholder='SEARCH'
                      value={filter.keyword}
                      onChange={this.onFilterChanged}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs='auto'>
                    <Box ml={2}>
                      <Typography variant='body1'>Status</Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Select
                      name='status'
                      value={filter.status}
                      onChange={this.onFilterChanged}
                      displayEmpty
                    >
                      <MenuItem value=''>All</MenuItem>
                      {Object.entries(formStatus).map(([status, name]) => (
                        <MenuItem key={status} value={status}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <Box ml={2}>
                      <Typography variant='body1'>Form</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs='auto'>
                    <Select
                      name='type'
                      value={filter.status}
                      onChange={this.onFilterChanged}
                      displayEmpty
                    >
                      <MenuItem value=''>All</MenuItem>
                      {Object.entries(formTypes).map(([type, name]) => (
                        <MenuItem key={type} value={type}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </Toolbar>
            </Box>
          </Paper>
        </Box>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={filter.orderBy === 'createdAt'}
                    direction={filter.order.toLowerCase()}
                    onClick={this.onSortOrderChanged('createdAt')}
                  >
                    Submit Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={filter.orderBy === 'caseId'}
                    direction={filter.order.toLowerCase()}
                    onClick={this.onSortOrderChanged('caseId')}
                  >
                    Case ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={filter.orderBy === 'refNo'}
                    direction={filter.order.toLowerCase()}
                    onClick={this.onSortOrderChanged('refNo')}
                  >
                    Ref No.
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={filter.orderBy === 'type'}
                    direction={filter.order.toLowerCase()}
                    onClick={this.onSortOrderChanged('type')}
                  >
                    Form
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={filter.orderBy === 'status'}
                    direction={filter.order.toLowerCase()}
                    onClick={this.onSortOrderChanged('status')}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formList.map(row => (
                <TableRow key={row.id}>
                  <TableCell>
                    {dayjs(row.createdAt).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    <Button
                      color='primary'
                      component={Link}
                      to={`/case-detail/${row.case['id']}`}
                    >
                      {row.case['customCaseId']}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      color='primary'
                      component={Link}
                      to={`/form-detail/${row.id}`}
                    >
                      {row.refNo}
                    </Button>
                  </TableCell>
                  <TableCell>
                    {R.propOr(row.type, row.type, formTypes)}
                  </TableCell>
                  <TableCell>
                    {R.propOr(row.status, row.status, formStatus)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justify='flex-end'>
            <Pagination
              currentPageColor='primary'
              size='large'
              limit={itemsPerPage}
              offset={(page - 1) * itemsPerPage}
              total={totalCount}
              onClick={this.onChangePage}
            />
          </Grid>
        </Paper>
      </div>
    )
  }
}

export default FormControl
