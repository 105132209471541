import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions
} from '@material-ui/core'

class ConfirmDialog extends React.Component {
  render () {
    const { open, onClose, title, content, onConfirm } = this.props

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogContent>
          <Box py={1}>
            <Typography variant='h6'>{title}</Typography>
            <Box mb={2} />
            <div>{content}</div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='secondary' onClick={onClose}>
            Close
          </Button>
          <Button variant='contained' color='primary' onClick={onConfirm}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  content: PropTypes.node,
  onConfirm: PropTypes.func.isRequired
}

export default ConfirmDialog
