import * as R from 'ramda'
import React from 'react'
import {
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography
} from '@material-ui/core'

import FieldRows from './FieldRows'

const topFields = [
  {
    name: '日期',
    property: 'date'
  },
  {
    name: '就業狀況',
    property: 'employmentStatus',
    options: {
      employed: '在職',
      unemployed: '失業'
    }
  }
]

const incomeFields = [
  {
    name: '薪金',
    property: 'salary'
  },
  {
    name: '佣金',
    property: 'commission'
  },
  {
    name: '津貼',
    property: 'allowance'
  },
  {
    name: '花紅',
    property: 'bonus'
  }
]

const otherIncomeFields = [
  {
    name: '長期服務金',
    property: 'longServicePayment'
  },
  {
    name: '遣散費',
    property: 'sererancePay'
  },
  {
    name: '離職補償',
    property: 'seperationCompensation'
  },
  {
    name: '傷殘',
    property: 'disabilityAllowance'
  },
  {
    name: '高齡津貼',
    property: 'oldAgeAllowance'
  },
  {
    name: '贍養費',
    property: 'alimony'
  },
  {
    name: '長俸',
    property: 'pension'
  },
  {
    name: '其他',
    property: 'others'
  }
]

const bottomFields = [
  {
    name: '綜援',
    property: 'cssa'
  },
  {
    name: '接受供養',
    property: 'acceptance'
  }
]

class MonthlyIncomeProofAndContributionNotice extends React.Component {
  render () {
    const { data } = this.props

    const income = incomeFields.reduce((acc, curr) => {
      return acc + R.propOr(0, curr.property, data)
    }, 0)

    const otherIncome = R.values(data.otherIncomes).reduce(R.add, 0)
    return (
      <div>
        <Box pl={2}>
          <Typography variant='subtitle1'>每月收入及供款通知書</Typography>
        </Box>

        <Table>
          <TableBody>
            <FieldRows data={data} fields={topFields} />
            {incomeFields.map(field => (
              <TableRow key={field.name}>
                <TableCell>{field.name}</TableCell>
                <TableCell>
                  $ {R.propOr(0, field.property, data).toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>其他收入</TableCell>
              <TableCell>$ {otherIncome.toFixed(2)}</TableCell>
            </TableRow>
            {R.keys(data.otherIncomes).length > 0 && (
              <TableRow>
                <TableCell colSpan={2}>
                  <Table>
                    <TableBody>
                      {otherIncomeFields.map(field => {
                        if (data.otherIncomes[field.property]) {
                          return (
                            <TableRow key={field.name}>
                              <TableCell>{field.name}</TableCell>
                              <TableCell>
                                ${' '}
                                {R.propOr(
                                  0,
                                  field.property,
                                  data.otherIncomes
                                ).toFixed(2)}
                              </TableCell>
                            </TableRow>
                          )
                        } else {
                          return null
                        }
                      })}
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>本月總收入</TableCell>
              <TableCell>$ {(income + otherIncome).toFixed(2)}</TableCell>
            </TableRow>
            <FieldRows data={data} fields={bottomFields} />
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default MonthlyIncomeProofAndContributionNotice
