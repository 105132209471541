import apiClient from './modules/client'
import * as paths from './modules/paths'

export const login = (email, password) =>
  apiClient.post(paths.login, {
    userType: 'officer',
    email,
    password
  })

export const refresh = refreshToken =>
  apiClient.post(paths.refresh, {
    refreshToken
  })

export const forgotPassword = email =>
  apiClient.post(paths.forgotPassword, {
    userType: 'officer',
    email
  })

export const resetPassword = (token, newPassword) =>
  apiClient.post(paths.resetPassword, {
    userType: 'officer',
    token,
    newPassword
  })

export const changePassword = (accessToken, { password, newPassword }) =>
  apiClient.post(
    paths.changePassword,
    {
      password,
      newPassword
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
