import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  withStyles
} from '@material-ui/core'
import { NavLink } from 'react-router-dom'

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  drawer: {
    width: 240
  },
  link: {
    color: 'inherit'
  },
  activeLink: {
    color: theme.palette.primary.main
  },
  listItemText: {
    color: 'inherit'
  }
})

@inject('authStore')
@observer
class Sidebar extends React.Component {
  render () {
    const { classes, authStore } = this.props
    const sidebarLinks = [
      {
        to: '/',
        exact: true,
        children: 'Dashboard'
      },
      {
        to: '/case-management',
        children: 'Case Management'
      },
      {
        to: '/first-in',
        children: 'First-in'
      },
      {
        to: '/payment-bookings',
        children: 'Payment Bookings'
      },
      {
        to: '/reminders',
        children: 'Reminders'
      },
      {
        to: '/forms-control',
        children: 'Forms Control'
      },
      {
        to: '/contributions',
        children: 'Contributions'
      }
    ]

    if (authStore.isAdmin || authStore.isSuperAdmin) {
      sidebarLinks.push({
        to: '/officer-management',
        children: 'Users Management'
      })
    }

    return (
      <Drawer variant='permanent' className={classes.drawer}>
        <div className={classes.toolbar} />
        <List>
          {sidebarLinks.map((config, index) => (
            <ListItem key={index}>
              <ListItemText>
                <NavLink
                  {...config}
                  className={classes.link}
                  activeClassName={classes.activeLink}
                />
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Drawer>
    )
  }
}

export default withStyles(styles)(Sidebar)
