import { hot } from 'react-hot-loader/root'
import React from 'react'
import { Provider } from 'mobx-react'
import { Router } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import LoadingController from './components/layouts/LoadingController'
import history from './history'
import RootStore from './stores/RootStore'

const themeColor = '#7791f7'

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: themeColor,
      contrastText: '#fff'
    },
    secondary: {
      main: '#fff',
      light: '#fff',
      dark: '#ececec',
      contrastText: themeColor
    }
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        fontSize: 14
      }
    },
    MuiTableCell: {
      head: {
        fontSize: 14
      }
    }
  }
})

const rootStore = new RootStore()

class App extends React.Component {
  render () {
    return (
      <MuiThemeProvider theme={theme}>
        <Provider {...rootStore}>
          <Router history={history}>
            <LoadingController />
          </Router>
        </Provider>
      </MuiThemeProvider>
    )
  }
}

export default hot(App)
