import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Field, ErrorMessage, connect } from 'formik'
import { Select } from 'formik-material-ui'
import {
  MenuItem,
  FormHelperText,
  Checkbox,
  ListItemText
} from '@material-ui/core'

// an integration of case options with formik select
@inject('caseStore')
@observer
class CaseOptionFormikMultipleSelect extends React.Component {
  componentDidMount () {
    const { caseStore } = this.props
    caseStore.fetchCaseOptions()
  }

  hasError () {
    const { formik, name } = this.props
    const { touched, errors } = formik
    return !!(touched[name] && errors[name])
  }

  render () {
    const { caseStore, name, formik } = this.props
    const { caseOptions } = caseStore
    const { [name]: caseIds } = formik.values

    return (
      <Fragment>
        <Field
          name={name}
          component={Select}
          multiple
          fullWidth
          error={this.hasError()}
          renderValue={selected => `${selected.length} case(s) selected.`}
        >
          {caseOptions.map(singleCase => (
            <MenuItem key={singleCase.id} value={singleCase.id}>
              <Checkbox
                color='primary'
                checked={caseIds.includes(singleCase.id)}
              />
              <ListItemText>
                [{singleCase.customCaseId}] {singleCase.englishName}
              </ListItemText>
            </MenuItem>
          ))}
        </Field>
        <ErrorMessage
          name={name}
          component={FormHelperText}
          error={this.hasError(name)}
        />
      </Fragment>
    )
  }
}

CaseOptionFormikMultipleSelect.propTypes = {
  name: PropTypes.string
}

CaseOptionFormikMultipleSelect.defaultProps = {
  name: 'caseIds'
}

export default connect(CaseOptionFormikMultipleSelect)
