import { observable, action, runInAction, reaction } from 'mobx'
import dayjs from 'dayjs'

import * as api from '../services/api'

class PaymentBookingStore {
  @observable bookingStatus = {
    disabled: false,
    slots: []
  }
  @observable bookingList = []
  @observable filter = {
    date: dayjs().format('YYYY-MM-DD')
  }
  @observable error = null

  constructor (rootStore) {
    this.rootStore = rootStore
  }

  @action
  updateFilter = filter => {
    this.filter = {
      ...this.filter,
      ...filter
    }
  }

  fetchBookingStatus = async () => {
    const { accessToken } = this.rootStore.authStore
    const { date } = this.filter
    try {
      const result = await api.fetchPaymentBookingStatus(accessToken, date)
      runInAction(() => {
        this.bookingStatus = result
      })
    } catch (error) {
      console.log(error)
    }
  }

  fetchBookings = async (date, time) => {
    const { accessToken } = this.rootStore.authStore
    try {
      const result = await api.fetchPaymentBookings(accessToken, date, time)
      runInAction(() => {
        this.bookingList = result.rows
      })
    } catch (error) {
      console.log(error)
    }
  }

  createBooking = async ({ caseId, date, time }) => {
    this.clearError()
    const { accessToken } = this.rootStore.authStore
    try {
      await api.createPaymentBooking(accessToken, { caseId, date, time })
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.error = error
      })
    }
  }

  deleteBooking = async id => {
    const { accessToken } = this.rootStore.authStore
    try {
      await api.deletePaymentBooking(accessToken, id)
    } catch (error) {
      console.log(error)
    }
  }

  enableDate = async () => {
    const { accessToken } = this.rootStore.authStore
    const { date } = this.filter
    try {
      await api.enablePaymentBookingDate(accessToken, date)
    } catch (error) {
      console.log(error)
    }
  }

  disableDate = async () => {
    const { accessToken } = this.rootStore.authStore
    const { date } = this.filter
    try {
      await api.disablePaymentBookingDate(accessToken, date)
    } catch (error) {
      console.log(error)
    }
  }

  updateBookingQuota = async ({ date, time, quota }) => {
    this.clearError()
    const { accessToken } = this.rootStore.authStore
    try {
      await api.updatePaymentBookingQuota(accessToken, { date, time, quota })
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.error = error
      })
    }
  }

  listenAndFetchBookingStatus = () =>
    reaction(() => this.filter.date, () => this.fetchBookingStatus())

  @action
  clearError = () => {
    this.error = null
  }
}

export default PaymentBookingStore
