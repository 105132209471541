import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Box, Typography, withStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors'

import ConfirmDialog from '../ConfirmDialog'

const styles = theme => ({
  errorMsg: {
    color: red[500]
  }
})

@inject('officerStore')
@observer
class OfficerDeleteDialog extends React.Component {
  state = {
    error: null
  }

  onConfirmDelete = async () => {
    const { officerStore, officer, onClose } = this.props
    await officerStore.deleteOfficer(officer.id)

    if (officerStore.error) {
      this.setState({
        error: officerStore.error
      })
    } else {
      // success
      officerStore.fetchOfficerList()
      onClose()
    }
  }

  render () {
    const { open, onClose, officer, classes } = this.props
    const { error } = this.state

    return (
      <ConfirmDialog
        title='Delete this officer?'
        content={
          <div>
            <Typography>
              Note that all diary bookings assigned to this officer will be
              cancelled.
            </Typography>
            <Box className='well' mt={1} p={2}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <Typography>Name</Typography>
                    </td>
                    <td>
                      <Typography variant='body1'>{officer.name}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Officer ID</Typography>
                    </td>
                    <td>
                      <Typography variant='body1'>
                        {officer.customOfficerId}
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
              {error && (
                <Typography className={classes.errorMsg}>
                  {error.message}
                </Typography>
              )}
            </Box>
          </div>
        }
        open={open}
        onClose={onClose}
        onConfirm={this.onConfirmDelete}
      />
    )
  }
}

OfficerDeleteDialog.propTypes = {
  officer: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default withStyles(styles)(OfficerDeleteDialog)
