import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Box,
  Grid,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  Typography,
  TableRow,
  TableCell,
  withStyles
} from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'
import { CloudDownload } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

import Loading from '../components/layouts/Loading'
import { status as formStatus } from '../constants/form'
import FormDetailToolbar from '../components/form/FormDetailToolbar'
import FormData from '../components/form/FormData'

const styles = theme => ({
  approved: {
    color: green[500]
  },
  rejected: {
    color: red[500]
  }
})

@inject('formStore')
@observer
class FormDetail extends React.Component {
  state = {
    isLightboxOpened: false,
    lightboxPhotoIndex: 0
  }

  componentDidMount () {
    const { formStore, match } = this.props
    const { formId } = match.params
    formStore.fetchSingleForm(formId)
  }

  formStatus = (status, reviewedAt) => {
    const { classes } = this.props

    if (status === 'APPROVED' || status === 'REJECTED') {
      return (
        <Typography className={classes[status.toLowerCase()]}>
          {formStatus[status]} on {dayjs(reviewedAt).format('DD/MM/YYYY')}
        </Typography>
      )
    }

    return formStatus[status]
  }

  render () {
    const { isLightboxOpened, lightboxPhotoIndex } = this.state
    const { formStore } = this.props
    const { singleForm } = formStore

    if (!singleForm) {
      return <Loading fullHeight />
    }

    const { proofs } = singleForm

    return (
      <div>
        <Box mb={2}>
          <Button color='primary' component={Link} to='/forms-control'>
            Form Control
          </Button>
          / <Typography display='inline'>Ref {singleForm.refNo}</Typography>
        </Box>
        <Box mb={2}>
          <FormDetailToolbar />
        </Box>
        <Paper>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Case ID</TableCell>
                <TableCell>
                  <Button
                    color='primary'
                    component={Link}
                    to={`/case-detail/${singleForm.case['id']}`}
                  >
                    {singleForm.case['customCaseId']}
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Submit date</TableCell>
                <TableCell>
                  {dayjs(singleForm.createdAt).format('DD/MM/YYYY')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>
                  {this.formStatus(singleForm.status, singleForm.reviewedAt)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Box mb={2} />
        {singleForm.comment && (
          <Fragment>
            <Paper>
              <Box p={2}>
                <Box mb={2}>
                  <Typography variant='h6' component='h2'>
                    Comment
                  </Typography>
                </Box>
                <Typography>{singleForm.comment}</Typography>
              </Box>
            </Paper>
            <Box mb={2} />
          </Fragment>
        )}
        <Paper>
          <Box p={2}>
            <Box mb={3}>
              <Typography variant='h6' component='h2'>
                Form Detail
              </Typography>
            </Box>
            <FormData type={singleForm.type} data={singleForm.data} />
          </Box>
        </Paper>
        <Box mb={2} />
        {singleForm.type !== 'NOTICE_OF_DEPARTURE' && (
          <Paper>
            <Box p={2}>
              <Box mb={2}>
                <Typography variant='h6' component='h2'>
                  Proof ({proofs.length}/10 Uploaded)
                </Typography>
              </Box>
              {proofs.length > 0 && (
                <Grid container spacing={8}>
                  {proofs.map((url, index) => (
                    <Grid key={index} item xs={6} sm={4} md={3} lg={2}>
                      <a
                        href='#'
                        onClick={e => {
                          e.preventDefault()
                          this.setState({
                            isLightboxOpened: true,
                            lightboxPhotoIndex: index
                          })
                        }}
                      >
                        <img src={url} />
                      </a>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          </Paper>
        )}
        {isLightboxOpened && (
          <Lightbox
            mainSrc={proofs[lightboxPhotoIndex]}
            nextSrc={
              proofs.length > lightboxPhotoIndex + 1
                ? proofs[lightboxPhotoIndex + 1]
                : undefined
            }
            prevSrc={
              lightboxPhotoIndex - 1 >= 0
                ? proofs[lightboxPhotoIndex - 1]
                : undefined
            }
            onCloseRequest={() => this.setState({ isLightboxOpened: false })}
            onMovePrevRequest={() =>
              this.setState({
                lightboxPhotoIndex: lightboxPhotoIndex - 1
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                lightboxPhotoIndex: lightboxPhotoIndex + 1
              })
            }
            imageCaption={
              <Typography>
                Image {lightboxPhotoIndex + 1} out of {proofs.length}
              </Typography>
            }
            toolbarButtons={[
              <IconButton
                color='secondary'
                href={proofs[lightboxPhotoIndex]}
                download
              >
                <CloudDownload />
              </IconButton>
            ]}
          />
        )}
      </div>
    )
  }
}

export default withStyles(styles)(FormDetail)
