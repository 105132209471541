import * as yup from 'yup'
import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  Typography,
  FormHelperText
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import nanoId from 'nanoid'

import TextFieldMaterial from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import OfficerOptionFormikSelect from '../officers/OfficerOptionFormikSelect'
import SuccessDialog from '../SuccessDialog'
import { formatDate } from '../../utils/formatDate'

const validationSchema = yup.object().shape({
  customCaseId: yup
    .string()
    .matches(/^\d+\/\d{4}$/, 'Invalid ID')
    .required('Required'),
  hcbNo: yup
    .string()
    .matches(/^\d{4,5}\/\d{4}$/, 'Invalid HCB No.')
    .required('Required'),
  password: yup.string().required(),
  boDate: yup
    .string()
    .matches(/\d{1,2}-\d{1,2}-\d{4}/, 'Invalid date')
    .required('Required'),
  cmDate: yup
    .string()
    .matches(/\d{1,2}-\d{1,2}-\d{4}/, 'Invalid date')
    .required('Required'),
  bankruptCount: yup
    .number()
    .positive()
    .required(),
  dischargeDate: yup.string().matches(/\d{1,2}-\d{1,2}-\d{4}/, 'Invalid date'),
  annulledDate: yup.string().matches(/\d{1,2}-\d{1,2}-\d{4}/, 'Invalid date'),
  chineseName: yup.string(),
  englishName: yup.string().required('Required'),
  hkIdNo: yup.string().required('Required'),
  dob: yup.string().matches(/\d{1,2}-\d{1,2}-\d{4}/, 'Invalid date'),
  phone: yup.object().shape({
    mobile1: yup
      .string()
      .matches(/^(\+\d+)?$/, 'Invalid format')
      .required('Required'),
    mobile2: yup.string().matches(/^(\+\d+)?$/, 'Invalid format'),
    home: yup.string().matches(/^(\+\d+)?$/, 'Invalid format'),
    work: yup.string().matches(/^(\+\d+)?$/, 'Invalid format')
  }),
  email1: yup.string().email(),
  email2: yup.string().email(),
  address: yup.object().shape({
    line1: yup.string(),
    line2: yup.string(),
    line3: yup.string(),
    region: yup.string(),
    country: yup.string()
  }),
  officerId: yup.number().nullable()
})

@inject('caseStore', 'officerStore')
@observer
class CaseCreateDialog extends React.Component {
  state = {
    generatedPw: '',
    success: false
  }

  regeneratePw = () => {
    this.setState({
      generatedPw: nanoId(10)
    })
  }

  toggleSuccessDialog = () => {
    this.setState(state => ({
      success: !state.success
    }))
  }

  getInitialValues = () => {
    const { generatedPw } = this.state
    return {
      customCaseId: '',
      hcbNo: '',
      password: generatedPw,
      boDate: '',
      cmDate: '',
      bankruptCount: 1,
      dischargeDate: '',
      annulledDate: '',
      chineseName: '',
      englishName: '',
      hkIdNo: '',
      dob: '',
      phone: {
        mobile1: '',
        mobile2: '',
        home: '',
        work: ''
      },
      email1: '',
      email2: '',
      address: {
        line1: '',
        line2: '',
        line3: '',
        region: '',
        country: ''
      },
      officerId: null
    }
  }

  handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const { caseStore, onClose } = this.props
    let caseValues = { ...values }
    const customCaseId = 'JW/OT/' + caseValues.customCaseId
    caseValues.customCaseId = customCaseId
    caseValues = formatDate({ ...caseValues }, true)
    await caseStore.createCase(caseValues)

    setSubmitting(false)

    if (caseStore.error) {
      const { code, fields } = caseStore.error
      if (code === 'UNIQUE_CONSTRAINT_ERROR') {
        const errorObj = fields.reduce((acc, field) => {
          acc[field] = `The entered value is being used.`
          return acc
        }, {})
        setErrors(errorObj)
      }
    } else {
      // success
      caseStore.fetchCaseList()
      onClose()
      this.toggleSuccessDialog()
    }
  }

  render () {
    const { open, onClose } = this.props
    const { success } = this.state

    return (
      <Fragment>
        <Dialog
          open={open}
          onEnter={this.regeneratePw}
          onClose={onClose}
          fullWidth
        >
          <DialogTitle>Add Case</DialogTitle>
          <Formik
            initialValues={this.getInitialValues()}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
            enableReinitialize
          >
            {() => (
              <Form>
                <DialogContent>
                  <Field
                    label='Case ID*'
                    name='customCaseId'
                    placeholder='10735/2018'
                    fullWidth
                    component={CustomInputComponent}
                  />
                  <Box mb={2} />
                  <Field
                    label='HCB No.*'
                    name='hcbNo'
                    placeholder='4-5 Digital No./YYYY'
                    fullWidth
                    component={TextField}
                  />
                  <Box mb={2} />
                  <Field
                    label='Password*'
                    name='password'
                    placeholder='Password'
                    fullWidth
                    component={TextField}
                  />
                  <FormHelperText error>
                    Generated password. Note that it will be shown once only,
                    please store it in a safe place.
                  </FormHelperText>
                  <Box mb={2} />
                  <Grid container spacing={24}>
                    <Grid item xs={12} md={6}>
                      <Field
                        label='BO Date*'
                        name='boDate'
                        placeholder='DD-MM-YYYY'
                        fullWidth
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        label='CM Date*'
                        name='cmDate'
                        placeholder='DD-MM-YYYY'
                        fullWidth
                        component={TextField}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={24}>
                    <Grid item xs={12} md={6}>
                      <Field
                        type='number'
                        label='Number of times of Bankruptcy*'
                        name='bankruptCount'
                        placeholder='1'
                        fullWidth
                        min='0'
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        label='Discharge Date'
                        name='dischargeDate'
                        placeholder='DD-MM-YYYY'
                        fullWidth
                        component={TextField}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={24}>
                    <Grid item xs={12} md={6}>
                      <Field
                        label='Annulled Date'
                        name='annulledDate'
                        placeholder='DD-MM-YYYY'
                        fullWidth
                        component={TextField}
                      />
                    </Grid>
                  </Grid>
                  <Box mb={2} />
                  <Field
                    label='Chinese Name'
                    name='chineseName'
                    placeholder='Chinese Name'
                    fullWidth
                    component={TextField}
                  />
                  <Box mb={2} />
                  <Field
                    label='English Name*'
                    name='englishName'
                    placeholder='English Name'
                    fullWidth
                    component={TextField}
                  />
                  <Box mb={2} />
                  <Grid container spacing={24}>
                    <Grid item xs={12} md={6}>
                      <Field
                        label='Date of Birth'
                        name='dob'
                        placeholder='DD-MM-YYYY'
                        fullWidth
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        label='HKID No.*'
                        name='hkIdNo'
                        placeholder='HK ID Number'
                        fullWidth
                        component={TextField}
                      />
                    </Grid>
                  </Grid>
                  <Box mb={3} />

                  <Grid container spacing={24}>
                    <Grid item xs={2}>
                      <Box mt={2}>
                        <Typography variant='subtitle1'>Phone</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={10}>
                      <Field
                        label='Mobile (1)*'
                        name='phone[mobile1]'
                        placeholder='+852XXXXXXXX'
                        fullWidth
                        component={TextField}
                      />
                      <Box mb={2} />
                      <Field
                        label='Mobile (2)'
                        name='phone[mobile2]'
                        placeholder='+852XXXXXXXX'
                        fullWidth
                        component={TextField}
                      />
                      <Box mb={2} />
                      <Field
                        label='Home'
                        name='phone[home]'
                        placeholder='+852XXXXXXXX'
                        fullWidth
                        component={TextField}
                      />
                      <Box mb={2} />
                      <Field
                        label='Work'
                        name='phone[work]'
                        placeholder='+852XXXXXXXX'
                        fullWidth
                        component={TextField}
                      />
                    </Grid>
                  </Grid>
                  <Box mt={2} />

                  <Field
                    label='Email (1)'
                    name='email1'
                    placeholder='Email (1)'
                    fullWidth
                    component={TextField}
                  />
                  <Box mb={2} />
                  <Field
                    label='Email (2)'
                    name='email2'
                    placeholder='Email (2)'
                    fullWidth
                    component={TextField}
                  />
                  <Box mb={2} />

                  <Grid container spacing={24}>
                    <Grid item xs={2}>
                      <Box mt={2}>
                        <Typography variant='subtitle1'>Address</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={10}>
                      <Field
                        label='Line 1'
                        name='address[line1]'
                        placeholder='Line 1'
                        fullWidth
                        component={TextField}
                      />
                      <Box mb={2} />
                      <Field
                        label='Line 2'
                        name='address[line2]'
                        placeholder='Line 2'
                        fullWidth
                        component={TextField}
                      />
                      <Box mb={2} />
                      <Field
                        label='Line 3'
                        name='address[line3]'
                        placeholder='Line 3'
                        fullWidth
                        component={TextField}
                      />
                      <Box mb={2} />
                      <Grid container spacing={24}>
                        <Grid item md={6}>
                          <Field
                            label='Region'
                            name='address[region]'
                            placeholder='Region'
                            fullWidth
                            component={TextField}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <Field
                            label='Country'
                            name='address[country]'
                            placeholder='Country'
                            fullWidth
                            component={TextField}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box mt={2} />

                  <Grid container spacing={24}>
                    <Grid item xs={2}>
                      <Typography variant='subtitle1'>Officer</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <OfficerOptionFormikSelect />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color='primary'>
                    Close
                  </Button>
                  <Button type='submit' color='primary' variant='contained'>
                    Create
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
        <SuccessDialog
          open={success}
          onClose={this.toggleSuccessDialog}
          content='Case has been added successfully'
        />
      </Fragment>
    )
  }
}

const CustomInputComponent = ({ field, form: { errors }, ...props }) => {
  return (
    <TextFieldMaterial
      type='text'
      {...field}
      {...props}
      error={!!errors.customCaseId}
      helperText={errors.customCaseId}
      fullWidth
      InputProps={{
        startAdornment: <InputAdornment position='start'>JW/OT/</InputAdornment>
      }}
    />
  )
}

CaseCreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default CaseCreateDialog
