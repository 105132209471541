import { observable, action, runInAction, reaction } from 'mobx'
import { itemsPerPage } from '../constants/pagination'

import * as api from '../services/api'

class ReminderStore {
  @observable reminderList = []
  @observable totalCount = 0

  @observable caseReminders = []
  @observable caseReminderCount = 0
  @observable page = 1
  @observable targetCaseId = 0 // for fetching case reminders

  @observable singleReminder = null

  @observable error = null

  constructor (rootStore) {
    this.rootStore = rootStore
  }

  @action
  setPage = page => {
    this.page = page
  }

  @action
  setTargetCaseId = caseId => {
    this.targetCaseId = caseId
  }

  fetchReminderList = async () => {
    const { accessToken } = this.rootStore.authStore
    try {
      const result = await api.fetchReminderList(accessToken)
      runInAction(() => {
        this.reminderList = result.rows
        this.totalCount = result.count
      })
    } catch (error) {
      console.log(error)
    }
  }

  fetchSingleReminder = async id => {
    const { accessToken } = this.rootStore.authStore
    try {
      const result = await api.fetchSingleReminder(accessToken, id)
      runInAction(() => {
        this.singleReminder = result
      })
    } catch (error) {
      console.log(error)
    }
  }

  fetchCaseReminders = async () => {
    const { accessToken } = this.rootStore.authStore
    try {
      const result = await api.fetchCaseReminders(
        accessToken,
        this.targetCaseId,
        {
          limit: itemsPerPage,
          offset: (this.page - 1) * itemsPerPage
        }
      )
      runInAction(() => {
        this.caseReminders = result.rows
        this.caseReminderCount = result.count
      })
    } catch (error) {
      console.log(error)
    }
  }

  createReminder = async newReminder => {
    this.clearError()
    const { accessToken } = this.rootStore.authStore
    try {
      await api.createReminder(accessToken, newReminder)
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.error = error
      })
    }
  }

  updateReminder = async (id, payload) => {
    this.clearError()
    const { accessToken } = this.rootStore.authStore
    try {
      await api.updateReminder(accessToken, id, payload)
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.error = error
      })
    }
  }

  deleteReminder = async id => {
    const { accessToken } = this.rootStore.authStore
    try {
      await api.deleteReminder(accessToken, id)
    } catch (error) {
      console.log(error)
    }
  }

  sendCaseReminder = async (caseId, reminderId) => {
    const { accessToken } = this.rootStore.authStore
    try {
      await api.sendCaseReminder(accessToken, caseId, reminderId)
    } catch (error) {
      console.log(error)
    }
  }

  massSendReminder = async (reminderId, caseIds) => {
    const { accessToken } = this.rootStore.authStore
    try {
      await api.massSendReminder(accessToken, reminderId, caseIds)
    } catch (error) {
      console.log(error)
    }
  }

  @action
  listenAndFetchCaseReminders = () => {
    this.page = 1
    return reaction(() => this.page, () => this.fetchCaseReminders())
  }

  @action
  clearError = () => {
    this.error = null
  }
}

export default ReminderStore
