import * as R from 'ramda'
import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableHead,
  TableSortLabel,
  TableBody,
  Typography,
  TableRow,
  TableCell,
  Toolbar,
  withStyles
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { Check, Close } from '@material-ui/icons'
import Pagination from 'material-ui-flat-pagination'

import { itemsPerPage } from '../constants/pagination'
import OfficerFormDialog from '../components/officers/OfficerFormDialog'
import OfficerDeleteDialog from '../components/officers/OfficerDeleteDialog'

const styles = theme => ({
  searchBox: {
    marginRight: theme.spacing(3)
  },
  toolbarButton: {
    marginRight: theme.spacing(1)
  },
  deleteBtn: {
    color: red[500]
  }
})

@inject('authStore', 'officerStore')
@observer
class OfficerManagement extends React.Component {
  state = {
    isCreateDialogOpened: false,
    isEditDialogOpened: false,
    isDeleteDialogOpened: false,
    targetOfficer: null
  }

  componentDidMount () {
    const { officerStore } = this.props
    officerStore.fetchOfficerList()
    this.cancelListen = officerStore.listenAndFetchOfficerList()
  }

  componentWillUnmount () {
    if (this.cancelListen) {
      this.cancelListen()
    }
  }

  onSortOrderChanged = property => () => {
    const { officerStore } = this.props
    const { filter } = officerStore
    if (filter.orderBy === property) {
      officerStore.updateFilter({
        order: filter.order === 'ASC' ? 'DESC' : 'ASC'
      })
    } else {
      officerStore.updateFilter({
        orderBy: property,
        order: 'DESC'
      })
    }
  }

  onChangePage = (e, offset) => {
    const { officerStore } = this.props
    officerStore.setPage(offset / itemsPerPage + 1)
  }

  toggleCreateDialog = () => {
    this.setState(state => ({
      isCreateDialogOpened: !state.isCreateDialogOpened
    }))
  }

  onEditBtnClicked = officer => () => {
    this.setState({
      isEditDialogOpened: true,
      targetOfficer: officer
    })
  }

  onEditDialogClosed = () => {
    this.setState({
      isEditDialogOpened: false,
      targetOfficer: null
    })
  }

  onDeleteBtnClicked = officer => () => {
    this.setState({
      isDeleteDialogOpened: true,
      targetOfficer: officer
    })
  }

  onDeleteDialogClosed = () => {
    this.setState({
      isDeleteDialogOpened: false,
      targetOfficer: null
    })
  }

  render () {
    const {
      isCreateDialogOpened,
      isEditDialogOpened,
      isDeleteDialogOpened,
      targetOfficer
    } = this.state
    const { authStore, officerStore, classes } = this.props
    const { officerList, page, totalCount, filter } = officerStore

    return (
      <div>
        <Box mb={2}>
          <Toolbar disableGutters>
            <Button
              className={classes.toolbarButton}
              variant='contained'
              color='secondary'
              onClick={this.toggleCreateDialog}
            >
              ADD USER
            </Button>
          </Toolbar>
        </Box>
        <Paper>
          <Box p={3}>
            <Typography variant='h6' component='h2'>
              {authStore.isSuperAdmin ? 'Officer/Admin' : 'Officer'} Management
            </Typography>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={filter.orderBy === 'customOfficerId'}
                    direction={filter.order.toLowerCase()}
                    onClick={this.onSortOrderChanged('customOfficerId')}
                  >
                    Officer ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={filter.orderBy === 'name'}
                    direction={filter.order.toLowerCase()}
                    onClick={this.onSortOrderChanged('name')}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>Is Admin</TableCell>
                <TableCell>Is Super Admin</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {officerList.map(row => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Button
                      variant='text'
                      color='primary'
                      onClick={this.onEditBtnClicked(row)}
                    >
                      {row.customOfficerId}
                    </Button>
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.isAdmin ? <Check /> : <Close />}</TableCell>
                  <TableCell>
                    {row.isSuperAdmin ? <Check /> : <Close />}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant='text'
                      className={classes.deleteBtn}
                      onClick={this.onDeleteBtnClicked(row)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justify='flex-end'>
            <Pagination
              currentPageColor='primary'
              size='large'
              limit={itemsPerPage}
              offset={(page - 1) * itemsPerPage}
              total={totalCount}
              onClick={this.onChangePage}
            />
          </Grid>
        </Paper>
        <OfficerFormDialog
          variant='create'
          open={isCreateDialogOpened}
          onClose={this.toggleCreateDialog}
        />
        <OfficerFormDialog
          variant='edit'
          open={isEditDialogOpened}
          onClose={this.onEditDialogClosed}
          officerId={R.prop('id', targetOfficer)}
        />
        {targetOfficer && (
          <OfficerDeleteDialog
            open={isDeleteDialogOpened}
            onClose={this.onDeleteDialogClosed}
            officer={targetOfficer}
          />
        )}
      </div>
    )
  }
}

export default withStyles(styles)(OfficerManagement)
