import apiClient from './modules/client'
import * as paths from './modules/paths'

export const fetchReminderList = accessToken =>
  apiClient.get(paths.reminderIndex, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const fetchSingleReminder = (accessToken, id) =>
  apiClient.get(`${paths.reminderIndex}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const createReminder = (accessToken, payload) =>
  apiClient.post(paths.reminderIndex, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const updateReminder = (accessToken, id, payload) =>
  apiClient.patch(`${paths.reminderIndex}/${id}`, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const deleteReminder = (accessToken, id) =>
  apiClient.delete(`${paths.reminderIndex}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const fetchCaseReminders = (accessToken, caseId, { limit, offset }) =>
  apiClient.get(`${paths.caseIndex}/${caseId}/reminders`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: {
      limit,
      offset
    }
  })

export const sendCaseReminder = (accessToken, caseId, reminderId) =>
  apiClient.post(
    `${paths.caseIndex}/${caseId}/reminders`,
    {
      reminderId
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )

export const massSendReminder = (accessToken, reminderId, caseIds) =>
  apiClient.post(
    `${paths.reminderIndex}/${reminderId}/send`,
    {
      caseIds
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
