import apiClient from './modules/client'
import * as paths from './modules/paths'

export const fetchPaymentBookingStatus = (accessToken, date) =>
  apiClient.get(`${paths.paymentBookingIndex}/date/${date}/status`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const fetchLatestPaymentBookingQuotas = accessToken =>
  apiClient.get(`${paths.paymentBookingIndex}/quotas`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const fetchPaymentBookings = (accessToken, date, time) =>
  apiClient.get(`${paths.paymentBookingIndex}/date/${date}/time/${time}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const updatePaymentBookingQuota = (accessToken, { date, time, quota }) =>
  apiClient.post(
    `${paths.paymentBookingIndex}/quotas`,
    {
      date,
      time,
      quota
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )

export const createPaymentBooking = (accessToken, { caseId, date, time }) =>
  apiClient.post(
    paths.paymentBookingIndex,
    {
      caseId,
      date,
      time
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )

export const deletePaymentBooking = (accessToken, id) =>
  apiClient.delete(`${paths.paymentBookingIndex}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const enablePaymentBookingDate = (accessToken, date) =>
  apiClient.post(
    `${paths.paymentBookingIndex}/quotas/date/${date}/enable`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )

export const disablePaymentBookingDate = (accessToken, date) =>
  apiClient.post(
    `${paths.paymentBookingIndex}/quotas/date/${date}/disable`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
