import * as yup from 'yup'
import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  Typography
} from '@material-ui/core'
import { Formik, Form } from 'formik'

import ReminderOptionFormikSelect from './ReminderOptionFormikSelect'
import SuccessDialog from '../SuccessDialog'

const validationSchema = yup.object().shape({
  reminderId: yup.number().required('Reminder is a required field')
})

const initialValues = {
  reminderId: ''
}

@inject('reminderStore')
@observer
class ReminderSendDialog extends React.Component {
  state = {
    success: false
  }

  toggleSuccessDialog = () => {
    this.setState(state => ({
      success: !state.success
    }))
  }

  handleSubmit = async ({ reminderId }, { setSubmitting, setErrors }) => {
    const { reminderStore, onClose, caseId } = this.props

    await reminderStore.sendCaseReminder(caseId, reminderId)
    setSubmitting(false)

    // success
    reminderStore.fetchCaseReminders()
    onClose()
    this.toggleSuccessDialog()
  }

  render () {
    const { open, onClose } = this.props
    const { success } = this.state

    return (
      <Fragment>
        <Dialog open={open} onClose={onClose} fullWidth>
          <DialogTitle>Send Reminder</DialogTitle>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
          >
            {() => (
              <Form>
                <DialogContent>
                  <Grid container spacing={24}>
                    <Grid item xs={2}>
                      <Typography variant='subtitle1'>Reminder*</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <ReminderOptionFormikSelect />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color='primary'>
                    Close
                  </Button>
                  <Button type='submit' color='primary' variant='contained'>
                    Send
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
        <SuccessDialog
          open={success}
          onClose={this.toggleSuccessDialog}
          content='Reminder has been sent successfully'
        />
      </Fragment>
    )
  }
}

ReminderSendDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ReminderSendDialog
