import * as yup from 'yup'
import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid
} from '@material-ui/core'
import { Formik, Form } from 'formik'

import SuccessDialog from '../SuccessDialog'
import CaseOptionFormikMultipleSelect from '../cases/CaseOptionFormikMultipleSelect'

const validationSchema = yup.object().shape({
  caseIds: yup
    .array()
    .of(yup.number().positive())
    .required('Please select at least 1 case.')
})

const initialValues = {
  caseIds: []
}

@inject('caseStore', 'reminderStore')
@observer
class ReminderMassSendDialog extends React.Component {
  state = {
    success: false
  }

  toggleSuccessDialog = () => {
    this.setState(state => ({
      success: !state.success
    }))
  }

  handleSubmit = async ({ caseIds }, { setSubmitting, setErrors }) => {
    const { reminderStore, onClose } = this.props
    const { singleReminder } = reminderStore

    await reminderStore.massSendReminder(singleReminder.id, caseIds)
    setSubmitting(false)

    // // success
    onClose()
    this.toggleSuccessDialog()
  }

  render () {
    const { open, onClose } = this.props
    const { success } = this.state

    return (
      <Fragment>
        <Dialog open={open} onClose={onClose} fullWidth>
          <DialogTitle>Mass Send Reminder</DialogTitle>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
          >
            {() => (
              <Form>
                <DialogContent>
                  <Grid container spacing={24}>
                    <Grid item xs={2}>
                      <Typography variant='subtitle1'>Case(s)*</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <CaseOptionFormikMultipleSelect />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color='primary'>
                    Close
                  </Button>
                  <Button type='submit' color='primary' variant='contained'>
                    Send
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
        <SuccessDialog
          open={success}
          onClose={this.toggleSuccessDialog}
          content='Reminder has been sent successfully'
        />
      </Fragment>
    )
  }
}

ReminderMassSendDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ReminderMassSendDialog
