import * as R from 'ramda'
import * as yup from 'yup'
import React from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  Typography
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { formatDate } from '../../utils/formatDate'

import OfficerOptionFormikSelect from '../officers/OfficerOptionFormikSelect'

const validationSchema = yup.object().shape({
  chineseName: yup.string(),
  englishName: yup.string().required(),
  hkIdNo: yup.string().required(),
  dob: yup
    .string()
    .matches(/\d{1,2}-\d{1,2}-\d{4}/, 'Invalid date')
    .nullable(),
  phone: yup.object().shape({
    mobile1: yup
      .string()
      .matches(/^(\+\d+)?$/, 'Invalid format')
      .required('Required'),
    mobile2: yup.string().matches(/^(\+\d+)?$/, 'Invalid format'),
    home: yup.string().matches(/^(\+\d+)?$/, 'Invalid format'),
    work: yup.string().matches(/^(\+\d+)?$/, 'Invalid format')
  }),
  email1: yup.string().email(),
  email2: yup
    .string()
    .email()
    .nullable(),
  address: yup.object().shape({
    line1: yup.string(),
    line2: yup.string(),
    line3: yup.string(),
    region: yup.string(),
    country: yup.string()
  }),
  officerId: yup.number().nullable()
})

@inject('caseStore')
@observer
class PersonalInfoEditDialog extends React.Component {
  handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const { caseStore, onClose } = this.props
    const { singleCase } = caseStore
    values = formatDate({ ...values }, true)
    await caseStore.updateCase(singleCase.id, values)

    setSubmitting(false)

    if (caseStore.error) {
      const { code, fields } = caseStore.error
      if (code === 'UNIQUE_CONSTRAINT_ERROR') {
        const errorObj = fields.reduce((acc, field) => {
          acc[field] = `The entered value is being used.`
          return acc
        }, {})
        setErrors(errorObj)
      }
    } else {
      // success
      caseStore.fetchSingleCase(singleCase.id)
      onClose()
    }
  }

  render () {
    const { open, onClose, caseStore } = this.props
    const { singleCase } = caseStore
    let initialValues = R.pick(
      [
        'chineseName',
        'englishName',
        'hkIdNo',
        'dob',
        'phone',
        'email1',
        'email2',
        'address',
        'officerId'
      ],
      singleCase
    )
    initialValues = formatDate(initialValues, false)

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>Edit Personal Information</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.handleSubmit}
          enableReinitialize
        >
          {() => (
            <Form>
              <DialogContent>
                <Field
                  label='Chinese Name'
                  name='chineseName'
                  placeholder='Chinese Name'
                  fullWidth
                  component={TextField}
                />
                <Box mb={2} />
                <Field
                  label='English Name*'
                  name='englishName'
                  placeholder='English Name'
                  fullWidth
                  component={TextField}
                />
                <Box mb={2} />
                <Grid container spacing={24}>
                  <Grid item xs={12} md={6}>
                    <Field
                      label='Date of Birth'
                      name='dob'
                      placeholder='DD-MM-YYYY'
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      label='HKID No.*'
                      name='hkIdNo'
                      placeholder='HK ID Number'
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                </Grid>
                <Box mb={3} />

                <Grid container spacing={24}>
                  <Grid item xs={2}>
                    <Box mt={2}>
                      <Typography variant='subtitle1'>Phone</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={10}>
                    <Field
                      label='Mobile (1)*'
                      name='phone[mobile1]'
                      placeholder='+852XXXXXXXX'
                      fullWidth
                      component={TextField}
                    />
                    <Box mb={2} />
                    <Field
                      label='Mobile (2)'
                      name='phone[mobile2]'
                      placeholder='+852XXXXXXXX'
                      fullWidth
                      component={TextField}
                    />
                    <Box mb={2} />
                    <Field
                      label='Home'
                      name='phone[home]'
                      placeholder='+852XXXXXXXX'
                      fullWidth
                      component={TextField}
                    />
                    <Box mb={2} />
                    <Field
                      label='Work'
                      name='phone[work]'
                      placeholder='+852XXXXXXXX'
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                </Grid>
                <Box mt={2} />

                <Field
                  label='Email (1)'
                  name='email1'
                  placeholder='Email (1)'
                  fullWidth
                  component={TextField}
                />
                <Box mb={2} />
                <Field
                  label='Email (2)'
                  name='email2'
                  placeholder='Email (2)'
                  fullWidth
                  component={TextField}
                />
                <Box mb={2} />

                <Grid container spacing={24}>
                  <Grid item xs={2}>
                    <Box mt={2}>
                      <Typography variant='subtitle1'>Address</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={10}>
                    <Field
                      label='Line 1'
                      name='address[line1]'
                      placeholder='Line 1'
                      fullWidth
                      component={TextField}
                    />
                    <Box mb={2} />
                    <Field
                      label='Line 2'
                      name='address[line2]'
                      placeholder='Line 2'
                      fullWidth
                      component={TextField}
                    />
                    <Box mb={2} />
                    <Field
                      label='Line 3'
                      name='address[line3]'
                      placeholder='Line 3'
                      fullWidth
                      component={TextField}
                    />
                    <Box mb={2} />
                    <Grid container spacing={24}>
                      <Grid item md={6}>
                        <Field
                          label='Region'
                          name='address[region]'
                          placeholder='Region'
                          fullWidth
                          component={TextField}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <Field
                          label='Country'
                          name='address[country]'
                          placeholder='Country'
                          fullWidth
                          component={TextField}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Box mt={2} />

                <Grid container spacing={24}>
                  <Grid item xs={2}>
                    <Typography variant='subtitle1'>Officer</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <OfficerOptionFormikSelect />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} color='primary'>
                  Close
                </Button>
                <Button type='submit' color='primary' variant='contained'>
                  Confirm
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    )
  }
}

PersonalInfoEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default PersonalInfoEditDialog
