import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import dayjs from 'dayjs'

import ConfirmDialog from '../ConfirmDialog'

class ContributionDeleteConfirmDialog extends React.Component {
  render () {
    const { open, onClose, onConfirm, contribution } = this.props

    return (
      <ConfirmDialog
        open={open}
        onClose={onClose}
        title='Remove this contribution?'
        content={
          <Box className='well' p={2}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <Typography>Date</Typography>
                  </td>
                  <td>
                    <Typography variant='body1'>
                      {dayjs(contribution.date).format('DD/MM/YYYY')}
                    </Typography>
                  </td>
                </tr>
                {contribution['case'] && (
                  <Fragment>
                    <tr>
                      <td>
                        <Typography>Case ID</Typography>
                      </td>
                      <td>
                        <Typography variant='body1'>
                          {contribution.case['customCaseId']}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography>English Name</Typography>
                      </td>
                      <td>
                        <Typography variant='body1'>
                          {contribution.case['englishName']}
                        </Typography>
                      </td>
                    </tr>
                  </Fragment>
                )}
                <tr>
                  <td>
                    <Typography>Amount</Typography>
                  </td>
                  <td>
                    <Typography variant='body1'>{`HKD $${
                      contribution.amount
                    }`}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>Remark</Typography>
                  </td>
                  <td>
                    <Typography variant='body1'>
                      {contribution.remark || '-'}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
        }
        onConfirm={onConfirm}
      />
    )
  }
}

ContributionDeleteConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contribution: PropTypes.object.isRequired
}

export default ContributionDeleteConfirmDialog
