import apiClient from './modules/client'
import * as paths from './modules/paths'

export const fetchDiaryList = (
  accessToken,
  { officerId, startDate, endDate }
) =>
  apiClient.get(paths.diaryIndex, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: {
      officerId,
      startDate,
      endDate
    }
  })

export const fetchOfficerDiary = (accessToken, { officerId, date }) =>
  apiClient.get(`${paths.diaryIndex}/officer/${officerId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: {
      date
    }
  })

export const disableDiaryTimeSlot = (accessToken, { officerId, date, time }) =>
  apiClient.patch(
    paths.disableDiaryTimeSlot,
    {
      officerId,
      date,
      time
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )

export const deleteDiaryBooking = (accessToken, id) =>
  apiClient.delete(`${paths.diaryBookings}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const createDiaryBooking = (accessToken, payload) =>
  apiClient.post(paths.diaryBookings, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
