import * as R from 'ramda'
import * as yup from 'yup'
import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  FormHelperText
} from '@material-ui/core'
import { Formik, Form } from 'formik'

import SuccessDialog from '../SuccessDialog'

const validationSchema = yup.object().shape({
  caseId: yup.number().required('Please enter valid case ID')
})

@inject('caseStore', 'paymentBookingStore')
@observer
class PaymentBookingDetailToolbar extends React.Component {
  state = {
    showAddBookingDialog: false,
    showSuccessDialog: false,
    customCaseId: ''
  }

  toggleAddBookingDialog = () => {
    this.setState(state => ({
      showAddBookingDialog: !state.showAddBookingDialog
    }))
  }

  toggleSuccessDialog = () => {
    this.setState(state => ({
      showSuccessDialog: !state.showSuccessDialog
    }))
  }

  onInputChanged = e => {
    const { name, value } = e.target
    this.setState(
      {
        [name]: value
      },
      this.searchCase
    )
  }

  searchCase = () => {
    const { customCaseId } = this.state
    const { caseStore } = this.props
    caseStore.searchCase(customCaseId)
  }

  handleSubmit = async ({ caseId }, { setSubmitting, setErrors }) => {
    const { paymentBookingStore, date, time } = this.props

    await paymentBookingStore.createBooking({ caseId, date, time })

    setSubmitting(false)

    if (paymentBookingStore.error) {
      const { message } = paymentBookingStore.error
      setErrors({
        caseId: message
      })
    } else {
      // success
      this.setState({
        customCaseId: ''
      })

      paymentBookingStore.fetchBookings(date, time)
      this.toggleAddBookingDialog()
      this.toggleSuccessDialog()
    }
  }

  render () {
    const { showAddBookingDialog, showSuccessDialog, customCaseId } = this.state
    const { disabled, caseStore } = this.props
    const { singleCase } = caseStore

    const initialValues = {
      caseId: R.propOr('', 'id', singleCase)
    }

    return (
      <Fragment>
        <Button
          variant='contained'
          color='secondary'
          onClick={this.toggleAddBookingDialog}
          disabled={disabled}
        >
          Add booking
        </Button>
        <Dialog
          open={showAddBookingDialog}
          onClose={this.toggleAddBookingDialog}
          onEnter={this.searchCase}
          fullWidth
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
            enableReinitialize
          >
            {({ errors }) => (
              <Form>
                <DialogContent>
                  <Box py={1}>
                    <Typography variant='h6'>Add Booking</Typography>
                    <Box mb={2} />
                    <TextField
                      name='customCaseId'
                      label='Case ID'
                      placeholder='Case ID'
                      value={customCaseId}
                      onChange={this.onInputChanged}
                      fullWidth
                      error={!!errors.caseId}
                    />
                    {!!errors.caseId && (
                      <FormHelperText error>{errors.caseId}</FormHelperText>
                    )}
                    <Box mb={2} />
                    {singleCase && (
                      <Box className='well' p={2}>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <Typography>English Name</Typography>
                              </td>
                              <td>
                                <Typography variant='body1'>
                                  {singleCase.englishName}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Typography>HCB no.</Typography>
                              </td>
                              <td>
                                <Typography variant='body1'>
                                  {singleCase.hcbNo}
                                </Typography>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Box>
                    )}
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={this.toggleAddBookingDialog}
                  >
                    Close
                  </Button>
                  <Button type='submit' variant='contained' color='primary'>
                    Confirm
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
        <SuccessDialog
          open={showSuccessDialog}
          content='The booking has been added successfully'
          onClose={this.toggleSuccessDialog}
        />
      </Fragment>
    )
  }
}

PaymentBookingDetailToolbar.propTypes = {
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
}

export default PaymentBookingDetailToolbar
