import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Box, Grid, CircularProgress } from '@material-ui/core'

const Loading = ({ className, color, fullHeight, ...restProps }) => (
  <Box {...restProps}>
    <Grid
      className={classnames(className, {
        'full-height': fullHeight
      })}
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justify='center'
    >
      <CircularProgress color={color} />
    </Grid>
  </Box>
)

Loading.propTypes = {
  color: PropTypes.string,
  fullHeight: PropTypes.bool
}

Loading.defaultProps = {
  color: 'primary',
  fullHeight: false
}

export default Loading
