import * as yup from 'yup'
import * as R from 'ramda'
import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { SimpleFileUpload } from 'formik-material-ui'

import SuccessDialog from '../SuccessDialog'

const validationSchema = yup.object().shape({
  file: yup.mixed().required()
})

const initialValues = {
  file: ''
}

@inject('contributionStore')
@observer
class ContributionUploadCsvDialog extends React.Component {
  state = {
    success: false,
    result: {}
  }

  toggleSuccessDialog = () => {
    this.setState(state => ({
      success: !state.success
    }))
  }

  handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const { file } = values
    const { contributionStore, onClose } = this.props

    const result = await contributionStore.importFromCsv(file)
    this.setState({
      result
    })

    setSubmitting(false)
    // success
    contributionStore.fetchContributionList()
    onClose()
    this.toggleSuccessDialog()
  }

  render () {
    const { open, onClose } = this.props
    const { success, result } = this.state

    return (
      <Fragment>
        <Dialog open={open} onClose={onClose} fullWidth>
          <DialogTitle>Upload CSV</DialogTitle>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
          >
            {() => (
              <Form>
                <DialogContent>
                  <Field
                    type='file'
                    label='CSV file'
                    name='file'
                    placeholder='CSV file'
                    fullWidth
                    component={SimpleFileUpload}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color='primary'>
                    Close
                  </Button>
                  <Button type='submit' color='primary' variant='contained'>
                    Confirm
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
        <SuccessDialog
          open={success}
          content={`${R.propOr(
            0,
            'success',
            result
          )} contributions have been added successfully`}
          onClose={this.toggleSuccessDialog}
        />
      </Fragment>
    )
  }
}

ContributionUploadCsvDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ContributionUploadCsvDialog
