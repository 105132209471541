import * as R from 'ramda'
import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Box, Button, Typography, withStyles } from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'

import ConfirmDialog from '../ConfirmDialog'
import FormRejectDialog from './FormRejectDialog'

const styles = theme => ({
  approveBtn: {
    color: green[500],
    marginRight: theme.spacing(2)
  },
  rejectBtn: {
    color: red[500]
  }
})

@inject('formStore')
@observer
class FormDetailToolbar extends React.Component {
  state = {
    showApproveDialog: false,
    showRejectDialog: false
  }

  onConfirmApprove = async () => {
    const { formStore } = this.props
    const { singleForm } = formStore
    await formStore.approveForm(singleForm.id)

    await formStore.fetchSingleForm(singleForm.id)
    this.toggleApproveDialog()
  }

  toggleApproveDialog = () => {
    this.setState(state => ({
      showApproveDialog: !state.showApproveDialog
    }))
  }

  toggleRejectDialog = () => {
    this.setState(state => ({
      showRejectDialog: !state.showRejectDialog
    }))
  }

  render () {
    const { showApproveDialog, showRejectDialog } = this.state
    const { formStore, classes } = this.props
    const { singleForm } = formStore

    if (R.prop('status', singleForm) === 'PENDING') {
      // only show the toolbar when status is `PENDING`
      return (
        <Fragment>
          <Box>
            <Button
              className={classes.approveBtn}
              variant='contained'
              color='secondary'
              onClick={this.toggleApproveDialog}
            >
              Approve
            </Button>
            <Button
              className={classes.rejectBtn}
              variant='contained'
              color='secondary'
              onClick={this.toggleRejectDialog}
            >
              Reject
            </Button>
          </Box>
          <ConfirmDialog
            open={showApproveDialog}
            onClose={this.toggleApproveDialog}
            title='Approve'
            content={<Typography>Approve this form?</Typography>}
            onConfirm={this.onConfirmApprove}
          />
          <FormRejectDialog
            open={showRejectDialog}
            onClose={this.toggleRejectDialog}
          />
        </Fragment>
      )
    } else {
      return null
    }
  }
}

export default withStyles(styles)(FormDetailToolbar)
