import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Button } from '@material-ui/core'

import ContributionDeleteConfirmDialog from './ContributionDeleteConfirmDialog'
import SuccessDialog from '../SuccessDialog'
import history from '../../history'

@inject('contributionStore')
@observer
class ContributionDetailToolbar extends React.Component {
  state = {
    showConfirmDeleteDialog: false,
    showSuccessDeleteDialog: false
  }

  onConfirmDelete = async () => {
    const { contributionStore } = this.props
    const { singleContribution: contribution } = contributionStore
    await contributionStore.deleteContribution(contribution.id)

    this.toggleConfirmDeleteDialog()
    this.openSuccessDeleteDialog()
  }

  toggleConfirmDeleteDialog = () => {
    this.setState(state => ({
      showConfirmDeleteDialog: !state.showConfirmDeleteDialog
    }))
  }

  openSuccessDeleteDialog = () => {
    this.setState({
      showSuccessDeleteDialog: true
    })
  }

  handleDismissSuccessDeleteDialog = () => {
    history.push('/contributions')
  }

  render () {
    const { showConfirmDeleteDialog, showSuccessDeleteDialog } = this.state
    const { contributionStore } = this.props
    const { singleContribution: contribution } = contributionStore

    return (
      <Fragment>
        <Button
          variant='contained'
          color='secondary'
          onClick={this.toggleConfirmDeleteDialog}
        >
          Remove contribution
        </Button>
        <ContributionDeleteConfirmDialog
          open={showConfirmDeleteDialog}
          onClose={this.toggleConfirmDeleteDialog}
          onConfirm={this.onConfirmDelete}
          contribution={contribution}
        />
        <SuccessDialog
          open={showSuccessDeleteDialog}
          content='The contribution has been removed successfully'
          onClose={this.handleDismissSuccessDeleteDialog}
        />
      </Fragment>
    )
  }
}

export default ContributionDetailToolbar
