import React, { Fragment } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography
} from '@material-ui/core'
import dayjs from 'dayjs'

const incomeDetailTranslations = {
  WILL_RECEIVE_EXTRA_INCOME_THIS_YEAR: '本年度將有額外收入',
  WONT_RECEIVE_EXTRA_INCOME_THIS_YEAR: '本年度將沒有額外收入',
  HAVENT_RECEIVED_ANY_NOTICE_ABOUT_EXTRA_INCOME_THIS_YEAR:
    '未收到公司通知發放額外收入',
  DONT_KNOW_ANYTHING_ABOUT_EXTRA_INCOME: '不知道有沒有額外收入',
  UNEMPLOYED: '失業'
}

class NoticeOfExtraIncome extends React.Component {
  renderDetail = () => {
    const { data } = this.props

    if (data.extraIncomeDetail === 'WILL_RECEIVE_EXTRA_INCOME_THIS_YEAR') {
      return (
        <Fragment>
          <TableRow>
            <TableCell>日期已確定</TableCell>
            <TableCell>
              {data.dateConfirmed
                ? dayjs(data.date).format('DD/MM/YYYY')
                : '未確定'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>金額已確定</TableCell>
            <TableCell>
              {data.amountConfirmed ? '$' + data.amount.toFixed(2) : '未確定'}
            </TableCell>
          </TableRow>
        </Fragment>
      )
    }

    return (
      <TableRow>
        <TableCell>日期</TableCell>
        <TableCell>
          {data['date']}
          {data.extraIncomeDetail === 'UNEMPLOYED' && ' 直至現在沒有工作'}
        </TableCell>
      </TableRow>
    )
  }

  render () {
    const { data } = this.props

    return (
      <div>
        <Box pl={2}>
          <Typography variant='subtitle1'>額外收入通知書</Typography>
        </Box>

        <Table>
          <TableBody>
            <TableRow>
              <TableCell>額外收入詳情</TableCell>
              <TableCell>
                {incomeDetailTranslations[data.extraIncomeDetail]}
              </TableCell>
            </TableRow>
            {this.renderDetail()}
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default NoticeOfExtraIncome
