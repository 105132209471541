import { observable, action, runInAction, reaction } from 'mobx'

import * as api from '../services/api'
import { itemsPerPage } from '../constants/pagination'

class OfficerStore {
  @observable singleOfficer = null
  @observable officerList = []
  @observable officerOptions = []
  @observable totalCount = 0
  @observable page = 1
  @observable filter = {
    orderBy: 'customOfficerId',
    order: 'DESC'
  }
  @observable error = null

  constructor (rootStore) {
    this.rootStore = rootStore
  }

  @action
  setPage = page => {
    this.page = page
  }

  @action
  updateFilter = filter => {
    this.filter = {
      ...this.filter,
      ...filter
    }
    // reset page after updating filter
    this.page = 1
  }

  fetchOfficerOptions = async () => {
    const { accessToken } = this.rootStore.authStore
    try {
      const result = await api.fetchOfficerOptions(accessToken)
      runInAction(() => {
        this.officerOptions = result
      })
    } catch (error) {
      console.log(error)
    }
  }

  fetchOfficerList = async () => {
    const { accessToken } = this.rootStore.authStore
    const { orderBy, order } = this.filter
    try {
      const result = await api.fetchOfficerList(accessToken, {
        limit: itemsPerPage,
        offset: (this.page - 1) * itemsPerPage,
        orderBy,
        order
      })
      runInAction(() => {
        this.officerList = result.rows
        this.totalCount = result.count
      })
    } catch (error) {
      console.log(error)
    }
  }

  fetchSingleOfficer = async id => {
    const { accessToken } = this.rootStore.authStore
    try {
      const result = await api.fetchSingleOfficer(accessToken, id)
      runInAction(() => {
        this.singleOfficer = result
      })
    } catch (error) {
      console.log(error)
    }
  }

  @action
  listenAndFetchOfficerList = () => {
    this.page = 1
    return reaction(
      () => ({
        page: this.page,
        filter: this.filter
      }),
      () => this.fetchOfficerList()
    )
  }

  @action
  clearError = () => {
    this.error = null
  }

  createOfficer = async officer => {
    this.clearError()
    const { accessToken } = this.rootStore.authStore
    try {
      await api.createOfficer(accessToken, officer)
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.error = error
      })
    }
  }

  updateOfficer = async (id, officer) => {
    this.clearError()
    const { accessToken } = this.rootStore.authStore
    try {
      await api.updateOfficer(accessToken, id, officer)
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.error = error
      })
    }
  }

  deleteOfficer = async id => {
    this.clearError()
    const { accessToken } = this.rootStore.authStore
    try {
      await api.deleteOfficer(accessToken, id)
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.error = error
      })
    }
  }
}

export default OfficerStore
