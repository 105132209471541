import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Box,
  Button,
  Paper,
  Table,
  TableHead,
  TableBody,
  Typography,
  TableRow,
  TableCell,
  Grid
} from '@material-ui/core'
import Pagination from 'material-ui-flat-pagination'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

import Loading from '../components/layouts/Loading'
import { itemsPerPage } from '../constants/pagination'
import ReminderSendDialog from '../components/reminders/ReminderSendDialog'
import { parseReminder, nl2br } from '../utils/reminder'

@inject('caseStore', 'reminderStore')
@observer
class CaseReminderHistory extends React.Component {
  state = {
    isDialogOpened: false
  }

  componentDidMount () {
    const { caseStore, reminderStore, match } = this.props
    const { caseId } = match.params
    caseStore.fetchSingleCase(caseId)
    reminderStore.setTargetCaseId(caseId)
    reminderStore.fetchCaseReminders()
    this.cancelListen = reminderStore.listenAndFetchCaseReminders()
  }

  componentWillUnmount () {
    if (this.cancelListen) {
      this.cancelListen()
    }
  }

  onChangePage = (e, offset) => {
    const { reminderStore } = this.props
    reminderStore.setPage(offset / itemsPerPage + 1)
  }

  toggleDialog = () => {
    this.setState(state => ({
      isDialogOpened: !state.isDialogOpened
    }))
  }

  render () {
    const { isDialogOpened } = this.state
    const { caseStore, reminderStore } = this.props
    const { singleCase } = caseStore
    const { caseReminders, page, caseReminderCount } = reminderStore

    if (!singleCase) {
      return <Loading fullHeight />
    }

    return (
      <div>
        <Box mb={2}>
          <Button color='primary' component={Link} to='/case-management'>
            Case Management
          </Button>
          /{' '}
          <Button
            color='primary'
            component={Link}
            to={`/case-detail/${singleCase.id}`}
          >
            {singleCase.customCaseId}
          </Button>
          / <Typography display='inline'>Reminder History</Typography>
        </Box>
        <Paper>
          <Box pl={3} pr={2} py={2}>
            <Grid container justify='space-between'>
              <Typography variant='h6' component='h2'>
                Reminder History
              </Typography>
              <Button
                variant='contained'
                color='primary'
                onClick={this.toggleDialog}
              >
                Send reminder
              </Button>
            </Grid>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Reminder name</TableCell>
                <TableCell>Content(TC)</TableCell>
                <TableCell>Content(EN)</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {caseReminders.map(message => {
                const reminder = parseReminder(message.reminder, message.params)
                return (
                  <TableRow key={message.id}>
                    <TableCell>
                      {dayjs(message.createdAt).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      <Button
                        color='primary'
                        component={Link}
                        to={`/reminder-detail/${reminder.id}`}
                      >
                        {reminder['name']}
                      </Button>
                    </TableCell>
                    <TableCell>{nl2br(reminder['contentTc'])}</TableCell>
                    <TableCell>{nl2br(reminder['contentEn'])}</TableCell>
                    <TableCell>
                      {message.read
                        ? `Read ${dayjs(message.readAt).format(
                            'DD/MM/YYYY h:mma'
                          )}`
                        : 'Unread'}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <Grid container justify='flex-end'>
            <Pagination
              currentPageColor='primary'
              size='large'
              limit={itemsPerPage}
              offset={(page - 1) * itemsPerPage}
              total={caseReminderCount}
              onClick={this.onChangePage}
            />
          </Grid>
        </Paper>
        <ReminderSendDialog
          caseId={singleCase.id}
          open={isDialogOpened}
          onClose={this.toggleDialog}
        />
      </div>
    )
  }
}

export default CaseReminderHistory
