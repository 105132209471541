import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Box,
  Grid,
  Button,
  Paper,
  Table,
  TableHead,
  TableBody,
  TextField,
  TableRow,
  TableCell
} from '@material-ui/core'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'

import ConfirmDialog from '../components/ConfirmDialog'

@inject('paymentBookingStore')
@observer
class PaymentBookingIndex extends React.Component {
  state = {
    showDisableDialog: false
  }

  componentDidMount () {
    const { paymentBookingStore } = this.props
    paymentBookingStore.fetchBookingStatus()
    this.cancelListen = paymentBookingStore.listenAndFetchBookingStatus()
  }

  componentWillUnmount () {
    if (this.cancelListen) {
      this.cancelListen()
    }
  }

  onDateFilterUpdated = e => {
    const { name, value } = e.target
    const { paymentBookingStore } = this.props
    if (value) {
      paymentBookingStore.updateFilter({
        [name]: value
      })
    }
  }

  onConfirmDisable = async () => {
    const { paymentBookingStore } = this.props
    const { bookingStatus } = paymentBookingStore
    if (bookingStatus.disabled) {
      await paymentBookingStore.enableDate()
    } else {
      await paymentBookingStore.disableDate()
    }
    this.toggleDisableDialog()
    paymentBookingStore.fetchBookingStatus()
  }

  toggleDisableDialog = () => {
    this.setState(state => ({
      showDisableDialog: !state.showDisableDialog
    }))
  }

  render () {
    const { showDisableDialog } = this.state
    const { paymentBookingStore } = this.props
    const { bookingStatus, filter } = paymentBookingStore

    return (
      <Paper>
        <Box p={3}>
          <Grid container justify='space-between'>
            <TextField
              type='date'
              name='date'
              value={filter.date}
              onChange={this.onDateFilterUpdated}
            />
            <Button color='primary' onClick={this.toggleDisableDialog}>
              {bookingStatus.disabled ? 'Enable' : 'Disable'}
            </Button>
          </Grid>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Time</TableCell>
              <TableCell>Number of booking</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookingStatus.slots.map(row => (
              <TableRow key={row.time}>
                <TableCell>
                  {dayjs(`2000-01-01 ${row.time}`).format('h:mma')}
                </TableCell>
                <TableCell>
                  <Button
                    color='primary'
                    component={Link}
                    to={`/payment-booking-detail?date=${filter.date}&time=${
                      row.time
                    }`}
                  >
                    {row.booked}
                  </Button>
                  / {row.quota}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <ConfirmDialog
          title={`${bookingStatus.disabled ? 'Enable' : 'Disable'} this date?`}
          open={showDisableDialog}
          onClose={this.toggleDisableDialog}
          onConfirm={this.onConfirmDisable}
        />
      </Paper>
    )
  }
}

export default PaymentBookingIndex
