import * as R from 'ramda'
import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Box,
  AppBar,
  Toolbar,
  Button,
  Typography,
  withStyles
} from '@material-ui/core'
import { Link } from 'react-router-dom'

import logo from '../../images/hkdm_logo.png'

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  logo: {
    width: 44
  }
})

@inject('authStore')
@observer
class Header extends React.Component {
  getRoleName () {
    const { authStore } = this.props
    const { profile } = authStore

    if (!profile) {
      return ''
    }

    if (profile.isSuperAdmin) {
      return 'Super Admin'
    }
    if (profile.isAdmin) {
      return 'Admin'
    }
    return 'Officer'
  }

  render () {
    const { authStore, classes } = this.props
    const { profile } = authStore

    return (
      <AppBar position='fixed' color='secondary' className={classes.appBar}>
        <Toolbar>
          <Box flexGrow={1}>
            <Link to='/'>
              <img className={classes.logo} src={logo} />
            </Link>
          </Box>

          <Link to='/change-pw'>
            <Typography variant='subtitle1' color='textPrimary'>
              {this.getRoleName() + ' ' + R.propOr('', 'name', profile)}
            </Typography>
          </Link>
          <Button color='primary' onClick={authStore.logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    )
  }
}

export default withStyles(styles)(Header)
