import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Box,
  Grid,
  Button,
  Paper,
  Table,
  TableBody,
  Typography,
  TableRow,
  TableCell,
  IconButton
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { Link } from 'react-router-dom'

import Loading from '../components/layouts/Loading'
import ContributionDetailToolbar from '../components/contributions/ContributionDetailToolbar'
import ContributionEditDialog from '../components/contributions/ContributionEditDialog'

@inject('contributionStore')
@observer
class ContributionDetail extends React.Component {
  state = {
    showEditAmouontDialog: false,
    showEditRemarkDialog: false
  }

  componentDidMount () {
    const { contributionStore, match } = this.props
    const { contributionId } = match.params
    contributionStore.fetchSingleContribution(contributionId)
  }

  toggleEditAmountDialog = () => {
    this.setState(state => ({
      showEditAmouontDialog: !state.showEditAmouontDialog
    }))
  }

  toggleEditRemarkDialog = () => {
    this.setState(state => ({
      showEditRemarkDialog: !state.showEditRemarkDialog
    }))
  }

  render () {
    const { contributionStore } = this.props
    const { singleContribution: contribution } = contributionStore
    const { showEditAmouontDialog, showEditRemarkDialog } = this.state

    if (!contribution) {
      return <Loading fullHeight />
    }

    return (
      <div>
        <Box mb={2}>
          <Button color='primary' component={Link} to='/contributions'>
            Contribution History
          </Button>
          / <Typography display='inline'>Contribution Detail</Typography>
        </Box>
        <Box mb={2}>
          <ContributionDetailToolbar />
        </Box>
        <Paper>
          <Box pl={3} pr={1} py={2}>
            <Grid container justify='space-between' alignItems='center'>
              <Typography variant='h6' component='h2'>
                Contribution Detail
              </Typography>
            </Grid>
          </Box>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>{contribution.date}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Case ID</TableCell>
                <TableCell>
                  <Button
                    color='primary'
                    component={Link}
                    to={`/case-detail/${contribution.case['id']}`}
                  >
                    {contribution.case['customCaseId']}
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{contribution.case['englishName']}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Box mb={2} />
        <Paper>
          <Box pl={3} pr={1} py={2}>
            <Grid container justify='space-between' alignItems='center'>
              <Typography variant='h6' component='h2'>
                Amount
              </Typography>
              <IconButton onClick={this.toggleEditAmountDialog}>
                <Edit />
              </IconButton>
            </Grid>
            <Typography variant='body1'>HKD ${contribution.amount}</Typography>
          </Box>
        </Paper>
        <Box mb={2} />
        <Paper>
          <Box pl={3} pr={1} py={2}>
            <Grid container justify='space-between' alignItems='center'>
              <Typography variant='h6' component='h2'>
                Remark
              </Typography>
              <IconButton onClick={this.toggleEditRemarkDialog}>
                <Edit />
              </IconButton>
            </Grid>
            <Typography variant='body1'>
              {contribution.remark || '-'}
            </Typography>
          </Box>
        </Paper>
        <ContributionEditDialog
          open={showEditAmouontDialog}
          onClose={this.toggleEditAmountDialog}
          field='amount'
        />
        <ContributionEditDialog
          open={showEditRemarkDialog}
          onClose={this.toggleEditRemarkDialog}
          field='remark'
        />
      </div>
    )
  }
}

export default ContributionDetail
