import * as yup from 'yup'
import * as R from 'ramda'
import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'

import SuccessDialog from '../SuccessDialog'
import { MAX_BOOKINGS_PER_SLOT } from '../../constants/paymentBooking'

const validationSchema = yup.object().shape({
  quota: yup
    .number()
    .max(MAX_BOOKINGS_PER_SLOT)
    .required()
})

@inject('paymentBookingStore')
@observer
class PaymentBookingEditQuotaDialog extends React.Component {
  state = {
    success: false
  }

  toggleSuccessDialog = () => {
    this.setState(state => ({
      success: !state.success
    }))
  }

  handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const { paymentBookingStore, onClose, date, time } = this.props

    await paymentBookingStore.updateBookingQuota({
      quota: values.quota,
      date,
      time
    })

    setSubmitting(false)

    if (paymentBookingStore.error) {
      const { message } = paymentBookingStore.error
      setErrors({
        quota: message
      })
    } else {
      // success
      paymentBookingStore.fetchBookingStatus()
      onClose()
      this.toggleSuccessDialog()
    }
  }

  render () {
    const { open, onClose, slot } = this.props
    const { success } = this.state

    const initialValues = {
      quota: R.propOr(0, 'quota', slot)
    }

    return (
      <Fragment>
        <Dialog open={open} onClose={onClose} fullWidth>
          <DialogTitle>Edit Quota</DialogTitle>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
            enableReinitialize
          >
            {() => (
              <Form>
                <DialogContent>
                  <Field
                    type='number'
                    label='Quota'
                    name='quota'
                    placeholder='Quota'
                    fullWidth
                    component={TextField}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color='primary'>
                    Close
                  </Button>
                  <Button type='submit' color='primary' variant='contained'>
                    Update
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
        <SuccessDialog
          open={success}
          content='The quota has been updated successfully'
          onClose={this.toggleSuccessDialog}
        />
      </Fragment>
    )
  }
}

PaymentBookingEditQuotaDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  slot: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired
}

export default PaymentBookingEditQuotaDialog
