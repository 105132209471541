import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Grid,
  Typography,
  Card,
  CardContent
} from '@material-ui/core'
import dayjs from 'dayjs'

import ConfirmDialog from '../ConfirmDialog'
import DiaryBookingCreateDialog from './DiaryBookingCreateDialog'

@inject('diaryStore')
@observer
class BookingCard extends React.Component {
  state = {
    showEnableDialog: false,
    showDisableDialog: false,
    showCancelDialog: false,
    showCreateDialog: false
  }

  getBookingDetail = booking => {
    return (
      <Box className='well' p={2}>
        <table>
          <tbody>
            <tr>
              <td>
                <Typography>HCB No.</Typography>
              </td>
              <td>
                <Typography variant='body1'>{booking.hcbNo}</Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography>English Name</Typography>
              </td>
              <td>
                <Typography variant='body1'>{booking.name}</Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography>Mobile</Typography>
              </td>
              <td>
                <Typography variant='body1'>{booking.phone}</Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography>Email</Typography>
              </td>
              <td>
                <Typography variant='body1'>{booking.email}</Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    )
  }

  getCardTitle = slot => {
    let button = null

    if (!slot.booking) {
      button = (
        <Button color='primary' onClick={this.toggleDisableDialog}>
          Disable
        </Button>
      )
    } else {
      if (slot.booking.disabled) {
        button = (
          <Button color='primary' onClick={this.toggleEnableDialog}>
            Enable
          </Button>
        )
      } else {
        button = (
          <Button color='primary' onClick={this.toggleCancelDialog}>
            Cancel booking
          </Button>
        )
      }
    }

    return (
      <Grid container justify='space-between' alignItems='center'>
        <Typography variant='h5'>
          {dayjs(`2000-01-01 ${slot.time}`).format('h:mma')}
        </Typography>
        {button}
      </Grid>
    )
  }

  getCardContent = slot => {
    const { booking } = slot

    if (!booking) {
      return (
        <Fragment>
          <Typography>
            No appointment made
            <Button color='primary' onClick={this.toggleCreateDialog}>
              Add appointment
            </Button>
          </Typography>
        </Fragment>
      )
    }

    if (booking.disabled) {
      return <Typography>This timeslot has been disabled</Typography>
    }

    return this.getBookingDetail(slot.booking)
  }

  toggleEnableDialog = () => {
    this.setState(state => ({
      showEnableDialog: !state.showEnableDialog
    }))
  }

  toggleDisableDialog = () => {
    this.setState(state => ({
      showDisableDialog: !state.showDisableDialog
    }))
  }

  toggleCancelDialog = () => {
    this.setState(state => ({
      showCancelDialog: !state.showCancelDialog
    }))
  }

  toggleCreateDialog = () => {
    this.setState(state => ({
      showCreateDialog: !state.showCreateDialog
    }))
  }

  onConfirmEnable = async () => {
    const { slot, diaryStore } = this.props
    await diaryStore.deleteDiaryBooking(slot.booking.id)
    diaryStore.fetchSingleDiary()
    this.toggleEnableDialog()
  }

  onConfirmDisable = async () => {
    const { slot, diaryStore } = this.props
    await diaryStore.disableTimeSlot(slot.time)
    diaryStore.fetchSingleDiary()
    this.toggleDisableDialog()
  }

  onConfirmCancel = async () => {
    const { slot, diaryStore } = this.props
    await diaryStore.deleteDiaryBooking(slot.booking.id)
    diaryStore.fetchSingleDiary()
    this.toggleCancelDialog()
  }

  render () {
    const { slot, diaryStore } = this.props
    const { filter } = diaryStore
    const {
      showCancelDialog,
      showEnableDialog,
      showDisableDialog,
      showCreateDialog
    } = this.state

    return (
      <Box mb={2}>
        <Card>
          <CardContent>
            {this.getCardTitle(slot)}
            {this.getCardContent(slot)}
          </CardContent>
        </Card>
        {!slot.booking && (
          <Fragment>
            <ConfirmDialog
              open={showDisableDialog}
              title='Disable this time slot?'
              onClose={this.toggleDisableDialog}
              onConfirm={this.onConfirmDisable}
            />
            <DiaryBookingCreateDialog
              open={showCreateDialog}
              onClose={this.toggleCreateDialog}
              officerId={filter.officerId}
              date={filter.date}
              time={slot.time}
            />
          </Fragment>
        )}
        {slot.booking && slot.booking.disabled && (
          <ConfirmDialog
            open={showEnableDialog}
            title='Enable this time slot?'
            onClose={this.toggleEnableDialog}
            onConfirm={this.onConfirmEnable}
          />
        )}
        {slot.booking && !slot.booking.disabled && (
          <ConfirmDialog
            open={showCancelDialog}
            title='Cancel this booking?'
            content={this.getBookingDetail(slot.booking)}
            onClose={this.toggleCancelDialog}
            onConfirm={this.onConfirmCancel}
          />
        )}
      </Box>
    )
  }
}

BookingCard.propTypes = {
  slot: PropTypes.object.isRequired
}

export default BookingCard
