import * as R from 'ramda'
import jwt from 'jsonwebtoken'
import ms from 'ms'
import { observable, action, computed, runInAction, reaction } from 'mobx'

import * as api from '../services/api'
import { getTokens, storeTokens, removeTokens } from '../utils/auth'
import history from '../history'

class AuthStore {
  @observable isReady = false
  @observable accessToken = null
  @observable refreshToken = null
  @observable error = null

  constructor (rootStore) {
    this.rootStore = rootStore

    const { accessToken, refreshToken } = getTokens()
    this.accessToken = accessToken
    this.refreshToken = refreshToken

    this.setupTokenTimeoutCheck()

    // watch for the change of access token,
    // when it happens, store to local storage
    reaction(
      () => this.accessToken,
      () => {
        if (this.accessToken && this.refreshToken) {
          storeTokens({
            accessToken: this.accessToken,
            refreshToken: this.refreshToken
          })
        } else {
          removeTokens()
        }
      }
    )
  }

  @computed get isAuthenticated () {
    return !!this.accessToken
  }

  @computed get profile () {
    if (this.accessToken) {
      return jwt.decode(this.accessToken)
    } else {
      return null
    }
  }

  @computed get isAdmin () {
    return R.propOr(false, 'isAdmin', this.profile)
  }

  @computed get isSuperAdmin () {
    return R.propOr(false, 'isSuperAdmin', this.profile)
  }

  login = async (email, password) => {
    this.clearError()
    try {
      const { accessToken, refreshToken } = await api.login(email, password)
      runInAction(() => {
        this.accessToken = accessToken
        this.refreshToken = refreshToken
      })
      // login success
      history.push('/')
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.error = error
      })
    }
  }

  forgotPassword = async email => {
    this.clearError()
    try {
      await api.forgotPassword(email)
      // forgot password success
      history.push('/forgot-pw-success')
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.error = error
      })
    }
  }

  resetPassword = async (token, newPassword) => {
    this.clearError()
    try {
      await api.resetPassword(token, newPassword)
      // reset password success
      history.push('/reset-pw-success')
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.error = error
      })
    }
  }

  changePassword = async (password, newPassword) => {
    this.clearError()
    try {
      await api.changePassword(this.accessToken, {
        password,
        newPassword
      })
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.error = error
      })
    }
  }

  refresh = async () => {
    try {
      const { accessToken, refreshToken } = await api.refresh(this.refreshToken)
      runInAction(() => {
        this.accessToken = accessToken
        this.refreshToken = refreshToken
      })
    } catch (error) {
      // background requests no need to set error
      console.log(error)
    }
  }

  @action
  logout = () => {
    this.accessToken = null
    this.refreshToken = null
    removeTokens()
    history.push('/login')
  }

  @action
  clearError = () => {
    this.error = null
  }

  refreshIfExpireSoon = async () => {
    if (this.isAuthenticated) {
      const { exp } = jwt.decode(this.accessToken)
      const now = new Date()
      const fiveMinutesFromNow = new Date(now.getTime() + ms('5mins'))
      if (new Date(exp * 1000) <= fiveMinutesFromNow) {
        await this.refresh()
      }
    }
  }

  setupTokenTimeoutCheck = async () => {
    // invoke once immediately
    await this.refreshIfExpireSoon()
    // check access token every 5 minutes,
    // then refresh it if it expires in 5 minutes
    setInterval(this.refreshIfExpireSoon, ms('5mins'))

    runInAction(() => {
      this.isReady = true
    })
  }
}

export default AuthStore
