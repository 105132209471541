import dayjs from 'dayjs'

export const formatDate = (values, isStandard) => {
  const dates = ['boDate', 'cmDate', 'dischargeDate', 'annulledDate', 'dob']
  for (let key in values) {
    const isDate = dates.includes(key)
    if (!isDate || !values[key]) {
      continue
    }

    if (isStandard) {
      const date = values[key].split('-')
      const day = date[0]
      date[0] = date[1]
      date[1] = day
      date.join('-')
      values[key] = dayjs(date).format('YYYY-MM-DD')
    }

    if (!isStandard) {
      values[key] = dayjs(values[key]).format('DD-MM-YYYY')
    }
  }

  return values
}
