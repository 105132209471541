import * as R from 'ramda'
import React from 'react'
import {
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography
} from '@material-ui/core'

import FieldRows from './FieldRows'

const generalFields = [
  {
    name: '離港日期',
    property: 'departureDate'
  },
  {
    name: '回港日期',
    property: 'arrivalDate'
  },
  {
    name: '目的地',
    property: 'destination'
  }
]

const reasonTranslations = {
  VISITING_RELATIVES: '探親',
  TOURISM: '旅遊',
  WORK: '出差',
  OTHER: '其他'
}

const payerFields = [
  {
    name: '姓名',
    property: 'name'
  },
  {
    name: '關係',
    property: 'relationship'
  }
]

const addressFields = [
  {
    name: '地址行1',
    property: 'line1'
  },
  {
    name: '地址行2',
    property: 'line2'
  },
  {
    name: '地址行3',
    property: 'line3'
  },
  {
    name: '地區',
    property: 'region'
  },
  {
    name: '國家',
    property: 'country'
  }
]

const contactFields = [
  {
    name: 'WhatsApp',
    property: 'whatsapp'
  },
  {
    name: '微信ID',
    property: 'wechat'
  },
  {
    name: '電郵',
    property: 'email'
  }
]

class NoticeOfDeparture extends React.Component {
  render () {
    const { data } = this.props

    return (
      <div>
        <Box pl={2}>
          <Typography variant='subtitle1'>離港通知書</Typography>
        </Box>

        <Table>
          <TableBody>
            <FieldRows data={data} fields={generalFields} />
            <TableRow>
              <TableCell>離港原因</TableCell>
              <TableCell>{reasonTranslations[data.reason]}</TableCell>
            </TableRow>
            {data.reason === 'OTHER' && (
              <TableRow>
                <TableCell>其他原因</TableCell>
                <TableCell>{data.reasonOther}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <Box mt={2} pl={2}>
          <Typography variant='subtitle2'>支付者詳情</Typography>
        </Box>

        <Table>
          <TableBody>
            <FieldRows data={R.prop('payer', data)} fields={payerFields} />
          </TableBody>
        </Table>

        <Box mt={2} pl={2}>
          <Typography variant='subtitle2'>聯絡方式 (外地)</Typography>
        </Box>

        <Table>
          <TableBody>
            <FieldRows
              data={R.prop('overseasContacts', data)}
              fields={[
                {
                  name: '電話',
                  property: 'phone'
                }
              ]}
            />
            <FieldRows
              data={R.path(['overseasContacts', 'address'], data)}
              fields={addressFields}
            />
            <FieldRows
              data={R.prop('overseasContacts', data)}
              fields={contactFields}
            />
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default NoticeOfDeparture
