// public routes
import Login from './pages/Login'
import ForgotPw from './pages/ForgotPw'
import ForgotPwSuccess from './pages/ForgotPwSuccess'
import ResetPw from './pages/ResetPw'
import ResetPwSuccess from './pages/ResetPwSuccess'
import NotFound from './pages/NotFound'
// restricted routes
import Dashboard from './pages/Dashboard'
import CaseManagement from './pages/CaseManagement'
import OfficerManagement from './pages/OfficerManagement'
import ChangePw from './pages/ChangePw'
import CaseDetail from './pages/CaseDetail'
import DiaryIndex from './pages/DiaryIndex'
import DiaryDetail from './pages/DiaryDetail'
import ContributionIndex from './pages/ContributionIndex'
import ContributionDetail from './pages/ContributionDetail'
import ReminderIndex from './pages/ReminderIndex'
import ReminderDetail from './pages/ReminderDetail'
import CaseContributionDetail from './pages/CaseContributionDetail'
import CaseReminderHistory from './pages/CaseReminderHistory'
import PaymentBookingIndex from './pages/PaymentBookingIndex'
import PaymentBookingDetail from './pages/PaymentBookingDetail'
import FormControl from './pages/FormControl'
import FormDetail from './pages/FormDetail'

export const publicRoutes = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/forgot-pw',
    component: ForgotPw
  },
  {
    path: '/forgot-pw-success',
    component: ForgotPwSuccess
  },
  {
    path: '/reset-pw/:token',
    component: ResetPw
  },
  {
    path: '/reset-pw-success',
    component: ResetPwSuccess
  },
  {
    path: '/404',
    component: NotFound
  }
]

export const restrictedRoutes = [
  {
    path: '/',
    component: Dashboard,
    exact: true
  },
  {
    path: '/case-management',
    component: CaseManagement
  },
  {
    path: '/officer-management',
    component: OfficerManagement
  },
  {
    path: '/change-pw',
    component: ChangePw
  },
  {
    path: '/case-detail/:caseId(\\d+)',
    component: CaseDetail,
    exact: true
  },
  {
    path: '/case-detail/:caseId(\\d+)/contributions',
    component: CaseContributionDetail
  },
  {
    path: '/case-detail/:caseId(\\d+)/reminders',
    component: CaseReminderHistory
  },
  {
    path: '/first-in',
    component: DiaryIndex
  },
  {
    path: '/diary-detail',
    component: DiaryDetail
  },
  {
    path: '/contributions',
    component: ContributionIndex
  },
  {
    path: '/contribution-detail/:contributionId(\\d+)',
    component: ContributionDetail
  },
  {
    path: '/reminders',
    component: ReminderIndex
  },
  {
    path: '/reminder-detail/:reminderId(\\d+)',
    component: ReminderDetail
  },
  {
    path: '/payment-bookings',
    component: PaymentBookingIndex
  },
  {
    path: '/payment-booking-detail',
    component: PaymentBookingDetail
  },
  {
    path: '/forms-control',
    component: FormControl
  },
  {
    path: '/form-detail/:formId(\\d+)',
    component: FormDetail
  }
]
