import * as R from 'ramda'
import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { MenuItem, Select } from '@material-ui/core'

@inject('officerStore')
@observer
class OfficerOptionSelect extends React.Component {
  async componentDidMount () {
    const { officerStore, value } = this.props
    await officerStore.fetchOfficerOptions()
    if (!value) {
      this.props.onChange(this.getFirstOption())
    }
  }

  handleChange = e => {
    const { value } = e.target
    this.props.onChange(value)
  }

  getFirstOption = () => {
    const { officerStore } = this.props
    if (officerStore.officerOptions.length > 0) {
      return R.path(['officerOptions', 0, 'id'], officerStore)
    } else {
      return 0
    }
  }

  render () {
    const { officerStore, value } = this.props

    return (
      <Select
        value={value || this.getFirstOption()}
        onChange={this.handleChange}
        fullWidth
      >
        {officerStore.officerOptions.map(officer => (
          <MenuItem key={officer.id} value={officer.id}>
            {`${officer.name} (${officer.customOfficerId})`}
          </MenuItem>
        ))}
      </Select>
    )
  }
}

OfficerOptionSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired
}

export default OfficerOptionSelect
