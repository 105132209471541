import { observable, action, runInAction, reaction } from 'mobx'

import * as api from '../services/api'
import { itemsPerPage } from '../constants/pagination'

class FormStore {
  @observable formList = []
  @observable singleForm = null
  @observable totalCount = 0
  @observable page = 1

  @observable caseForms = []
  @observable targetCaseId = 0 // for fetching case forms

  @observable filter = {
    keyword: '',
    orderBy: 'createdAt',
    order: 'DESC',
    status: '',
    type: ''
  }
  @observable error = null

  constructor (rootStore) {
    this.rootStore = rootStore
  }

  @action
  setPage = page => {
    this.page = page
  }

  @action
  updateFilter = filter => {
    this.filter = {
      ...this.filter,
      ...filter
    }
    // reset page after updating filter
    this.page = 1
  }

  @action
  setTargetCaseId = caseId => {
    this.targetCaseId = caseId
  }

  fetchFormList = async () => {
    const { accessToken } = this.rootStore.authStore
    const { keyword, orderBy, order, status, type } = this.filter
    try {
      const result = await api.fetchFormList(accessToken, {
        limit: itemsPerPage,
        offset: (this.page - 1) * itemsPerPage,
        keyword,
        orderBy,
        order,
        status,
        type
      })
      runInAction(() => {
        this.formList = result.rows
        this.totalCount = result.count
      })
    } catch (error) {
      console.log(error)
    }
  }

  fetchSingleForm = async id => {
    const { accessToken } = this.rootStore.authStore
    try {
      const result = await api.fetchSingleForm(accessToken, id)
      runInAction(() => {
        this.singleForm = result
      })
    } catch (error) {
      console.log(error)
    }
  }

  fetchCaseForms = async () => {
    const { accessToken } = this.rootStore.authStore
    try {
      const result = await api.fetchCaseForms(accessToken, {
        caseId: this.targetCaseId
      })
      runInAction(() => {
        this.caseForms = result.rows
      })
    } catch (error) {
      console.log(error)
    }
  }

  approveForm = async id => {
    const { accessToken } = this.rootStore.authStore
    try {
      await api.approveForm(accessToken, id)
    } catch (error) {
      console.log(error)
    }
  }

  rejectForm = async (id, comment) => {
    const { accessToken } = this.rootStore.authStore
    try {
      await api.rejectForm(accessToken, id, comment)
    } catch (error) {
      console.log(error)
    }
  }

  @action
  listenAndFetchFormList = () => {
    this.page = 1
    return reaction(
      () => ({
        page: this.page,
        filter: this.filter
      }),
      () => this.fetchFormList()
    )
  }

  @action
  clearError = () => {
    this.error = null
  }
}

export default FormStore
