import '@babel/polyfill'
import 'react-hot-loader'
import React, { Fragment } from 'react'
import ReactDom from 'react-dom'
import CssBaseline from '@material-ui/core/CssBaseline'

import './styles/main.scss'
import App from './App'

ReactDom.render(
  <Fragment>
    <CssBaseline />
    <App />
  </Fragment>,
  document.getElementById('app')
)
