import * as yup from 'yup'
import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  withStyles
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { Formik, Form, Field } from 'formik'
import { SimpleFileUpload } from 'formik-material-ui'
import { ExportToCsv } from 'export-to-csv'
import dayjs from 'dayjs'

import SuccessDialog from '../SuccessDialog'

const styles = theme => ({
  warning: {
    color: red[500]
  }
})

const validationSchema = yup.object().shape({
  file: yup.mixed().required()
})

const initialValues = {
  file: ''
}

@inject('caseStore')
@observer
class CaseUploadCsvDialog extends React.Component {
  state = {
    success: false,
    result: [],
    downloaded: false
  }

  toggleSuccessDialog = () => {
    this.setState(state => ({
      success: !state.success
    }))
  }

  handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const { file } = values
    const { caseStore, onClose } = this.props

    const result = await caseStore.importFromCsv(file)

    setSubmitting(false)

    if (caseStore.error) {
      setErrors({
        file: 'One or more Case ID is duplicated.'
      })
    } else {
      this.setState({
        result
      })

      // success
      caseStore.fetchCaseList()
      onClose()
      this.toggleSuccessDialog()
    }
  }

  downloadCsv = () => {
    const { result } = this.state
    const csvExporter = new ExportToCsv({
      filename: 'cases-' + dayjs().format('DD-MM-YYYY'),
      showLabels: true,
      useKeysAsHeaders: true
    })

    csvExporter.generateCsv(result)

    this.setState({
      downloaded: true
    })
  }

  render () {
    const { open, onClose, classes } = this.props
    const { success, result, downloaded } = this.state

    return (
      <Fragment>
        <Dialog open={open} onClose={onClose} fullWidth>
          <DialogTitle>Upload CSV</DialogTitle>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
          >
            {() => (
              <Form>
                <DialogContent>
                  <Field
                    type='file'
                    label='CSV file'
                    name='file'
                    placeholder='CSV file'
                    fullWidth
                    component={SimpleFileUpload}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color='primary'>
                    Close
                  </Button>
                  <Button type='submit' color='primary' variant='contained'>
                    Confirm
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
        <SuccessDialog
          open={success}
          content={
            <Fragment>
              {`${result.length} cases have been added successfully.`}
              <div className={classes.warning}>
                Note that you can only download the login credentials now.
              </div>
              <Box mt={1}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={this.downloadCsv}
                >
                  Download credentials
                </Button>
              </Box>
              <Box mt={1}>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={this.toggleSuccessDialog}
                  disabled={!downloaded}
                >
                  Close
                </Button>
              </Box>
            </Fragment>
          }
          onClose={this.toggleSuccessDialog}
          disableBackdropClick
          disableEscapeKeyDown
        />
      </Fragment>
    )
  }
}

CaseUploadCsvDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default withStyles(styles)(CaseUploadCsvDialog)
