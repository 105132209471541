// auth
export const login = 'auth/login'
export const refresh = 'auth/refresh'
export const forgotPassword = 'auth/forgotPassword'
export const resetPassword = 'auth/resetPassword'
export const changePassword = 'auth/changePassword'

// dashboard
export const dashboardReport = 'dashboard/report'

// case
export const caseIndex = 'cases'
export const caseOptions = 'cases/options'

// officer
export const officerIndex = 'officers'
export const officerOptions = 'officers/options'

// diary
export const diaryIndex = 'diaries'
export const diaryBookings = 'diaries/bookings'
export const disableDiaryTimeSlot = 'diaries/timeSlots/disable'

// contributions
export const contributionIndex = 'contributions'

// reminders
export const reminderIndex = 'reminders'

// payment bookings
export const paymentBookingIndex = 'paymentBookings'

// forms
export const formIndex = 'forms'
