import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

import ConfirmDialog from '../ConfirmDialog'

class PaymentBookingDeleteConfirmDialog extends React.Component {
  render () {
    const { open, onClose, onConfirm, booking } = this.props
    const singleCase = booking['case']

    return (
      <ConfirmDialog
        open={open}
        onClose={onClose}
        title='Cancel this booking?'
        content={
          <Box className='well' p={2}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <Typography>Case ID</Typography>
                  </td>
                  <td>
                    <Typography variant='body1'>
                      {singleCase.customCaseId}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>English Name</Typography>
                  </td>
                  <td>
                    <Typography variant='body1'>
                      {singleCase.englishName}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
        }
        onConfirm={onConfirm}
      />
    )
  }
}

PaymentBookingDeleteConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  booking: PropTypes.object.isRequired
}

export default PaymentBookingDeleteConfirmDialog
