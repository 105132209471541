import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

import ConfirmDialog from '../ConfirmDialog'

const CaseDetailConfirmDialog = ({
  variant,
  open,
  onClose,
  onConfirm,
  caseObj
}) => {
  let title
  switch (variant) {
    case 'disable':
      title = 'Disable this user?'
      break

    case 'lock':
      title = 'Return no objection letter for this case?'
      break

    case 'unlock':
      title = 'Unlock no objection letter for this case?'
      break
  }

  return (
    <ConfirmDialog
      title={title}
      content={
        <Box className='well' p={2}>
          <table>
            <tbody>
              <tr>
                <td>
                  <Typography>Case ID</Typography>
                </td>
                <td>
                  <Typography variant='body1'>
                    {caseObj.customCaseId}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>English Name</Typography>
                </td>
                <td>
                  <Typography variant='body1'>{caseObj.englishName}</Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>HCB No.</Typography>
                </td>
                <td>
                  <Typography variant='body1'>{caseObj.hcbNo}</Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      }
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  )
}

CaseDetailConfirmDialog.propTypes = {
  variant: PropTypes.oneOf(['lock', 'unlock', 'disable']).isRequired,
  caseObj: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
}

export default CaseDetailConfirmDialog
