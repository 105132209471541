import * as yup from 'yup'
import React from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'

const validationSchema = yup.object().shape({
  officerId: yup.number().required(),
  date: yup.string().required(),
  time: yup.string().required(),
  name: yup.string().required(),
  phone: yup.string().required(),
  email: yup.string(),
  hcbNo: yup
    .string()
    .matches(/^\d{4,5}\/\d{4}$/, 'Invalid HCB No.')
    .required()
})

@inject('diaryStore')
@observer
class DiaryBookingCreateDialog extends React.Component {
  handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const { diaryStore, onClose } = this.props
    await diaryStore.createDiaryBooking(values)

    setSubmitting(false)

    if (diaryStore.error) {
      const { message } = diaryStore.error
      setErrors({
        hcbNo: message
      })
    } else {
      diaryStore.fetchSingleDiary()
      onClose()
    }
  }

  render () {
    const { officerId, date, time, open, onClose } = this.props
    const initialValues = {
      officerId,
      date,
      time,
      name: '',
      phone: '',
      email: '',
      hcbNo: ''
    }

    return (
      <Dialog
        open={open}
        onEnter={this.regeneratePw}
        onClose={onClose}
        fullWidth
      >
        <DialogTitle>Add Appointment</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.handleSubmit}
          enableReinitialize
        >
          {() => (
            <Form>
              <DialogContent>
                <Field
                  label='HCB No.*'
                  name='hcbNo'
                  placeholder='4-5 Digital No./YYYY'
                  fullWidth
                  component={TextField}
                />
                <Box mb={2} />
                <Box mb={2} />
                <Field
                  label='Name*'
                  name='name'
                  placeholder='Name'
                  fullWidth
                  component={TextField}
                />
                <Box mb={2} />

                <Field
                  label='Phone*'
                  name='phone'
                  placeholder='Phone'
                  fullWidth
                  component={TextField}
                />
                <Box mb={2} />

                <Field
                  label='Email'
                  name='email'
                  placeholder='Email'
                  fullWidth
                  component={TextField}
                />
                <Box mb={2} />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} color='primary'>
                  Close
                </Button>
                <Button type='submit' color='primary' variant='contained'>
                  Create
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    )
  }
}

DiaryBookingCreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  officerId: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired
}

export default DiaryBookingCreateDialog
