import apiClient from './modules/client'
import * as paths from './modules/paths'

export const fetchContributionList = (
  accessToken,
  { limit, offset, keyword, orderBy, order }
) =>
  apiClient.get(paths.contributionIndex, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: {
      limit,
      offset,
      search: keyword,
      orderBy,
      order
    }
  })

export const fetchSingleContribution = (accessToken, id) =>
  apiClient.get(`${paths.contributionIndex}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const updateContribution = (accessToken, id, payload) =>
  apiClient.patch(`${paths.contributionIndex}/${id}`, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const deleteContribution = (accessToken, id) =>
  apiClient.delete(`${paths.contributionIndex}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const fetchCaseContributions = (
  accessToken,
  { caseId, limit, offset }
) =>
  apiClient.get(`${paths.caseIndex}/${caseId}/contributions`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: {
      limit,
      offset
    }
  })

export const importContributionsFromCsv = (accessToken, file) => {
  const formData = new window.FormData()
  formData.append('file', file)
  return apiClient.post(`${paths.contributionIndex}/csv`, formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}
