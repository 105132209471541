import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Field, ErrorMessage, connect } from 'formik'
import { Select } from 'formik-material-ui'
import { MenuItem, FormHelperText } from '@material-ui/core'

// an integration of reminder options with formik select
@inject('reminderStore')
@observer
class ReminderOptionFormikSelect extends React.Component {
  componentDidMount () {
    const { reminderStore } = this.props
    reminderStore.fetchReminderList()
  }

  hasError () {
    const { formik, name } = this.props
    const { touched, errors } = formik
    return !!(touched[name] && errors[name])
  }

  render () {
    const { reminderStore, name } = this.props
    const { reminderList } = reminderStore

    return (
      <Fragment>
        <Field name={name} component={Select} fullWidth error={this.hasError()}>
          {// filter to free text reminders only
          reminderList
            .filter(reminder => !reminder.readOnly)
            .map(reminder => (
              <MenuItem key={reminder.id} value={reminder.id}>
                {reminder.name}
              </MenuItem>
            ))}
        </Field>
        <ErrorMessage
          name={name}
          component={FormHelperText}
          error={this.hasError(name)}
        />
      </Fragment>
    )
  }
}

ReminderOptionFormikSelect.propTypes = {
  name: PropTypes.string
}

ReminderOptionFormikSelect.defaultProps = {
  name: 'reminderId'
}

export default connect(ReminderOptionFormikSelect)
