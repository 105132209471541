import { observable, action, runInAction, reaction } from 'mobx'
import dayjs from 'dayjs'

import * as api from '../services/api'

class DiaryStore {
  @observable diaryList = []
  @observable singleDiary = []
  @observable filter = {
    officerId: 0,
    date: dayjs().format('YYYY-MM-DD'),
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs()
      .add(30, 'day')
      .format('YYYY-MM-DD')
  }
  @observable error = null

  constructor (rootStore) {
    this.rootStore = rootStore
  }

  @action
  updateFilter = filter => {
    this.filter = {
      ...this.filter,
      ...filter
    }
  }

  fetchDiaryList = async () => {
    const { accessToken } = this.rootStore.authStore
    const { officerId, startDate, endDate } = this.filter
    try {
      const result = await api.fetchDiaryList(accessToken, {
        officerId,
        startDate,
        endDate
      })
      runInAction(() => {
        this.diaryList = result
      })
    } catch (error) {
      console.log(error)
    }
  }

  fetchSingleDiary = async () => {
    const { accessToken } = this.rootStore.authStore
    const { officerId, date } = this.filter
    try {
      const result = await api.fetchOfficerDiary(accessToken, {
        officerId,
        date
      })
      runInAction(() => {
        this.singleDiary = result
      })
    } catch (error) {
      console.log(error)
    }
  }

  listenAndFetchDiaryList = () =>
    reaction(
      () => {
        const { officerId, startDate, endDate } = this.filter
        return {
          officerId,
          startDate,
          endDate
        }
      },
      () => this.fetchDiaryList()
    )

  listenAndFetchSingleDiary = () =>
    reaction(
      () => {
        const { officerId, date } = this.filter
        return {
          officerId,
          date
        }
      },
      () => this.fetchSingleDiary()
    )

  disableTimeSlot = async time => {
    const { accessToken } = this.rootStore.authStore
    const { officerId, date } = this.filter
    try {
      await api.disableDiaryTimeSlot(accessToken, {
        officerId,
        date,
        time
      })
    } catch (error) {
      console.log(error)
    }
  }

  createDiaryBooking = async newBooking => {
    this.clearError()
    const { accessToken } = this.rootStore.authStore
    try {
      await api.createDiaryBooking(accessToken, newBooking)
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.error = error
      })
    }
  }

  deleteDiaryBooking = async id => {
    const { accessToken } = this.rootStore.authStore
    try {
      await api.deleteDiaryBooking(accessToken, id)
    } catch (error) {
      console.log(error)
    }
  }

  @action
  clearError = () => {
    this.error = null
  }
}

export default DiaryStore
