import * as yup from 'yup'
import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'

import SuccessDialog from '../SuccessDialog'

const validationSchema = yup.object().shape({
  name: yup.string().required('Required'),
  contentTc: yup.string().required('Required'),
  contentEn: yup.string().required('Required')
})

const initialValues = {
  name: '',
  contentTc: '',
  contentEn: ''
}

@inject('reminderStore')
@observer
class ReminderCreateDialog extends React.Component {
  state = {
    success: false
  }

  toggleSuccessDialog = () => {
    this.setState(state => ({
      success: !state.success
    }))
  }

  handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const { reminderStore, onClose } = this.props

    await reminderStore.createReminder(values)

    setSubmitting(false)

    // success
    reminderStore.fetchReminderList()
    onClose()
    this.toggleSuccessDialog()
  }

  render () {
    const { open, onClose } = this.props
    const { success } = this.state

    return (
      <Fragment>
        <Dialog open={open} onClose={onClose} fullWidth>
          <DialogTitle>Add Reminder</DialogTitle>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
            enableReinitialize
          >
            {() => (
              <Form>
                <DialogContent>
                  <Field
                    label='Heading*'
                    name='name'
                    placeholder='Heading'
                    fullWidth
                    component={TextField}
                  />
                  <Box mb={2} />
                  <Field
                    label='Content(CH)*'
                    name='contentTc'
                    placeholder='Content(CH)'
                    fullWidth
                    multiline
                    component={TextField}
                  />
                  <Box mb={2} />
                  <Field
                    label='Content(EN)*'
                    name='contentEn'
                    placeholder='Content(EN)'
                    fullWidth
                    multiline
                    component={TextField}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color='primary'>
                    Close
                  </Button>
                  <Button type='submit' color='primary' variant='contained'>
                    Create
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
        <SuccessDialog
          open={success}
          onClose={this.toggleSuccessDialog}
          content='Reminder has been added successfully'
        />
      </Fragment>
    )
  }
}

ReminderCreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ReminderCreateDialog
