import { ACCESS_TOKEN, REFRESH_TOKEN } from '../constants/tokenKeys'

export const storeTokens = ({ accessToken, refreshToken }) => {
  window.localStorage.setItem(ACCESS_TOKEN, accessToken)
  window.localStorage.setItem(REFRESH_TOKEN, refreshToken)
}

export const removeTokens = () => {
  window.localStorage.removeItem(ACCESS_TOKEN)
  window.localStorage.removeItem(REFRESH_TOKEN)
}

export const getTokens = () => {
  if (process.browser) {
    const accessToken = window.localStorage.getItem(ACCESS_TOKEN)
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN)
    return {
      accessToken,
      refreshToken
    }
  } else {
    return {
      accessToken: null,
      refreshToken: null
    }
  }
}
