import * as yup from 'yup'
import React from 'react'
import { inject, observer } from 'mobx-react'
import { Box, Grid, Paper, Typography, Button } from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'

import SuccessDialog from '../components/SuccessDialog'

const initialValues = {
  password: '',
  newPassword: '',
  newPasswordConfirm: ''
}

const validationSchema = yup.object().shape({
  password: yup.string().required(),
  newPassword: yup
    .string()
    .min(6)
    .required('New Password is a required field'),
  newPasswordConfirm: yup
    .string()
    .required('New Password Confirm is a required field')
    .oneOf([yup.ref('newPassword')], 'Password mismatch')
})

@inject('authStore')
@observer
class ChangePw extends React.Component {
  state = {
    success: false
  }

  handleSubmit = async (values, { setSubmitting, setErrors, resetForm }) => {
    const { password, newPassword } = values
    const { authStore } = this.props

    await authStore.changePassword(password, newPassword)

    if (authStore.error) {
      const { error } = authStore
      if (error.code === 'PASSWORD_INCORRECT') {
        setErrors({
          password: error.message
        })
      }
    } else {
      resetForm()
      this.toggleSuccessDialog()
    }

    setSubmitting(false)
  }

  toggleSuccessDialog = () => {
    this.setState(state => ({
      success: !state.success
    }))
  }

  render () {
    const { success } = this.state

    return (
      <div className='page change-pw'>
        <Grid container justify='center'>
          <Grid item xs={12} lg={6} xl={4}>
            <Paper>
              <Box p={3}>
                <Typography variant='h6' component='h2'>
                  Change Password
                </Typography>
                <Box mb={2} />
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={this.handleSubmit}
                >
                  {() => (
                    <Form>
                      <Field
                        label='Current Password'
                        name='password'
                        placeholder='Current Password'
                        fullWidth
                        component={TextField}
                      />
                      <Box mb={2} />
                      <Field
                        label='New Password'
                        name='newPassword'
                        placeholder='New Password'
                        fullWidth
                        component={TextField}
                      />
                      <Box mb={2} />
                      <Field
                        label='New Password Confirm'
                        name='newPasswordConfirm'
                        placeholder='New Password Confirm'
                        fullWidth
                        component={TextField}
                      />
                      <Box mb={2} />
                      <Button type='submit' color='primary' variant='contained'>
                        Update Password
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <SuccessDialog
          open={success}
          onClose={this.toggleSuccessDialog}
          content='Password updated successfully'
        />
      </div>
    )
  }
}

export default ChangePw
