import * as R from 'ramda'
import * as yup from 'yup'
import React from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'

@inject('contributionStore')
@observer
class ContributionEditDialog extends React.Component {
  handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const { contributionStore, onClose } = this.props
    const { singleContribution } = contributionStore

    await contributionStore.updateContribution(singleContribution.id, values)

    setSubmitting(false)

    contributionStore.fetchSingleContribution(singleContribution.id)
    onClose()
  }

  render () {
    const { open, onClose, contributionStore, field } = this.props
    const { singleContribution } = contributionStore

    const validationSchema = yup.object().shape({
      [field]: field === 'amount' ? yup.number().required() : yup.string()
    })
    const initialValues = R.pick([field], singleContribution)

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>Edit {field}</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.handleSubmit}
          enableReinitialize
        >
          {() => (
            <Form>
              <DialogContent>
                {field === 'amount' ? (
                  <Field
                    type='number'
                    label='Amount'
                    name='amount'
                    placeholder='HKD $'
                    fullWidth
                    component={TextField}
                  />
                ) : (
                  <Field
                    label='Remark'
                    name='remark'
                    placeholder='Type your message here'
                    fullWidth
                    multiline
                    component={TextField}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} color='primary'>
                  Close
                </Button>
                <Button type='submit' color='primary' variant='contained'>
                  Confirm
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    )
  }
}

ContributionEditDialog.propTypes = {
  field: PropTypes.oneOf(['amount', 'remark']).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ContributionEditDialog
