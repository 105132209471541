import * as R from 'ramda'
import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Box,
  Grid,
  Button,
  Paper,
  Table,
  TableHead,
  TableBody,
  Typography,
  TableRow,
  TableCell,
  IconButton,
  withStyles
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { Edit } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import qs from 'qs'

import PaymentBookingDeleteConfirmDialog from '../components/paymentBookings/PaymentBookingDeleteConfirmDialog'
import SuccessDialog from '../components/SuccessDialog'
import PaymentBookingDetailToolbar from '../components/paymentBookings/PaymentBookingDetailToolbar'
import PaymentBookingEditQuotaDialog from '../components/paymentBookings/PaymentBookingEditQuotaDialog'

const styles = theme => ({
  cancelButton: {
    color: red[500]
  }
})

@inject('paymentBookingStore')
@observer
class PaymentBookingDetail extends React.Component {
  state = {
    targetBooking: null,
    showConfirmDeleteDialog: false,
    showSuccessDeleteDialog: false,
    showEditQuotaDialog: false
  }

  componentDidMount () {
    this.fetchData()
  }

  fetchData = () => {
    const { paymentBookingStore, location } = this.props
    const { date, time } = qs.parse(location.search, {
      ignoreQueryPrefix: true
    })

    // fetch booking status
    paymentBookingStore.updateFilter({
      date
    })
    paymentBookingStore.fetchBookingStatus()

    // fetch booked cases
    paymentBookingStore.fetchBookings(date, time)
  }

  onDeleteBtnClicked = booking => () => {
    this.setState({
      targetBooking: booking,
      showConfirmDeleteDialog: true
    })
  }

  onConfirmDelete = async () => {
    const { targetBooking } = this.state
    const { paymentBookingStore, location } = this.props
    const { date, time } = qs.parse(location.search, {
      ignoreQueryPrefix: true
    })
    await paymentBookingStore.deleteBooking(targetBooking.id)
    paymentBookingStore.fetchBookings(date, time)

    this.toggleConfirmDeleteDialog()
    this.toggleSuccessDeleteDialog()
  }

  toggleConfirmDeleteDialog = () => {
    this.setState(state => ({
      showConfirmDeleteDialog: !state.showConfirmDeleteDialog
    }))
  }

  toggleSuccessDeleteDialog = () => {
    this.setState(state => ({
      showSuccessDeleteDialog: !state.showSuccessDeleteDialog
    }))
  }

  toggleEditQuotaDialog = () => {
    this.setState(state => ({
      showEditQuotaDialog: !state.showEditQuotaDialog
    }))
  }

  render () {
    const {
      targetBooking,
      showConfirmDeleteDialog,
      showSuccessDeleteDialog,
      showEditQuotaDialog
    } = this.state
    const { paymentBookingStore, location, classes } = this.props
    const { date, time } = qs.parse(location.search, {
      ignoreQueryPrefix: true
    })
    const { bookingStatus, bookingList } = paymentBookingStore
    const slot = bookingStatus.slots.find(slot => slot.time === time)

    return (
      <div>
        <Box mb={2}>
          <Button color='primary' component={Link} to='/payment-bookings'>
            Payment Booking
          </Button>
          /{' '}
          <Typography display='inline'>
            {dayjs(`${date} ${time}`).format('DD/MM/YYYY h:mma')}
          </Typography>
        </Box>
        <Box mb={2}>
          <PaymentBookingDetailToolbar
            date={date}
            time={time}
            disabled={R.gte(R.prop('booked', slot), R.prop('quota', slot))}
          />
        </Box>
        <Paper>
          <Box pl={3} pr={1} py={2}>
            <Grid container justify='space-between' alignItems='center'>
              <Typography variant='h6' component='h2'>
                {dayjs(`${date} ${time}`).format('DD/MM/YYYY h:mma')}
              </Typography>
              <IconButton onClick={this.toggleEditQuotaDialog}>
                <Edit />
              </IconButton>
            </Grid>
            <Typography variant='body1'>
              Number of booking: {R.propOr(0, 'booked', slot)} /{' '}
              {R.propOr(0, 'quota', slot)}
            </Typography>
          </Box>
        </Paper>
        <Box mb={2} />
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order</TableCell>
                <TableCell>Case ID</TableCell>
                <TableCell>English Name</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {bookingList.map((row, index) => {
                const bookingCase = row['case']
                return (
                  <TableRow key={row.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <Button
                        color='primary'
                        component={Link}
                        to={`/case-detail/${bookingCase.id}`}
                      >
                        {bookingCase.customCaseId}
                      </Button>
                    </TableCell>
                    <TableCell>{bookingCase.englishName}</TableCell>
                    <TableCell>
                      <Button
                        className={classes.cancelButton}
                        onClick={this.onDeleteBtnClicked(row)}
                      >
                        Cancel
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Paper>
        {targetBooking && (
          <PaymentBookingDeleteConfirmDialog
            open={showConfirmDeleteDialog}
            onClose={this.toggleConfirmDeleteDialog}
            onConfirm={this.onConfirmDelete}
            booking={targetBooking}
          />
        )}
        <SuccessDialog
          open={showSuccessDeleteDialog}
          content='The booking has been cancelled successfully'
          onClose={this.toggleSuccessDeleteDialog}
        />
        {slot && (
          <PaymentBookingEditQuotaDialog
            open={showEditQuotaDialog}
            onClose={this.toggleEditQuotaDialog}
            slot={slot}
            date={date}
            time={time}
          />
        )}
      </div>
    )
  }
}

export default withStyles(styles)(PaymentBookingDetail)
