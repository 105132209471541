import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'

import ConfirmDialog from '../ConfirmDialog'
import OfficerOptionSelect from '../officers/OfficerOptionSelect'

@inject('caseStore')
@observer
class CaseTransferDialog extends React.Component {
  state = {
    officerId: 0
  }

  onConfirm = async () => {
    const { officerId } = this.state
    const { selectedCaseIds, caseStore, onSuccess } = this.props

    await caseStore.transferCases(officerId, selectedCaseIds)
    onSuccess()
  }

  onOfficerSelected = officerId => {
    this.setState({
      officerId
    })
  }

  render () {
    const { open, onClose, selectedCaseIds } = this.props
    const { officerId } = this.state

    return (
      <ConfirmDialog
        open={open}
        onClose={onClose}
        onConfirm={this.onConfirm}
        title={`Please select an officer to transfer the ${
          selectedCaseIds.length
        } selected case(s).`}
        content={
          <OfficerOptionSelect
            value={officerId}
            onChange={this.onOfficerSelected}
          />
        }
      />
    )
  }
}

CaseTransferDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedCaseIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSuccess: PropTypes.func.isRequired
}

export default CaseTransferDialog
