import apiClient from './modules/client'
import * as paths from './modules/paths'

export const fetchCaseList = (
  accessToken,
  { limit, offset, keyword, orderBy, order }
) =>
  apiClient.get(paths.caseIndex, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: {
      limit,
      offset,
      search: keyword,
      orderBy,
      order
    }
  })

export const fetchSingleCase = (accessToken, id) =>
  apiClient.get(`${paths.caseIndex}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const fetchCaseOptions = accessToken =>
  apiClient.get(paths.caseOptions, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const createCase = (accessToken, payload) =>
  apiClient.post(paths.caseIndex, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const updateCase = (accessToken, id, payload) =>
  apiClient.patch(`${paths.caseIndex}/${id}`, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

export const disableCase = (accessToken, id) =>
  apiClient.post(
    `${paths.caseIndex}/${id}/disable`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )

export const updateNoObjectionLetterStatus = (accessToken, id, lock) =>
  apiClient.patch(
    `${paths.caseIndex}/${id}/noObjectionLetter`,
    {
      lock
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )

export const searchCase = (accessToken, query) =>
  apiClient.get(`${paths.caseIndex}/search`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: {
      query
    }
  })

export const transferCases = (accessToken, officerId, caseIds) =>
  apiClient.patch(
    `${paths.officerIndex}/${officerId}/cases/`,
    {
      caseIds
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )

export const importCasesFromCsv = (accessToken, file) => {
  const formData = new window.FormData()
  formData.append('file', file)
  return apiClient.post(`${paths.caseIndex}/csv`, formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}
