import React, { Fragment } from 'react'

const parseContent = (content, params) =>
  Object.entries(params).reduce(
    (acc, [key, value]) => acc.replace(new RegExp(`{${key}}`, 'g'), value),
    content
  )

export const parseReminder = (reminder, params) => {
  // replace content key by values in `params` object,
  // then return a new reminder
  if (params === null) {
    return reminder
  }

  return {
    ...reminder,
    contentTc: parseContent(reminder.contentTc, params),
    contentEn: parseContent(reminder.contentEn, params)
  }
}

export const nl2br = text =>
  text.split('\n').map((item, index) => (
    <Fragment key={index}>
      {index !== 0 && <br />}
      {item}
    </Fragment>
  ))
