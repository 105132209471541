import { observable, runInAction } from 'mobx'

import * as api from '../services/api'

class DashboardStore {
  @observable report = null

  constructor (rootStore) {
    this.rootStore = rootStore
  }

  fetchReport = async () => {
    const { accessToken } = this.rootStore.authStore
    try {
      const report = await api.fetchDashboardReport(accessToken)
      runInAction(() => {
        this.report = report
      })
    } catch (error) {
      console.log(error)
    }
  }
}

export default DashboardStore
