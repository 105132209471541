import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  withStyles
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { Check } from '@material-ui/icons'

const styles = theme => ({
  check: {
    color: green[500],
    fontSize: 120
  }
})

class SuccessDialog extends React.Component {
  render () {
    const { open, onClose, title, content, classes, ...restProps } = this.props

    return (
      <Dialog open={open} onClose={onClose} fullWidth {...restProps}>
        <DialogContent>
          <Grid container justify='center' alignItems='center'>
            <Box py={5}>
              <Typography align='center'>
                <Check className={classes.check} />
              </Typography>
              <Typography variant='h6' align='center'>
                {title}
              </Typography>
              <Typography align='center' component='div'>
                {content}
              </Typography>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }
}

SuccessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.node,
  content: PropTypes.node.isRequired
}

SuccessDialog.defaultProps = {
  title: 'Success!'
}

export default withStyles(styles)(SuccessDialog)
