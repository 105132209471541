import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Box,
  Button,
  Paper,
  Table,
  TableHead,
  TableBody,
  Typography,
  TableRow,
  TableCell,
  Grid,
  withStyles
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import Pagination from 'material-ui-flat-pagination'
import { Link } from 'react-router-dom'

import Loading from '../components/layouts/Loading'
import { itemsPerPage } from '../constants/pagination'
import ContributionDeleteConfirmDialog from '../components/contributions/ContributionDeleteConfirmDialog'
import SuccessDialog from '../components/SuccessDialog'
import dayjs from 'dayjs'

const styles = theme => ({
  deleteButton: {
    color: red[500]
  }
})

@inject('caseStore', 'contributionStore')
@observer
class CaseContributionDetail extends React.Component {
  state = {
    targetContribution: null,
    showConfirmDeleteDialog: false,
    showSuccessDeleteDialog: false
  }

  componentDidMount () {
    const { caseStore, contributionStore, match } = this.props
    const { caseId } = match.params
    caseStore.fetchSingleCase(caseId)
    contributionStore.setTargetCaseId(caseId)
    contributionStore.fetchCaseContributions()
    this.cancelListen = contributionStore.listenAndFetchCaseContributions()
  }

  componentWillUnmount () {
    if (this.cancelListen) {
      this.cancelListen()
    }
  }

  onChangePage = (e, offset) => {
    const { contributionStore } = this.props
    contributionStore.setCaseContributionPage(offset / itemsPerPage + 1)
  }

  onDeleteBtnClicked = contribution => () => {
    this.setState({
      targetContribution: contribution,
      showConfirmDeleteDialog: true
    })
  }

  onConfirmDelete = async () => {
    const { targetContribution } = this.state
    const { contributionStore } = this.props
    await contributionStore.deleteContribution(targetContribution.id)
    contributionStore.fetchCaseContributions()

    this.toggleConfirmDeleteDialog()
    this.toggleSuccessDeleteDialog()
  }

  toggleConfirmDeleteDialog = () => {
    this.setState(state => ({
      showConfirmDeleteDialog: !state.showConfirmDeleteDialog
    }))
  }

  toggleSuccessDeleteDialog = () => {
    this.setState(state => ({
      showSuccessDeleteDialog: !state.showSuccessDeleteDialog
    }))
  }

  render () {
    const {
      showConfirmDeleteDialog,
      showSuccessDeleteDialog,
      targetContribution
    } = this.state
    const { caseStore, contributionStore, classes } = this.props
    const { singleCase } = caseStore
    const {
      totalContributions,
      monthlyContributions,
      caseContributions,
      caseContributionPage,
      caseContributionCount
    } = contributionStore

    if (!singleCase) {
      return <Loading fullHeight />
    }

    return (
      <div>
        <Box mb={2}>
          <Button color='primary' component={Link} to='/case-management'>
            Case Management
          </Button>
          /{' '}
          <Button
            color='primary'
            component={Link}
            to={`/case-detail/${singleCase.id}`}
          >
            {singleCase.customCaseId}
          </Button>
          / <Typography display='inline'>Contribution</Typography>
        </Box>
        <Paper>
          <Box pl={3} pr={1} py={2}>
            <Typography variant='h6' component='h2'>
              Contribution Summary
            </Typography>
          </Box>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Total Contribution</TableCell>
                <TableCell>${' ' + totalContributions}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>This month Contribution</TableCell>
                <TableCell>${' ' + monthlyContributions}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Box mb={2} />
        <Paper>
          <Box pl={3} pr={1} py={2}>
            <Typography variant='h6' component='h2'>
              Contribution History
            </Typography>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Remark</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {caseContributions.map(row => (
                <TableRow key={row.id}>
                  <TableCell>{dayjs(row.date).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{row.amount}</TableCell>
                  <TableCell>{row.remark || '-'}</TableCell>
                  <TableCell>
                    <Button
                      className={classes.deleteButton}
                      onClick={this.onDeleteBtnClicked(row)}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justify='flex-end'>
            <Pagination
              currentPageColor='primary'
              size='large'
              limit={itemsPerPage}
              offset={(caseContributionPage - 1) * itemsPerPage}
              total={caseContributionCount}
              onClick={this.onChangePage}
            />
          </Grid>
        </Paper>
        {targetContribution && (
          <ContributionDeleteConfirmDialog
            open={showConfirmDeleteDialog}
            onClose={this.toggleConfirmDeleteDialog}
            onConfirm={this.onConfirmDelete}
            contribution={targetContribution}
          />
        )}
        <SuccessDialog
          open={showSuccessDeleteDialog}
          content='The contribution has been removed successfully'
          onClose={this.toggleSuccessDeleteDialog}
        />
      </div>
    )
  }
}

export default withStyles(styles)(CaseContributionDetail)
