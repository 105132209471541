import * as R from 'ramda'
import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableHead,
  TableSortLabel,
  TableBody,
  Typography,
  TableRow,
  TableCell,
  InputBase,
  Toolbar,
  Checkbox,
  withStyles
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import dayjs from 'dayjs'
import Pagination from 'material-ui-flat-pagination'
import { Link } from 'react-router-dom'

import { itemsPerPage } from '../constants/pagination'
import CaseCreateDialog from '../components/cases/CaseCreateDialog'
import CaseTransferDialog from '../components/cases/CaseTransferDialog'
import CaseUploadCsvDialog from '../components/cases/CaseUploadCsvDialog'

const styles = theme => ({
  searchBox: {
    marginRight: theme.spacing(3)
  },
  toolbarButton: {
    marginRight: theme.spacing(1)
  }
})

@inject('caseStore')
@observer
class CaseManagement extends React.Component {
  state = {
    showCreateDialog: false,
    showTransferDialog: false,
    showImportDialog: false,
    selectedCaseIds: new Set()
  }

  componentDidMount () {
    const { caseStore } = this.props
    caseStore.fetchCaseList()
    this.cancelListen = caseStore.listenAndFetchCaseList()
  }

  componentWillUnmount () {
    if (this.cancelListen) {
      this.cancelListen()
    }
  }

  onKeywordChanged = e => {
    const { caseStore } = this.props
    const { value } = e.target
    caseStore.updateFilter({
      keyword: value
    })
  }

  onChangePage = (e, offset) => {
    const { caseStore } = this.props
    caseStore.setPage(offset / itemsPerPage + 1)
  }

  onSortOrderChanged = property => () => {
    const { caseStore } = this.props
    const { filter } = caseStore
    if (filter.orderBy === property) {
      caseStore.updateFilter({
        order: filter.order === 'ASC' ? 'DESC' : 'ASC'
      })
    } else {
      caseStore.updateFilter({
        orderBy: property,
        order: 'DESC'
      })
    }
  }

  toggleCreateDialog = () => {
    this.setState(state => ({
      showCreateDialog: !state.showCreateDialog
    }))
  }

  toggleTransferDialog = () => {
    this.setState(state => ({
      showTransferDialog: !state.showTransferDialog
    }))
  }

  toggleImportDialog = () => {
    this.setState(state => ({
      showImportDialog: !state.showImportDialog
    }))
  }

  onCheckboxChanged = caseId => (e, checked) => {
    this.setState(state => {
      const newSet = new Set(state.selectedCaseIds)
      if (checked) {
        newSet.add(caseId)
      } else {
        newSet.delete(caseId)
      }
      return {
        selectedCaseIds: newSet
      }
    })
  }

  clearSelectedCases = () => {
    this.setState({
      selectedCaseIds: new Set()
    })
  }

  onSuccessTransfer = () => {
    const { caseStore } = this.props
    this.clearSelectedCases()
    this.toggleTransferDialog()
    caseStore.fetchCaseList()
  }

  render () {
    const {
      showCreateDialog,
      showTransferDialog,
      showImportDialog,
      selectedCaseIds
    } = this.state
    const { caseStore, classes } = this.props
    const { caseList, page, totalCount, filter } = caseStore

    return (
      <div>
        <Box mb={2}>
          <Toolbar disableGutters>
            <Paper className={classes.searchBox}>
              <Box py={1 / 2} px={2}>
                <Grid container spacing={8} alignItems='flex-end'>
                  <Grid item>
                    <Search />
                  </Grid>
                  <Grid item>
                    <InputBase
                      placeholder='SEARCH'
                      value={filter.keyword}
                      onChange={this.onKeywordChanged}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Button
              className={classes.toolbarButton}
              variant='contained'
              color='secondary'
              onClick={this.toggleCreateDialog}
            >
              Add Case
            </Button>
            <Button
              className={classes.toolbarButton}
              variant='contained'
              color='secondary'
              onClick={this.toggleImportDialog}
            >
              Upload CSV
            </Button>
            <Button
              className={classes.toolbarButton}
              variant='contained'
              color='secondary'
              disabled={selectedCaseIds.size === 0}
              onClick={this.toggleTransferDialog}
            >
              Transfer Cases
            </Button>
          </Toolbar>
        </Box>
        <Paper>
          <Box p={3}>
            <Typography variant='h6' component='h2'>
              Case Management
            </Typography>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding='checkbox' />
                <TableCell>
                  <TableSortLabel
                    active={filter.orderBy === 'customCaseId'}
                    direction={filter.order.toLowerCase()}
                    onClick={this.onSortOrderChanged('customCaseId')}
                  >
                    Case ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={filter.orderBy === 'hcbNo'}
                    direction={filter.order.toLowerCase()}
                    onClick={this.onSortOrderChanged('hcbNo')}
                  >
                    HCB No.
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={filter.orderBy === 'englishName'}
                    direction={filter.order.toLowerCase()}
                    onClick={this.onSortOrderChanged('englishName')}
                  >
                    English Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={filter.orderBy === 'officerId'}
                    direction={filter.order.toLowerCase()}
                    onClick={this.onSortOrderChanged('officerId')}
                  >
                    Officer
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={filter.orderBy === 'boDate'}
                    direction={filter.order.toLowerCase()}
                    onClick={this.onSortOrderChanged('boDate')}
                  >
                    BO Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={filter.orderBy === 'lastLogin'}
                    direction={filter.order.toLowerCase()}
                    onClick={this.onSortOrderChanged('lastLogin')}
                  >
                    Last Login
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {caseList.map(row => (
                <TableRow key={row.id}>
                  <TableCell padding='checkbox'>
                    <Checkbox
                      color='primary'
                      checked={selectedCaseIds.has(row.id)}
                      onChange={this.onCheckboxChanged(row.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      color='primary'
                      component={Link}
                      to={`/case-detail/${row.id}`}
                    >
                      {row.customCaseId}
                    </Button>
                  </TableCell>
                  <TableCell>{row.hcbNo}</TableCell>
                  <TableCell>{row.englishName}</TableCell>
                  <TableCell>
                    {R.ifElse(
                      R.prop('officer'),
                      row =>
                        `${R.path(['officer', 'name'], row)} (${R.path(
                          ['officer', 'customOfficerId'],
                          row
                        )})`,
                      R.always('-')
                    )(row)}
                  </TableCell>
                  <TableCell>
                    {row.boDate ? dayjs(row.boDate).format('DD-MM-YYYY') : ''}
                  </TableCell>
                  <TableCell>
                    {row.lastLogin
                      ? dayjs(row.lastLogin).format('DD/MM/YYYY HH:mm:ss')
                      : '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justify='flex-end'>
            <Pagination
              currentPageColor='primary'
              size='large'
              limit={itemsPerPage}
              offset={(page - 1) * itemsPerPage}
              total={totalCount}
              onClick={this.onChangePage}
            />
          </Grid>
        </Paper>
        <CaseCreateDialog
          open={showCreateDialog}
          onClose={this.toggleCreateDialog}
        />
        <CaseUploadCsvDialog
          open={showImportDialog}
          onClose={this.toggleImportDialog}
        />
        <CaseTransferDialog
          open={showTransferDialog}
          onClose={this.toggleTransferDialog}
          onSuccess={this.onSuccessTransfer}
          selectedCaseIds={[...selectedCaseIds]}
        />
      </div>
    )
  }
}

export default withStyles(styles)(CaseManagement)
