import * as R from 'ramda'
import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.API_HOST,
  timeout: 5000
})

apiClient.interceptors.response.use(
  response => response.data,
  error => {
    throw R.pathOr(
      { code: 'UNKNOWN_SERVER_ERROR' },
      ['response', 'data'],
      error
    )
  }
)

export default apiClient
