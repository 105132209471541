import * as yup from 'yup'
import React from 'react'
import { inject, observer } from 'mobx-react'
import { Box, Grid, Typography, Button } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import { Formik, Form, Field } from 'formik'

import logo from '../images/hkdm_logo.png'

const initialValues = {
  password: '',
  passwordConfirm: ''
}

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .min(6)
    .required(),
  passwordConfirm: yup
    .string()
    .required()
    .oneOf([yup.ref('password')])
})

@inject('authStore')
@observer
class ResetPw extends React.Component {
  handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const { password } = values
    const { authStore, match } = this.props
    const { token } = match.params
    await authStore.resetPassword(token, password)

    if (authStore.error) {
      setErrors({
        password: authStore.error.message
      })
    }

    setSubmitting(false)
  }

  render () {
    return (
      <Grid
        className='page reset-pw'
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justify='center'
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.handleSubmit}
        >
          {() => (
            <Form>
              <Box width={350}>
                <Box mb={5}>
                  <div className='logo-container'>
                    <img id='logo' src={logo} alt='logo' />
                  </div>
                  <Typography component='h1' variant='h5' color='secondary'>
                    HKDMS Management System
                  </Typography>
                </Box>
                <Typography variant='body1' color='secondary' align='left'>
                  Please enter your new password
                </Typography>
                <Box mb={1} />
                <Field
                  type='password'
                  name='password'
                  placeholder='New Password'
                  fullWidth
                  component={TextField}
                />
                <Box mb={2} />
                <Field
                  type='password'
                  name='passwordConfirm'
                  placeholder='Confirm Password'
                  fullWidth
                  component={TextField}
                />
                <Box mb={5} />
                <Button
                  type='submit'
                  variant='contained'
                  color='secondary'
                  fullWidth
                >
                  Confirm
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
    )
  }
}

export default ResetPw
