import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Box,
  Toolbar,
  Grid,
  Button,
  Paper,
  Table,
  TableHead,
  TableBody,
  TextField,
  Typography,
  TableRow,
  TableCell
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

import { timeSlots } from '../constants/diaryBooking'
import OfficerOptionSelect from '../components/officers/OfficerOptionSelect'

@inject('diaryStore')
@observer
class DiaryIndex extends React.Component {
  componentDidMount () {
    const { diaryStore } = this.props
    diaryStore.fetchDiaryList()

    this.cancelListen = diaryStore.listenAndFetchDiaryList()
  }

  componentWillUnmount () {
    if (this.cancelListen) {
      this.cancelListen()
    }
  }

  onOfficerChanged = officerId => {
    this.updateFilter('officerId', officerId)
  }

  onDateFilterUpdated = e => {
    const { name, value } = e.target
    this.updateFilter(name, value)
  }

  updateFilter = (name, value) => {
    const { diaryStore } = this.props
    diaryStore.updateFilter({
      [name]: value
    })
  }

  getStatusNode = (status, date) => {
    const { diaryStore } = this.props
    const { filter } = diaryStore

    switch (status) {
      case 'available':
        return <Button disableRipple>{status}</Button>

      case 'booked':
        return (
          <Button
            color='primary'
            component={Link}
            to={`/diary-detail?officerId=${filter.officerId}&date=${date}`}
          >
            {status}
          </Button>
        )

      case 'disabled':
        return <Button disabled>{status}</Button>
    }
  }

  render () {
    const { diaryStore } = this.props
    const { diaryList, filter } = diaryStore

    return (
      <Fragment>
        <Box mb={2}>
          <Paper>
            <Box px={3}>
              <Toolbar disableGutters>
                <Grid container spacing={8} alignItems='center'>
                  <Grid item xs='auto'>
                    <Typography variant='body1'>Officer</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <OfficerOptionSelect
                      onChange={this.onOfficerChanged}
                      value={filter.officerId}
                    />
                  </Grid>
                  <Grid item xs='auto'>
                    <Box ml={2}>
                      <Typography variant='body1'>Start Date</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs='auto'>
                    <TextField
                      type='date'
                      name='startDate'
                      value={filter.startDate}
                      onChange={this.onDateFilterUpdated}
                    />
                  </Grid>
                  <Grid item xs='auto'>
                    <Box ml={2}>
                      <Typography variant='body1'>End Date</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      type='date'
                      name='endDate'
                      value={filter.endDate}
                      onChange={this.onDateFilterUpdated}
                    />
                  </Grid>
                </Grid>
              </Toolbar>
            </Box>
          </Paper>
        </Box>
        <Paper>
          <Box p={3}>
            <Typography variant='h6' component='h2'>
              Diary
            </Typography>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                {timeSlots.map(slot => (
                  <TableCell key={slot}>
                    {dayjs(`2000-01-01 ${slot}`).format('h:mma')}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {diaryList.map(row => (
                <TableRow key={row.date}>
                  <TableCell>
                    <Button
                      color='primary'
                      component={Link}
                      to={`/diary-detail?officerId=${filter.officerId}&date=${
                        row.date
                      }`}
                    >
                      {dayjs(row.date).format('DD/MM/YYYY')}
                    </Button>
                  </TableCell>
                  {row.slots.map(slot => (
                    <TableCell key={`${row.date}-${slot.time}`}>
                      {this.getStatusNode(slot.status, row.date)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Fragment>
    )
  }
}

export default DiaryIndex
