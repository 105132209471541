import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import qs from 'qs'
import {
  Box,
  Button,
  Typography,
  Toolbar,
  Grid,
  Paper
} from '@material-ui/core'
import { Link } from 'react-router-dom'

import BookingCard from '../components/diaries/BookingCard'
import OfficerOptionSelect from '../components/officers/OfficerOptionSelect'
import dayjs from 'dayjs'

@inject('diaryStore')
@observer
class DiaryDetail extends React.Component {
  componentDidMount () {
    const { diaryStore, location } = this.props
    const { officerId, date } = qs.parse(location.search, {
      ignoreQueryPrefix: true
    })

    diaryStore.updateFilter({
      officerId: parseInt(officerId),
      date
    })
    diaryStore.fetchSingleDiary()
    this.cancelListen = diaryStore.listenAndFetchSingleDiary()
  }

  componentWillUnmount () {
    if (this.cancelListen) {
      this.cancelListen()
    }
  }

  onOfficerChanged = officerId => {
    this.updateFilter('officerId', officerId)
  }

  updateFilter = (name, value) => {
    const { diaryStore } = this.props
    diaryStore.updateFilter({
      [name]: value
    })
  }

  render () {
    const { diaryStore } = this.props
    const { singleDiary, filter } = diaryStore

    return (
      <Fragment>
        <Box mb={2}>
          <Button color='primary' component={Link} to='/first-in'>
            Diary
          </Button>
          /{' '}
          <Typography display='inline'>
            {dayjs(filter.date).format('DD/MM/YYYY')}
          </Typography>
        </Box>
        <Box mb={2}>
          <Paper>
            <Box px={3}>
              <Toolbar disableGutters>
                <Grid container spacing={8} alignItems='center'>
                  <Grid item xs='auto'>
                    <Typography variant='body1'>Officer</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <OfficerOptionSelect
                      onChange={this.onOfficerChanged}
                      value={filter.officerId}
                    />
                  </Grid>
                </Grid>
              </Toolbar>
            </Box>
          </Paper>
        </Box>
        {singleDiary.map(slot => (
          <BookingCard key={slot.time} slot={slot} />
        ))}
      </Fragment>
    )
  }
}

export default DiaryDetail
