import React from 'react'
import { Box, Grid, Typography, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

class NotFound extends React.Component {
  render () {
    return (
      <Grid
        className='page not-found'
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justify='center'
      >
        <Typography component='h1' variant='h2' color='secondary'>
          404 Not Found
        </Typography>
        <Box mb={2} />
        <Typography component='h1' variant='body1' color='secondary'>
          The page you are looking for could not be found.
        </Typography>
        <Box mb={5} />
        <Button variant='contained' color='secondary' component={Link} to='/'>
          Back to home
        </Button>
      </Grid>
    )
  }
}

export default NotFound
