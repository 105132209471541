import * as R from 'ramda'
import React from 'react'
import { inject, observer } from 'mobx-react'
import { Box, Grid, Card, Typography } from '@material-ui/core'
import {
  AccountCircle,
  AssignmentInd,
  DateRange,
  Event,
  Chat,
  Edit,
  AttachMoney
} from '@material-ui/icons'
import { Link } from 'react-router-dom'

import DashboardCard from '../components/DashboardCard'

@inject('authStore', 'dashboardStore')
@observer
class Dashboard extends React.Component {
  componentDidMount () {
    const { dashboardStore } = this.props
    dashboardStore.fetchReport()
  }

  render () {
    const { dashboardStore, authStore } = this.props
    const { report } = dashboardStore

    const cards = [
      {
        className: 'peach',
        Icon: AccountCircle,
        text: 'Case Management',
        link: '/case-management'
      },
      {
        className: 'navy',
        Icon: DateRange,
        text: 'First-in',
        link: '/first-in'
      },
      {
        className: 'green',
        Icon: Event,
        text: 'Payment Bookings',
        link: '/payment-bookings'
      },
      {
        className: 'purple',
        Icon: Chat,
        text: 'Reminders',
        link: '/reminders'
      },
      {
        className: 'lake-green',
        Icon: Edit,
        text: 'Forms Control',
        link: '/forms-control'
      },
      {
        className: 'pink',
        Icon: AttachMoney,
        text: 'Contributions',
        link: '/contributions'
      }
    ]

    if (authStore.isAdmin || authStore.isSuperAdmin) {
      cards.push({
        className: 'blue',
        link: '/officer-management',
        Icon: AssignmentInd,
        text: authStore.isSuperAdmin
          ? 'Officer/Admin Management'
          : 'Officer Management'
      })
    }

    return (
      <div className='page dashboard'>
        <Grid container spacing={2}>
          {/* first row */}
          <Grid item md={6}>
            <Card>
              <Link to='/case-management'>
                <Box px={6} py={3}>
                  <Grid container justify='space-between' alignItems='center'>
                    <Typography variant='h6' color='textPrimary'>
                      Total Case
                    </Typography>
                    <Typography variant='h5' color='primary'>
                      {R.pathOr(0, ['count', 'case'], report)}
                    </Typography>
                  </Grid>
                </Box>
              </Link>
            </Card>
          </Grid>
          <Grid item md={3}>
            <Card>
              <Link to='/officer-management'>
                <Box px={6} py={3}>
                  <Grid container justify='space-between' alignItems='center'>
                    <Typography variant='h6' color='textPrimary'>
                      Total Officer
                    </Typography>
                    <Typography variant='h5' color='primary'>
                      {R.pathOr(0, ['count', 'officer'], report)}
                    </Typography>
                  </Grid>
                </Box>
              </Link>
            </Card>
          </Grid>
          <Grid item md={3}>
            <Card>
              <Link to='/officer-management'>
                <Box px={6} py={3}>
                  <Grid container justify='space-between' alignItems='center'>
                    <Typography variant='h6' color='textPrimary'>
                      Total Admin
                    </Typography>
                    <Typography variant='h5' color='primary'>
                      {R.pathOr(0, ['count', 'admin'], report)}
                    </Typography>
                  </Grid>
                </Box>
              </Link>
            </Card>
          </Grid>
          {/* first row */}
          {cards.map((config, index) => (
            <Grid item md={3} key={index}>
              <DashboardCard {...config} />
            </Grid>
          ))}
        </Grid>
      </div>
    )
  }
}

export default Dashboard
