import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Field, ErrorMessage, connect } from 'formik'
import { Select } from 'formik-material-ui'
import { MenuItem, FormHelperText } from '@material-ui/core'

// an integration of officer options with formik select
@inject('officerStore')
@observer
class OfficerOptionFormikSelect extends React.Component {
  componentDidMount () {
    const { officerStore } = this.props
    officerStore.fetchOfficerOptions()
  }

  hasError () {
    const { formik, name } = this.props
    const { touched, errors } = formik
    return !!(touched[name] && errors[name])
  }

  render () {
    const { officerStore, name } = this.props

    return (
      <Fragment>
        <Field name={name} component={Select} fullWidth error={this.hasError()}>
          {officerStore.officerOptions.map(officer => (
            <MenuItem key={officer.id} value={officer.id}>
              {`${officer.name} (${officer.customOfficerId})`}
            </MenuItem>
          ))}
        </Field>
        <ErrorMessage
          name={name}
          component={FormHelperText}
          error={this.hasError(name)}
        />
      </Fragment>
    )
  }
}

OfficerOptionFormikSelect.propTypes = {
  name: PropTypes.string
}

OfficerOptionFormikSelect.defaultProps = {
  name: 'officerId'
}

export default connect(OfficerOptionFormikSelect)
