import { observable, action, runInAction, reaction } from 'mobx'

import * as api from '../services/api'
import { itemsPerPage } from '../constants/pagination'

class ContributionStore {
  @observable contributionList = []
  @observable totalCount = 0
  @observable page = 1

  @observable caseContributions = []
  @observable caseContributionCount = 0
  @observable caseContributionPage = 1
  @observable totalContributions = 0
  @observable monthlyContributions = 0
  @observable targetCaseId = 0 // for fetching case contributions

  @observable singleContribution = null

  @observable filter = {
    keyword: '',
    orderBy: 'date',
    order: 'DESC'
  }

  @observable error = null

  constructor (rootStore) {
    this.rootStore = rootStore
  }

  @action
  setPage = page => {
    this.page = page
  }

  @action
  setCaseContributionPage = page => {
    this.caseContributionPage = page
  }

  @action
  updateFilter = filter => {
    this.filter = {
      ...this.filter,
      ...filter
    }
    // reset page after updating filter
    this.page = 1
  }

  @action
  setTargetCaseId = caseId => {
    this.targetCaseId = caseId
  }

  fetchContributionList = async () => {
    const { accessToken } = this.rootStore.authStore
    const { keyword, orderBy, order } = this.filter
    try {
      const result = await api.fetchContributionList(accessToken, {
        limit: itemsPerPage,
        offset: (this.page - 1) * itemsPerPage,
        keyword,
        orderBy,
        order
      })
      runInAction(() => {
        this.contributionList = result.rows
        this.totalCount = result.count
      })
    } catch (error) {
      console.log(error)
    }
  }

  fetchCaseContributions = async () => {
    const { accessToken } = this.rootStore.authStore
    try {
      const result = await api.fetchCaseContributions(accessToken, {
        caseId: this.targetCaseId,
        limit: itemsPerPage,
        offset: (this.caseContributionPage - 1) * itemsPerPage
      })
      runInAction(() => {
        this.caseContributions = result.rows
        this.caseContributionCount = result.count
        this.totalContributions = result.totalContributions
        this.monthlyContributions = result.monthlyContributions
      })
    } catch (error) {
      console.log(error)
    }
  }

  updateContribution = async (id, payload) => {
    this.clearError()
    const { accessToken } = this.rootStore.authStore
    try {
      await api.updateContribution(accessToken, id, payload)
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.error = error
      })
    }
  }

  deleteContribution = async id => {
    const { accessToken } = this.rootStore.authStore
    try {
      await api.deleteContribution(accessToken, id)
    } catch (error) {
      console.log(error)
    }
  }

  fetchSingleContribution = async id => {
    const { accessToken } = this.rootStore.authStore
    try {
      const result = await api.fetchSingleContribution(accessToken, id)
      runInAction(() => {
        this.singleContribution = result
      })
    } catch (error) {
      console.log(error)
    }
  }

  importFromCsv = async file => {
    const { accessToken } = this.rootStore.authStore
    try {
      const result = await api.importContributionsFromCsv(accessToken, file)
      return result
    } catch (error) {
      console.log(error)
    }
  }

  @action
  listenAndFetchContributionList = () => {
    this.page = 1
    return reaction(
      () => ({
        page: this.page,
        filter: this.filter
      }),
      () => this.fetchContributionList()
    )
  }

  @action
  listenAndFetchCaseContributions = () => {
    this.caseContributionPage = 1
    return reaction(
      () => this.caseContributionPage,
      () => this.fetchCaseContributions()
    )
  }

  @action
  clearError = () => {
    this.error = null
  }
}

export default ContributionStore
