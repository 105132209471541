import * as R from 'ramda'
import * as yup from 'yup'
import React from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  contentTc: yup.string().required(),
  contentEn: yup.string().required()
})

@inject('reminderStore')
@observer
class ReminderEditDialog extends React.Component {
  handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const { reminderStore, onClose } = this.props
    const { singleReminder } = reminderStore

    await reminderStore.updateReminder(singleReminder.id, values)

    setSubmitting(false)

    reminderStore.fetchSingleReminder(singleReminder.id)
    onClose()
  }

  render () {
    const { open, onClose, reminderStore, field } = this.props
    const { singleReminder } = reminderStore

    const initialValues = R.pick(
      ['name', 'contentTc', 'contentEn'],
      singleReminder
    )

    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>Edit {field}</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.handleSubmit}
          enableReinitialize
        >
          {() => (
            <Form>
              <DialogContent>
                <Field
                  label='Name'
                  name='name'
                  placeholder='Name'
                  fullWidth
                  component={TextField}
                />
                <Box mb={2} />

                <Field
                  label='Content(TC)'
                  name='contentTc'
                  placeholder='Content(TC)'
                  fullWidth
                  multiline
                  component={TextField}
                />
                <Box mb={2} />

                <Field
                  label='Content(EN)'
                  name='contentEn'
                  placeholder='Content(EN)'
                  fullWidth
                  multiline
                  component={TextField}
                />
                <Box mb={2} />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} color='primary'>
                  Close
                </Button>
                <Button type='submit' color='primary' variant='contained'>
                  Confirm
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    )
  }
}

ReminderEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ReminderEditDialog
