import AuthStore from './AuthStore'
import DashboardStore from './DashboardStore'
import CaseStore from './CaseStore'
import OfficerStore from './OfficerStore'
import DiaryStore from './DiaryStore'
import ContributionStore from './ContributionStore'
import ReminderStore from './ReminderStore'
import PaymentBookingStore from './PaymentBookingStore'
import FormStore from './FormStore'

class RootStore {
  constructor () {
    this.authStore = new AuthStore(this)
    this.dashboardStore = new DashboardStore(this)
    this.caseStore = new CaseStore(this)
    this.officerStore = new OfficerStore(this)
    this.diaryStore = new DiaryStore(this)
    this.contributionStore = new ContributionStore(this)
    this.reminderStore = new ReminderStore(this)
    this.paymentBookingStore = new PaymentBookingStore(this)
    this.formStore = new FormStore(this)
  }
}

export default RootStore
