import * as R from 'ramda'
import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableHead,
  TableSortLabel,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  InputBase,
  Toolbar,
  withStyles
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { red } from '@material-ui/core/colors'
import Pagination from 'material-ui-flat-pagination'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

import { itemsPerPage } from '../constants/pagination'
import ContributionDeleteConfirmDialog from '../components/contributions/ContributionDeleteConfirmDialog'
import SuccessDialog from '../components/SuccessDialog'
import ContributionUploadCsvDialog from '../components/contributions/ContributionUploadCsvDialog'

const styles = theme => ({
  searchBox: {
    marginRight: theme.spacing(3)
  },
  toolbarButton: {
    marginRight: theme.spacing(1)
  },
  deleteButton: {
    color: red[500]
  }
})

@inject('contributionStore')
@observer
class ContributionIndex extends React.Component {
  state = {
    targetContribution: null,
    showConfirmDeleteDialog: false,
    showSuccessDeleteDialog: false,
    showUploadDialog: false
  }

  componentDidMount () {
    const { contributionStore } = this.props
    contributionStore.fetchContributionList()
    this.cancelListen = contributionStore.listenAndFetchContributionList()
  }

  componentWillUnmount () {
    if (this.cancelListen) {
      this.cancelListen()
    }
  }

  onKeywordChanged = e => {
    const { contributionStore } = this.props
    const { value } = e.target
    contributionStore.updateFilter({
      keyword: value
    })
  }

  onSortOrderChanged = property => () => {
    const { contributionStore } = this.props
    const { filter } = contributionStore
    if (filter.orderBy === property) {
      contributionStore.updateFilter({
        order: filter.order === 'ASC' ? 'DESC' : 'ASC'
      })
    } else {
      contributionStore.updateFilter({
        orderBy: property,
        order: 'DESC'
      })
    }
  }

  onChangePage = (e, offset) => {
    const { contributionStore } = this.props
    contributionStore.setPage(offset / itemsPerPage + 1)
  }

  onDeleteBtnClicked = contribution => () => {
    this.setState({
      targetContribution: contribution,
      showConfirmDeleteDialog: true
    })
  }

  onConfirmDelete = async () => {
    const { targetContribution } = this.state
    const { contributionStore } = this.props
    await contributionStore.deleteContribution(targetContribution.id)
    contributionStore.fetchContributionList()

    this.toggleConfirmDeleteDialog()
    this.toggleSuccessDeleteDialog()
  }

  toggleConfirmDeleteDialog = () => {
    this.setState(state => ({
      showConfirmDeleteDialog: !state.showConfirmDeleteDialog
    }))
  }

  toggleSuccessDeleteDialog = () => {
    this.setState(state => ({
      showSuccessDeleteDialog: !state.showSuccessDeleteDialog
    }))
  }

  toggleUploadDialog = () => {
    this.setState(state => ({
      showUploadDialog: !state.showUploadDialog
    }))
  }

  render () {
    const {
      showConfirmDeleteDialog,
      showSuccessDeleteDialog,
      targetContribution,
      showUploadDialog
    } = this.state
    const { contributionStore, classes } = this.props
    const { contributionList, page, totalCount, filter } = contributionStore

    return (
      <Fragment>
        <Box mb={2}>
          <Toolbar disableGutters>
            <Paper className={classes.searchBox}>
              <Box py={1 / 2} px={2}>
                <Grid container spacing={8} alignItems='flex-end'>
                  <Grid item>
                    <Search />
                  </Grid>
                  <Grid item>
                    <InputBase
                      placeholder='SEARCH'
                      value={filter.keyword}
                      onChange={this.onKeywordChanged}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Button
              className={classes.toolbarButton}
              variant='contained'
              color='secondary'
              onClick={this.toggleUploadDialog}
            >
              Upload CSV
            </Button>
          </Toolbar>
        </Box>
        <Paper>
          <Box p={3}>
            <Typography variant='h6' component='h2'>
              Contribution History
            </Typography>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={filter.orderBy === 'date'}
                    direction={filter.order.toLowerCase()}
                    onClick={this.onSortOrderChanged('date')}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={filter.orderBy === 'caseId'}
                    direction={filter.order.toLowerCase()}
                    onClick={this.onSortOrderChanged('caseId')}
                  >
                    Case ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>English Name</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={filter.orderBy === 'amount'}
                    direction={filter.order.toLowerCase()}
                    onClick={this.onSortOrderChanged('amount')}
                  >
                    Amount
                  </TableSortLabel>
                </TableCell>
                <TableCell>Remark</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {contributionList.map(row => (
                <TableRow key={row.id}>
                  <TableCell>{dayjs(row.date).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>
                    <Button
                      color='primary'
                      component={Link}
                      to={`/contribution-detail/${row.id}`}
                    >
                      {R.pathOr('', ['case', 'customCaseId'], row)}
                    </Button>
                  </TableCell>
                  <TableCell>
                    {R.pathOr('', ['case', 'englishName'], row)}
                  </TableCell>
                  <TableCell>{`HKD $${row.amount}`}</TableCell>
                  <TableCell>{row.remark || '-'}</TableCell>
                  <TableCell>
                    <Button
                      className={classes.deleteButton}
                      onClick={this.onDeleteBtnClicked(row)}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justify='flex-end'>
            <Pagination
              currentPageColor='primary'
              size='large'
              limit={itemsPerPage}
              offset={(page - 1) * itemsPerPage}
              total={totalCount}
              onClick={this.onChangePage}
            />
          </Grid>
        </Paper>
        {targetContribution && (
          <ContributionDeleteConfirmDialog
            open={showConfirmDeleteDialog}
            onClose={this.toggleConfirmDeleteDialog}
            onConfirm={this.onConfirmDelete}
            contribution={targetContribution}
          />
        )}
        <SuccessDialog
          open={showSuccessDeleteDialog}
          content='The contribution has been removed successfully'
          onClose={this.toggleSuccessDeleteDialog}
        />
        <ContributionUploadCsvDialog
          open={showUploadDialog}
          onClose={this.toggleUploadDialog}
        />
      </Fragment>
    )
  }
}

export default withStyles(styles)(ContributionIndex)
