import * as R from 'ramda'
import * as yup from 'yup'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  FormControlLabel
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { TextField, Switch } from 'formik-material-ui'

import SuccessDialog from '../SuccessDialog'

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
  customOfficerId: yup
    .string()
    .required('Officer ID is a required field')
    .matches(/^\d{3}$/, 'Please enter a 3-digit officer ID'),
  name: yup.string().required(),
  isAdmin: yup.bool(),
  isSuperAdmin: yup.bool(),
  acceptDiaryBooking: yup.bool()
})

@inject('authStore', 'officerStore')
@observer
class OfficerFormDialog extends React.Component {
  state = {
    success: false
  }

  handleEnter = () => {
    const { variant, officerId, officerStore } = this.props
    if (variant === 'edit') {
      officerStore.fetchSingleOfficer(officerId)
    }
  }

  handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const { variant, officerId, officerStore, onClose } = this.props
    const {
      email,
      customOfficerId,
      name,
      isAdmin,
      isSuperAdmin,
      acceptDiaryBooking
    } = values

    if (variant === 'create') {
      await officerStore.createOfficer({
        email,
        customOfficerId,
        name,
        isAdmin,
        isSuperAdmin,
        acceptDiaryBooking
      })
    } else {
      await officerStore.updateOfficer(officerId, {
        email,
        customOfficerId,
        name,
        isAdmin,
        isSuperAdmin,
        acceptDiaryBooking
      })
    }

    setSubmitting(false)

    if (officerStore.error) {
      const { code, fields } = officerStore.error
      if (code === 'UNIQUE_CONSTRAINT_ERROR') {
        const errorObj = fields.reduce((acc, field) => {
          acc[field] = `The entered value is being used.`
          return acc
        }, {})
        setErrors(errorObj)
      }
    } else {
      // success
      officerStore.fetchOfficerList()
      onClose()
      this.toggleSuccessDialog()
    }
  }

  toggleSuccessDialog = () => {
    this.setState(state => ({
      success: !state.success
    }))
  }

  render () {
    const { open, onClose, variant, authStore, officerStore } = this.props
    const { success } = this.state

    let initialValues = {
      email: '',
      customOfficerId: '',
      name: '',
      isAdmin: false,
      isSuperAdmin: false,
      acceptDiaryBooking: true
    }

    if (variant === 'edit' && officerStore.singleOfficer) {
      initialValues = R.pick(
        [
          'email',
          'customOfficerId',
          'name',
          'isAdmin',
          'isSuperAdmin',
          'acceptDiaryBooking'
        ],
        officerStore.singleOfficer
      )
    }

    return (
      <Fragment>
        <Dialog
          open={open}
          onClose={onClose}
          onEnter={this.handleEnter}
          fullWidth
        >
          <DialogTitle>
            {variant === 'create' ? 'Add' : 'Edit'}{' '}
            {authStore.isSuperAdmin ? 'Officer/Admin' : 'Officer'}
          </DialogTitle>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
            enableReinitialize
          >
            {() => (
              <Form>
                <DialogContent>
                  <Field
                    label='Email'
                    name='email'
                    placeholder='Email'
                    fullWidth
                    component={TextField}
                  />
                  <Box mb={2} />
                  <Field
                    label='Officer ID'
                    name='customOfficerId'
                    placeholder='Officer ID'
                    fullWidth
                    component={TextField}
                  />
                  <Box mb={2} />
                  <Field
                    label='Name'
                    name='name'
                    placeholder='Name'
                    fullWidth
                    component={TextField}
                  />
                  <Box mb={2} />
                  <FormControlLabel
                    control={
                      <Field
                        name='acceptDiaryBooking'
                        component={Switch}
                        color='primary'
                      />
                    }
                    label='Accepts diary booking'
                  />
                  {authStore.isSuperAdmin && (
                    <Fragment>
                      <Box mb={2} />
                      <FormControlLabel
                        control={
                          <Field
                            name='isAdmin'
                            component={Switch}
                            color='primary'
                          />
                        }
                        label='Is Admin'
                      />
                      <Box mb={2} />
                      <FormControlLabel
                        control={
                          <Field
                            name='isSuperAdmin'
                            component={Switch}
                            color='primary'
                          />
                        }
                        label='Is Super Admin'
                      />
                    </Fragment>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color='primary'>
                    Close
                  </Button>
                  <Button type='submit' color='primary' variant='contained'>
                    {variant === 'create' ? 'Create' : 'Update'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
        <SuccessDialog
          open={success}
          onClose={this.toggleSuccessDialog}
          content={`Officer has been ${
            variant === 'create' ? 'added' : 'updated'
          } successfully`}
        />
      </Fragment>
    )
  }
}

OfficerFormDialog.propTypes = {
  variant: PropTypes.oneOf(['create', 'edit']).isRequired,
  officerId: PropTypes.number,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default OfficerFormDialog
