import React from 'react'
import { Box, Card, Grid, Typography, withStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'

const styles = theme => ({
  icon: {
    fontSize: 60
  }
})

const DashboardCard = ({ classes, className, link, Icon, text }) => (
  <Card className={className}>
    <Link to={link}>
      <Box px={6} py={3}>
        <Grid container direction='column' alignItems='center'>
          <Icon fontSize='large' color='secondary' className={classes.icon} />
          <Typography variant='h5' color='secondary'>
            {text}
          </Typography>
        </Grid>
      </Box>
    </Link>
  </Card>
)

export default withStyles(styles)(DashboardCard)
