import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

import ConfirmDialog from '../ConfirmDialog'
import { nl2br } from '../../utils/reminder'

class ReminderDeleteConfirmDialog extends React.Component {
  render () {
    const { open, onClose, onConfirm, reminder } = this.props

    return (
      <ConfirmDialog
        open={open}
        onClose={onClose}
        title='Remove this reminder?'
        content={
          <Box className='well' p={2}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <Typography>Name</Typography>
                  </td>
                  <td>
                    <Typography variant='body1'>{reminder.name}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>Content(TC)</Typography>
                  </td>
                  <td>
                    <Typography variant='body1'>
                      {nl2br(reminder['contentTc'])}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>Content(EN)</Typography>
                  </td>
                  <td>
                    <Typography variant='body1'>
                      {nl2br(reminder['contentEn'])}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
        }
        onConfirm={onConfirm}
      />
    )
  }
}

ReminderDeleteConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  reminder: PropTypes.object.isRequired
}

export default ReminderDeleteConfirmDialog
