import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { withStyles } from '@material-ui/core'

import { restrictedRoutes } from '../../routes'
import Header from './Header'
import Sidebar from './Sidebar'

const styles = theme => ({
  root: {
    display: 'flex'
  },
  toolbar: theme.mixins.toolbar,
  main: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
})

@inject('authStore')
@observer
class Layout extends React.Component {
  componentDidMount () {
    this.checkAuthentication()
  }

  componentDidUpdate () {
    this.checkAuthentication()
  }

  checkAuthentication () {
    const { authStore, history } = this.props
    if (!authStore.isAuthenticated) {
      history.replace('/login')
    }
  }

  render () {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <Header />
        <Sidebar />
        <main className={classes.main}>
          <div className={classes.toolbar} />
          <Switch>
            {restrictedRoutes.map(config => (
              <Route key={config.path} {...config} />
            ))}
            <Redirect to='/404' />
          </Switch>
        </main>
      </div>
    )
  }
}

export default withStyles(styles)(Layout)
