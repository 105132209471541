import * as yup from 'yup'
import React from 'react'
import { observer, inject } from 'mobx-react'
import { Box, Grid, Typography, Button } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import { Formik, Form, Field } from 'formik'

const initialValues = {
  email: ''
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required()
})

@inject('authStore')
@observer
class ForgotPw extends React.Component {
  handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const { email } = values
    const { authStore } = this.props
    await authStore.forgotPassword(email)

    if (authStore.error) {
      setErrors({
        email: authStore.error.message
      })
    }

    setSubmitting(false)
  }

  render () {
    return (
      <Grid
        className='page forgot-pw'
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justify='center'
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.handleSubmit}
        >
          {() => (
            <Form>
              <Box width={350}>
                <Typography component='h1' variant='h5' color='secondary'>
                  Forgot Password
                </Typography>
                <Box mb={5} />
                <Typography variant='body1' color='secondary'>
                  To reset your password, please enter your email address.
                </Typography>
                <Box mb={2} />
                <Field
                  name='email'
                  placeholder='Email'
                  fullWidth
                  component={TextField}
                />
                <Box mb={5} />
                <Button
                  type='submit'
                  variant='contained'
                  color='secondary'
                  fullWidth
                >
                  Confirm
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
    )
  }
}

export default ForgotPw
